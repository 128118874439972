import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";
import { ButtonProgress, SharedStyles } from "@shared/components/lib/index";
import { Snackbar, Alert } from "@mui/material";
import { getRequestUI, putRequestUI } from "common-utils/utils/api";
import { useQuery, useMutation } from "react-query";

const useStyles = (theme) => ({
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 340,
  },
  submitBtn: {
    position: "absolute",
    top: "14px",
    zIndex: 10000,
    color: "#fff",
    left: "60px",
  },
});

const ClaimSettingsTab = ({ carrierId }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const defaultClaimsPricePerKg = 3.4;
  const [claimPricePerKG, setClaimPricePerKG] = useState(
    defaultClaimsPricePerKg
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  useQuery(
    ["claimSettings", carrierId],
    () => getRequestUI(`carrier-claim-settings/${carrierId}`),
    {
      enabled: !!carrierId,
      onSuccess: (data) => {
        setClaimPricePerKG(data.claimPrice);
      },
    }
  );

  const updateClaimSettings = useMutation(
    () =>
      putRequestUI(`carrier-claim-settings/${carrierId}`, {
        claimPrice: claimPricePerKG,
      }),
    {
      onSuccess: () => {
        setShowError(false);
        setErrorMessage("");
      },
      onError: (error) => {
        setErrorMessage(error.message || "Error updating claim settings");
        setShowError(true);
      },
    }
  );

  const handleSubmit = () => {
    updateClaimSettings.mutate();
  };

  return (
    <>
      {showError && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={() => setShowError(false)}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      )}
      <Button
        sx={styles.submitBtn}
        autoFocus
        color="inherit"
        onClick={handleSubmit}
      >
        Update
      </Button>
      <form style={sharedStyles.container}>
        <FormControl sx={sharedStyles.formControl}>
          <Typography variant="h3" mb={2}>
            Claim Settings
          </Typography>
          <TextField
            variant="standard"
            id="claimPricePerKG"
            label="Claim Price per KG"
            value={claimPricePerKG}
            onChange={(e) => setClaimPricePerKG(parseFloat(e.target.value))}
            type="number"
          />
        </FormControl>
      </form>
    </>
  );
};

export default ClaimSettingsTab;
