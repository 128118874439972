const adminAvailableRoles = [
  {
    id: "retailer",
    name: "Retailer",
    value: "retailer",
  },
  {
    id: "carrier",
    name: "Carrier",
    value: "carrier",
  },
  {
    id: "storeManager",
    name: "Store Manager",
    value: "storeManager",
  },
  {
    id: "carrierAdmin",
    name: "Carrier Admin",
    value: "carrierAdmin",
  },
  {
    id: "support",
    name: "Support",
    value: "support",
  },
];

const adminRole = {
  id: "admin",
  name: "Admin",
  value: "admin",
};

export const getRoles = async () => {
  const authLocalStorage = JSON.parse(window.localStorage.getItem("auth"));
  const role = authLocalStorage ? authLocalStorage.role.userType : undefined;
  if (role === "superAdmin") {
    return [...adminAvailableRoles, adminRole];
  }
  return adminAvailableRoles;
};

export const AUTH_TYPE = [
  {
    id: "bearer",
    name: "Bearer",
    value: "Bearer",
  },
  {
    id: "basic",
    name: "Basic",
    value: "Basic",
  },
];

export const FAILED_AT_CARRIER = "FAILED_AT_CARRIER";

export const WEEK_DAYS = [
  {
    id: 1,
    name: "Monday",
  },
  {
    id: 2,
    name: "Tuesday",
  },
  {
    id: 3,
    name: "Wednesday",
  },
  {
    id: 4,
    name: "Thursday",
  },
  {
    id: 5,
    name: "Friday",
  },
  {
    id: 6,
    name: "Saturday",
  },
  {
    id: 7,
    name: "Sunday",
  },
];
