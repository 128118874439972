import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import {
  getRequestUI,
  deleteRequestUI,
  postRequestUI,
} from "common-utils/utils/api";
import DialogAppBar from "../components/DialogAppBar";
import Slide from "@mui/material/Slide";
import FormControl from "@mui/material/FormControl";
import useTheme from "@mui/material/styles/useTheme";
import {
  ButtonProgress,
  DeleteButton,
  PageTitle,
  TableComponent,
  SharedStyles,
} from "@shared/components/lib/index";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RetailerInsuranceTab = ({ retailerId }) => {
  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);
  const [insurances, setInsurances] = useState([]);
  const [isAddInsurance, setIsAddInsurance] = useState(false);
  const [formData, setFormData] = useState({});
  const [isCreatingNew, setCreatingNew] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      await updateTable();
    })();
  }, [retailerId]);

  const updateTable = async () => {
    try {
      const result = await getRequestUI(`/insurance/retailer/${retailerId}`);
      setInsurances(result);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handleDeleteInsurance = async (id) => {
    try {
      await deleteRequestUI(`/insurance/retailer/${id}`);
      await updateTable();
    } catch (error) {
      if (error.code === "invalid-argument") {
        setErrorMessage(error.message);
      }
    }
  };

  const insuranceTableData = {
    headers: ["From Amount", "To Amount", ""],
    body: [
      (insurance) => insurance.fromAmount,
      (insurance) => insurance.toAmount,
      (insurance) => (
        <DeleteButton
          id={insurance.id}
          updateTable={updateTable}
          deleteItem={handleDeleteInsurance}
        />
      ),
    ],
  };

  const handleClickOpenInsurances = () => {
    setIsAddInsurance(true);
    setFormData({});
  };

  const handleClose = () => {
    setIsAddInsurance(false);
  };

  const handleSave = async () => {
    setErrorMessage("");
    try {
      const { fromAmount, toAmount } = formData;
      if (!fromAmount || !toAmount) {
        setErrorMessage("Please select insurance");
        return;
      }
      if (parseFloat(fromAmount) > parseFloat(toAmount)) {
        setErrorMessage("To Amount must be bigger or equal From Amount");
        return;
      }
      const data = {
        retailerId,
        fromAmount,
        toAmount,
      };
      setCreatingNew(true);
      await postRequestUI("/insurance/retailer", data);
      await updateTable();
      setCreatingNew(false);
      handleClose();
    } catch (error) {
      // Getting the Error details.

      if (error.code === "invalid-argument") {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <Grid container>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Insurances"} />
        </div>
        <Button
          data-testid="add_insurance_btn"
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => handleClickOpenInsurances()}
        >
          Add Insurance
        </Button>
        <TableComponent
          headers={insuranceTableData.headers}
          rowData={insurances}
          cellComponents={insuranceTableData.body}
        />
      </TableContainer>
      {isAddInsurance && (
        <Box p={10} flexDirection="row">
          <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <DialogAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Button
                  data-testid="save_insurance_btn"
                  autoFocus
                  color="inherit"
                  onClick={handleSave}
                >
                  Save
                  {isCreatingNew && <ButtonProgress size={24} />}
                </Button>
              </Toolbar>
            </DialogAppBar>
            <DialogContent>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={!!errorMessage}
                autoHideDuration={3000}
              >
                <Alert severity="error">{errorMessage}</Alert>
              </Snackbar>
              <form style={sharedStyles.container}>
                <FormControl sx={sharedStyles.formControl}>
                  <Typography variant="h3" component="h4">
                    Add Insurance
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        data-testid="retailer_from_amount_insurance"
                        id="from"
                        label="From Amount"
                        value={formData.fromAmount || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            fromAmount: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="standard"
                        data-testid="retailer_to_amount_insurance"
                        id="to"
                        label="To Amount"
                        value={formData.toAmount || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            toAmount: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Grid>
  );
};

export default RetailerInsuranceTab;
