import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import useTheme from "@mui/material/styles/useTheme";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {
  Grid,
  LinearProgress,
  MenuItem,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Alert from "@mui/material/Alert";
import EditIcon from "@mui/icons-material/Edit";
import {
  putRequestUI,
  deleteRequestUI,
  getRequestUI,
  postRequestUI,
} from "common-utils/utils/api";
import {
  AddButton,
  IconButton as CIconButton,
  PageTitle,
  TableComponent,
  MainBox,
  SharedStyles,
} from "@shared/components/lib/index";
import DialogAppBar from "../components/DialogAppBar";
import { getRoles } from "../constants";
import { ButtonProgress } from "@shared/components/lib/index";
import TableFilter from "../components/TableFilter";
import { useQuery, useMutation, useQueryClient } from "react-query";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  table: {
    minWidth: 650,
  },
  endAdornment: {
    top: 0,
    bottom: "calc(50% - 14px)",
  },
  title: {
    padding: theme.spacing(2),
    flex: 1,
  },
  root: {
    display: "flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 340,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  deleteProgress: {
    marginLeft: 10,
  },
});

function Users() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [deletingUser, setDeletingUser] = useState();
  const [edit, setEdit] = useState(false);
  const [isCreatingNew, setCreatingNew] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState({
    retailers: [],
    carriers: [],
  });
  const queryClient = useQueryClient();
  const userQuery = useQuery(["users", selectedUsers], getUsers);
  const carrierQuery = useQuery(["carriers"], getCarriers);
  const retailerQuery = useQuery(["retailers"], getRetailers);
  const roleQuery = useQuery(["roles"], getFirebaseRoles);
  const [retailerId, setRetailerId] = useState(null);
  const locationsQuery = useQuery(["locations", retailerId], getLocations, {
    enabled: !!retailerId,
  });
  const [selectedCarriers, setSelectedCarriers] = useState([]);
  const createUserMutation = useMutation(createUser, {
    onSuccess: onSuccessUserMutation,
    onError: () => handleClose(),
  });
  const updateUserMutation = useMutation(updateUser, {
    onSuccess: onSuccessUserMutation,
    onError: () => handleClose(),
  });
  const deleteUserMutation = useMutation(deleteUser, {
    onSuccess: onSuccessUserMutation,
  });

  useEffect(() => {
    (async () => {
      if (
        formData &&
        formData.userType === "storeManager" &&
        formData.retailerId
      ) {
        setRetailerId(formData.retailerId);
      }
    })();
  }, [formData]);

  function getCarriers() {
    return getRequestUI("/carriers");
  }

  function getFirebaseRoles() {
    return getRoles();
  }

  function getRetailers() {
    return getRequestUI("/retailers");
  }

  function getLocations() {
    return getRequestUI(`/available/locations/${retailerId}`);
  }

  const handleClickOpen = () => {
    setOpen(true);
    setFormData({});
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setFormData({});
  };

  function getUsers() {
    const params = {
      carriers: selectedUsers.carriers
        ? selectedUsers.carriers.map((c) => c.id)
        : [],
      retailers: selectedUsers.retailers
        ? selectedUsers.retailers.map((r) => r.id)
        : [],
    };
    return getRequestUI(`/users`, params);
  }

  async function createUser(user) {
    setCreatingNew(true);
    await postRequestUI("/user", user);
    setCreatingNew(false);
    handleClose();
  }

  async function updateUser(user) {
    await putRequestUI("/user", user);
    handleClose();
  }

  async function deleteUser(id) {
    setDeletingUser(id);
    await deleteRequestUI(`/user?id=${encodeURIComponent(id)}`);
    setDeletingUser();
  }

  function onSuccessUserMutation() {
    queryClient.invalidateQueries("users");
  }

  const handleSave = () => {
    const user = {
      email: formData.email,
      userType: formData.userType,
      retailerId: formData.retailerId,
      carrierId: formData.carrierId,
      locationId: formData.locationId,
      carriers: selectedCarriers.map((carrier) => carrier.id),
    };
    createUserMutation.mutate(user);
  };

  const handleEditUser = () => {
    const data = {
      email: formData.email,
      userType: formData.userType,
      retailerId: formData.retailerId,
      carrierId: formData.carrierId,
      locationId: formData.locationId,
      carriers: selectedCarriers.map((carrier) => carrier.id),
    };
    updateUserMutation.mutate(data);
  };
  const handleDelete = (id) => {
    deleteUserMutation.mutate(id);
  };

  const handleFormData = useCallback(
    (key) => (e) => {
      setFormData({ ...formData, [key]: e.target.value });
    },
    [formData]
  );

  const editUser = (user) => {
    setEdit(true);
    setFormData({ ...formData, ...user });
    const carriers = user.carriers
      ? carrierQuery.data.filter((carrier) =>
          user.carriers.includes(carrier.id)
        )
      : [];
    setSelectedCarriers(carriers);
  };

  const userTableData = {
    headers: ["", "Email", "Type", ""],
    body: [
      (user) => (
        <Button endIcon={<EditIcon />} onClick={() => editUser(user)} />
      ),
      (user) => user.email,
      (user) => user.userType,
      (user) =>
        deleteUserMutation.isLoading && deletingUser === user.id ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton
            data-testid="delete_user_btn"
            icon="delete"
            onClick={() => handleDelete(user.email)}
          />
        ),
    ],
  };

  const showProgress = userQuery.isLoading;
  const getErrorMessage = () => {
    if (userQuery.isError) {
      return userQuery.error.message;
    }
    if (createUserMutation.isError) {
      const error =
        createUserMutation.error instanceof Error
          ? createUserMutation.error.message
          : "Error by create user";
      return error;
    }
    if (updateUserMutation.isError) {
      const error =
        updateUserMutation.error instanceof Error
          ? updateUserMutation.error.message
          : "Error by update user";
      return error;
    }
    if (deleteUserMutation.isError) {
      const error =
        deleteUserMutation.error instanceof Error
          ? deleteUserMutation.error.message
          : "Error by update user";
      return error;
    }
    return "";
  };

  return (
    <MainBox>
      <AddButton onClick={handleClickOpen} />
      <Dialog
        fullScreen
        open={open || edit}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button
              data-testid="save_user_btn"
              autoFocus
              disabled={isCreatingNew}
              color="inherit"
              onClick={edit ? handleEditUser : handleSave}
            >
              {edit ? "Update" : " Save"}
              {isCreatingNew && <ButtonProgress size={24} />}
            </Button>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!getErrorMessage()}
            autoHideDuration={3000}
          >
            <Alert severity="error">{getErrorMessage()}</Alert>
          </Snackbar>
          <form style={styles.container}>
            <FormControl sx={sharedStyles.formControl}>
              <Typography variant="h3" component="h4">
                {edit ? "Edit User" : " Create new User"}
              </Typography>
              <Grid container spacing={2} sx={styles.formContainer}>
                <Grid item xs={12}>
                  <TextField
                    data-testid="email_field"
                    id="email"
                    variant="standard"
                    label="Email"
                    fullWidth
                    value={formData.email || ""}
                    onChange={edit ? () => {} : handleFormData("email")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid="user_type_field"
                    id="id-user-type"
                    variant="standard"
                    select
                    label="User Type"
                    fullWidth
                    value={formData.userType || ""}
                    onChange={handleFormData("userType")}
                  >
                    {roleQuery.data &&
                      roleQuery.data.map(({ id, name, value }) => (
                        <MenuItem key={id} value={value}>
                          {name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                {formData.userType === "retailer" && (
                  <Grid item xs={12}>
                    <TextField
                      data-testid="id_retailers_field"
                      id="id-retailers"
                      variant="standard"
                      select
                      label="select a retailer"
                      fullWidth
                      value={formData.retailerId || ""}
                      onChange={handleFormData("retailerId")}
                    >
                      {retailerQuery.data &&
                        retailerQuery.data.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                )}
                {formData.userType === "carrier" && (
                  <Grid item xs={12}>
                    <TextField
                      data-testid="id_carriers"
                      id="id-carriers"
                      variant="standard"
                      select
                      label="select a carrier"
                      fullWidth
                      value={formData.carrierId || ""}
                      onChange={handleFormData("carrierId")}
                    >
                      {carrierQuery.data &&
                        carrierQuery.data.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                )}
                {formData.userType === "carrierAdmin" && (
                  <Grid item xs={12}>
                    <Autocomplete
                      options={carrierQuery.data || []}
                      id="carrier-admin"
                      data-testid="carrier_admin"
                      getOptionLabel={(option) => option.name ?? ""}
                      classes={{
                        endAdornment: styles.endAdornment,
                      }}
                      multiple
                      value={selectedCarriers}
                      onChange={(event, carriers) =>
                        setSelectedCarriers(carriers)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Carriers"
                          variant="standard"
                        />
                      )}
                    />
                  </Grid>
                )}
                {formData.userType === "storeManager" && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        data-testid="id_retailers"
                        id="id-retailers"
                        variant="standard"
                        select
                        label="select a retailer"
                        fullWidth
                        value={formData.retailerId || ""}
                        onChange={handleFormData("retailerId")}
                      >
                        {retailerQuery.data &&
                          retailerQuery.data.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        data-testid="id_locations"
                        id="id-locations"
                        variant="standard"
                        select
                        label="select a location"
                        fullWidth
                        value={formData.locationId || ""}
                        onChange={handleFormData("locationId")}
                      >
                        {locationsQuery.data &&
                          locationsQuery.data.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                              {name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </>
                )}
              </Grid>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!getErrorMessage()}
        autoHideDuration={3000}
      >
        <Alert severity="error">{getErrorMessage()}</Alert>
      </Snackbar>
      <Grid container sx={styles.title}>
        <Grid item>
          <PageTitle setTitle={"Users"} />
        </Grid>
        <TableFilter
          tableFilter={[
            {
              data: carrierQuery.data,
              label: "Carriers",
              key: "carriers",
              id: "carriers_user",
            },
            {
              data: retailerQuery.data,
              label: "Retailers",
              key: "retailers",
              id: "retailers_user",
            },
          ]}
          selectedFilterData={selectedUsers}
          setSelectedFilterData={setSelectedUsers}
        />
      </Grid>
      <TableComponent
        headers={userTableData.headers}
        rowData={userQuery.data || []}
        cellComponents={userTableData.body}
      />
    </MainBox>
  );
}

export default Users;
