import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { QueryClient, QueryClientProvider } from "react-query";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import AdminApp from "./AdminApp";
import { theme } from "@shared/components/lib/index";

import "./css/main.css";

const Main = () => {
  const history = createBrowserHistory();
  const queryClient = new QueryClient();

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <AdminApp />
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
};

export default Main;
