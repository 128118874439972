import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogAppBar from "../components/DialogAppBar";
import Alert from "@mui/material/Alert";

import EditIcon from "@mui/icons-material/Edit";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import useTheme from "@mui/material/styles/useTheme";
import {
  IconButton as CIconButton,
  TableComponent,
  PageTitle,
  GeoFencesBlackList,
  SharedStyles,
} from "@shared/components/lib/index";
import {
  putRequestUI,
  postRequestUI,
  getRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";

const useStyles = (theme) => ({
  deleteProgress: {
    marginLeft: 10,
  },
  mainBox: {
    padding: 0,
  },
  iconWrap: {
    "& svg": {
      marginBottom: "-4px",
      marginRight: "4px",
    },
  },
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 540,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GeofenceBlacklist = ({ carrierId }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [geoFences, setGeoFences] = useState({});
  const queryClient = useQueryClient();
  const query = useQuery(["blacklist"], getBlackList);
  const createMutation = useMutation(saveToBlacklist, {
    onSuccess: onSuccessMutation,
    onError: () => handleCloseDialog(),
  });
  const updateMutation = useMutation(updateBlacklist, {
    onSuccess: onSuccessMutation,
    onError: () => handleCloseDialog(),
  });
  const deleteMutation = useMutation(deleteFromBlacklist, {
    onSuccess: onSuccessMutation,
  });

  async function getBlackList() {
    return await getRequestUI(`/blacklist/${carrierId}`);
  }

  async function saveToBlacklist(data) {
    await postRequestUI("/blacklist", { carrierId, ...data });
    handleCloseDialog();
  }

  async function updateBlacklist(data) {
    await putRequestUI("/blacklist", { carrierId, ...data });
    handleCloseDialog();
  }

  async function deleteFromBlacklist(id) {
    await deleteRequestUI(`/blacklist/${id}`);
  }

  function onSuccessMutation() {
    queryClient.invalidateQueries("blacklist");
  }

  const blacklistTableData = {
    headers: ["Name", "", ""],
    body: [
      (data) => data.name,
      (data) => (
        <Button endIcon={<EditIcon />} onClick={() => editGeoFences(data)} />
      ),
      (data) =>
        deleteMutation.isLoading ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton
            icon="delete"
            data-testid="delete_blacklist_btn"
            onClick={() => deleteMutation.mutate(data.id)}
          />
        ),
    ],
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setGeoFences({});
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCreateGeoFence = (data) => {
    createMutation.mutate(data);
  };

  const editGeoFences = (data) => {
    setGeoFences(data);
    handleOpenDialog();
  };

  const handleUpdateGeoFence = (data) => {
    updateMutation.mutate(data);
  };

  const showProgress = query.isLoading;
  const getErrorMessage = () => {
    if (query.isError) {
      return query.error.message;
    }
    if (createMutation.isError) {
      return createMutation.error.message;
    }
    if (deleteMutation.isError) {
      deleteMutation.error.message;
    }
    return "";
  };
  const isCreatingNew = createMutation.isLoading;
  const { data = [] } = query;
  return (
    <Grid container>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!getErrorMessage()}
        autoHideDuration={3000}
      >
        <Alert severity="error">{getErrorMessage()}</Alert>
      </Snackbar>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <Button
          data-testid="add_blacklist_btn"
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleOpenDialog}
        >
          Add to blacklist
        </Button>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Blacklist"} />
        </div>

        <TableComponent
          headers={blacklistTableData.headers}
          rowData={data}
          cellComponents={blacklistTableData.body}
        />
      </TableContainer>
      <Dialog
        fullScreen
        open={isOpenDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <GeoFencesBlackList
            isSave={isCreatingNew}
            geoFences={geoFences}
            createGeoFence={handleCreateGeoFence}
            updateGeoFence={handleUpdateGeoFence}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default GeofenceBlacklist;
