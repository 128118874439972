"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styles = require("@mui/material/styles");
var _material = require("@mui/material");
var DialogAppBar = (0, _styles.styled)(_material.AppBar)({
  position: "relative",
  backgroundColor: "#0635C9"
});
var _default = exports["default"] = DialogAppBar;