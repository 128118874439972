import React, { useState, useCallback, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import {
  GoogleAutoCompleteAddress,
  RetailerInsurances,
} from "@shared/components/lib/index";
import {
  getRequestUI,
  putRequestUI,
} from "../../../library/node_modules/common-utils/utils/api";
import { compact } from "lodash";
import { formatPostCode } from "common-utils/utils/formatter";
import { isPostcodeValid } from "common-utils/utils/validation";
import { MuiTelInput } from "mui-tel-input";
import { useQuery } from "react-query";

const EditOrder = ({
  order,
  isEditOrder,
  handleCloseEdit,
  setShowError,
  setErrorMessage,
  updateOrders,
}) => {
  const [formData, setFormData] = useState({
    postcode: order.deliveryAddress.postcode,
    houseNumber: order.deliveryAddress.houseNumber,
    houseAdditions: order.deliveryAddress.houseAdditions,
    description: order.description,
    recipientName: order.deliveryAddress.recipientName,
    recipientNumber: order.deliveryAddress.recipientNumber,
    externalId: order.externalId,
    insuranceId: order.retailerInsurancesId,
  });
  const [houseAdditions, setHouseAdditions] = useState(
    compact([order.deliveryAddress.houseAdditions])
  );
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [showGoogleAutocomplete, setShowGoogleAutocomplete] = useState(false);
  const insurancesQuery = useQuery(
    ["insurances", order],
    async () => {
      return getRequestUI(`/order/${order.id}/insurances`);
    },
    {
      enabled: !order.retailerInsurancesId && isEditOrder,
    }
  );

  const delayedAdddressChecked = useRef(
    _.debounce(
      async (postcode, houseNumber) =>
        await addressChanged(postcode, houseNumber),
      300
    )
  ).current;

  useEffect(() => {
    if (isEditOrder && order.deliveryAddress.country.code === "nl")
      delayedAdddressChecked(
        formData.postcode,
        formData.houseNumber,
        formData.houseAdditions
      );
  }, [formData.postcode, formData.houseNumber]);

  const addressChanged = async (postcode, houseNumber) => {
    setErrorMessage("");
    setShowError(false);
    try {
      if (!postcode) {
        return;
      }
      let normalizedPostcode = formatPostCode(postcode);
      let addressQueryValid =
        isPostcodeValid(normalizedPostcode) && parseInt(houseNumber);
      if (!addressQueryValid) {
        return;
      }
      const result = await getRequestUI("/house/additions", {
        postcode: normalizedPostcode,
        houseNumber: houseNumber,
      });
      if (!result) {
        setShowError(true);
        setErrorMessage("Address is not valid");
        setShowGoogleAutocomplete(true);
        return;
      }
      setDisableSaveButton(false);
      const street = result.street;
      const city = result.city;
      const availableHouseNumbers = result.houseAdditions;
      setFormData({
        ...formData,
        postcode: normalizedPostcode,
        houseNumber: houseNumber,
        street,
        coordinates: result.coordinates,
        city,
      });
      setHouseAdditions(
        compact([...availableHouseNumbers, formData.houseAdditions])
      );
      setShowError(false);
      setErrorMessage("");
      setShowGoogleAutocomplete(false);
    } catch (e) {
      console.log(e);
      setShowGoogleAutocomplete(true);
      setHouseAdditions([]);
    }
  };

  const handleFormData = useCallback(
    (key) => (e) => {
      setFormData({ ...formData, [key]: e.target.value });
    },
    [formData]
  );

  const handleUpdateOrder = async () => {
    try {
      setErrorMessage("");
      setShowError(false);
      const payload = {
        ...formData,
        id: order.id,
        country: order.deliveryAddress.country,
        locationId: order.pickupAddress.locationId,
        carrierId: order.carrier.carrierId,
      };
      await putRequestUI("/order", payload);
      updateOrders();
      handleCloseEdit();
    } catch (error) {
      if (error.code === "invalid-argument") {
        setShowError(true);
        setErrorMessage(error.message);
      }
    }
  };

  const onAddressResolved = (autoCompleteData) => {
    if (!autoCompleteData) {
      return;
    }
    setShowError(false);
    setErrorMessage("");
    const { coordinates, postcode, houseNumber, city, street } =
      autoCompleteData;
    if (!postcode || !city) {
      return;
    }
    if (!houseNumber) {
      setErrorMessage("Please add street number");
      setShowError(true);
      return;
    }
    if (!street) {
      setErrorMessage("Please fill the street");
      setShowError(true);
      return;
    }
    setFormData({
      ...formData,
      coordinates,
      postcode,
      houseNumber,
      city,
      street,
    });
    setShowGoogleAutocomplete(false);
  };

  const handleClearHouseAdditions = () => {
    setFormData({ ...formData, houseAdditions: "" });
  };

  return (
    <div>
      <Dialog
        maxWidth={"md"}
        open={isEditOrder}
        fullWidth
        aria-labelledby="status-dialog-title"
        onClose={handleCloseEdit}
      >
        <DialogTitle id="status-dialog-title">Update Order</DialogTitle>
        <DialogContent>
          <Grid container flexDirection="row" spacing={2} xs={12}>
            <Grid item xs={6}>
              {order.deliveryAddress.country.code === "nl" ? (
                <>
                  <Grid item xs={12}>
                    <TextField
                      id="postcode"
                      data-testid="postcode"
                      variant="standard"
                      label="Postcode"
                      fullWidth
                      value={formData.postcode || ""}
                      onChange={handleFormData("postcode")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="houseNumber"
                      data-testid="houseNumber"
                      variant="standard"
                      label="House Number"
                      fullWidth
                      value={formData.houseNumber || ""}
                      onChange={handleFormData("houseNumber")}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {houseAdditions && houseAdditions.length > 0 && (
                      <TextField
                        variant="standard"
                        select
                        fullWidth
                        value={formData.houseAdditions || ""}
                        label="House Number Additions"
                        id="house-addition"
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={handleClearHouseAdditions}
                              data-testid="close_retailer_btn"
                              aria-label="close"
                              style={{ marginRight: 20 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          ),
                        }}
                        onChange={handleFormData("houseAdditions")}
                      >
                        {houseAdditions.map((addition) => (
                          <MenuItem value={addition} key={addition}>
                            {addition}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sx={{ marginTop: 3 }}>
                    <GoogleAutoCompleteAddress
                      country={order.deliveryAddress.country}
                      onSelect={onAddressResolved}
                      address={{
                        city: formData.city,
                        street: formData.street,
                        houseNumber: formData.houseNumber,
                      }}
                    />
                  </Grid>
                </>
              )}
              {showGoogleAutocomplete && (
                <Grid item xs={12} sx={{ marginTop: 3 }}>
                  <GoogleAutoCompleteAddress
                    country={order.deliveryAddress.country}
                    onSelect={onAddressResolved}
                    address={{
                      city: formData.city,
                      street: formData.street,
                      houseNumber: formData.houseNumber,
                    }}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={6}>
              <Grid item xs={12}>
                <TextField
                  id="recipientName"
                  data-testid="recipientName"
                  variant="standard"
                  label="Recipient Name"
                  fullWidth
                  value={formData.recipientName || ""}
                  onChange={handleFormData("recipientName")}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiTelInput
                  defaultCountry={"NL"}
                  data-testid="recipient_number_field"
                  value={formData.recipientNumber || ""}
                  onChange={(value) =>
                    setFormData({ ...formData, recipientNumber: value })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  data-testid="description"
                  variant="standard"
                  label="Description"
                  fullWidth
                  value={formData.description || ""}
                  onChange={handleFormData("description")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="externalId"
                  data-testid="externalId"
                  variant="standard"
                  label="External Id"
                  fullWidth
                  value={formData.externalId || ""}
                  onChange={handleFormData("externalId")}
                />
              </Grid>
              {!order.retailerInsurancesId && insurancesQuery.data && (
                <Grid item xs={12}>
                  <RetailerInsurances
                    insurances={insurancesQuery.data}
                    selectedInsurance={formData.insuranceId ?? ""}
                    chooseActiveInsurance={handleFormData("insuranceId")}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Button
            data-testid="edit_order_btn"
            color="primary"
            onClick={handleUpdateOrder}
            disabled={disableSaveButton}
          >
            Save
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditOrder;
