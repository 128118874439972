"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styles = require("@mui/material/styles");
var _CircularProgress = _interopRequireDefault(require("@mui/material/CircularProgress"));
var _colors = require("@mui/material/colors");
var ButtonProgress = (0, _styles.styled)(_CircularProgress["default"])({
  color: _colors.green[500],
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -12
});
var _default = exports["default"] = ButtonProgress;