import React, { useState, useCallback, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  ButtonProgress,
  TabPanel,
  IconButton as CIconButton,
} from "@shared/components/lib/index";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getRequestUI,
  postRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";

const useStyles = (theme) => ({
  submitBtn: {
    position: "absolute",
    top: "14px",
    zIndex: 10000,
    color: "#fff",
    left: "60px",
  },
  removeBtn: {
    position: "absolute",
    top: "14px",
    zIndex: 10000,
    color: "#fff",
    left: "120px",
  },
  inputBlock: {
    maxWidth: "300px",
    marginBottom: "16px",
  },
  block: {
    minWidth: "600px",
    marginBottom: "12px",
  },
});

const PicqerIntegrationTab = ({ tabValue, integration, retailerId }) => {
  const styles = useStyles();
  const [formData, setFormData] = useState({});
  const queryClient = useQueryClient();
  const integrationQuery = useQuery(
    ["integration", retailerId],
    getIntegration,
    {
      enabled: !!retailerId,
    }
  );
  const integrationMutation = useMutation(saveIntegration, {
    onSuccess: onSuccessUserMutation,
  });
  const deleteIntegrationMutation = useMutation(removeIntegration, {
    onSuccess: onSuccessUserMutation,
    enabled: !!retailerId,
  });

  function getIntegration() {
    return getRequestUI(`/retailer-integration/${retailerId}`);
  }
  function saveIntegration(data) {
    return postRequestUI(`/retailer-integration/${retailerId}`, data);
  }
  function removeIntegration() {
    return deleteRequestUI(`/retailer-integration/${retailerId}`);
  }

  function onSuccessUserMutation() {
    queryClient.invalidateQueries("integration");
  }

  useEffect(() => {
    if (integrationQuery.data) {
      const integrationId = findItemValueByName(
        integrationQuery.data,
        "integration"
      );
      const picqer =
        integrationId && integrationId === integration.id
          ? {
              username: findItemValueByName(
                integrationQuery.data,
                "picqer_username"
              ),
              password: findItemValueByName(
                integrationQuery.data,
                "picqer_password"
              ),
            }
          : {};
      setFormData({
        ...picqer,
      });
    }
  }, [integrationQuery.data]);

  const findItemValueByName = (integration, name) => {
    const item = integration.find((i) => i.name === name);
    return item ? item.value : "";
  };

  const handleFormData = useCallback(
    (key) => (e) => {
      setFormData({ ...formData, [key]: e.target.value });
    },
    [formData]
  );
  const onHandleSave = () => {
    integrationMutation.mutate([
      {
        name: "picqer_username",
        value: formData.username,
      },
      {
        name: "picqer_password",
        value: formData.password,
      },
      {
        name: "integration",
        value: tabValue,
      },
    ]);
  };
  const onHandleDelete = () => {
    deleteIntegrationMutation.mutate();
  };
  return (
    <TabPanel value={tabValue} index={integration.id}>
      <Button
        data-testid="save_integration_btn"
        sx={styles.submitBtn}
        autoFocus
        color="inherit"
        onClick={onHandleSave}
      >
        Save
        {integrationMutation.isLoading && <ButtonProgress size={24} />}
      </Button>
      <Button
        data-testid="remove_integration_btn"
        sx={styles.removeBtn}
        autoFocus
        color="inherit"
        onClick={onHandleDelete}
      >
        Remove
        {deleteIntegrationMutation.isLoading && <ButtonProgress size={24} />}
      </Button>
      <Grid container sx={styles.inputBlock}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            id="picqer_username"
            label="User Name"
            margin="normal"
            fullWidth
            value={formData.username || ""}
            onChange={handleFormData("username")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            id="picqer_password"
            label="Password"
            margin="normal"
            fullWidth
            type="password"
            value={formData.password || ""}
            onChange={handleFormData("password")}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default PicqerIntegrationTab;
