import React, { useState, useEffect } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import {
  SignIn,
  CustomAppBar,
  ResetPassword,
  ForgotPassword,
} from "@shared/components/lib/index";
import { useHistory } from "react-router-dom";
import conf from "common-utils/conf";
import { PAGES, SUPPORT_PAGES } from "./routers";
import { useLocalStorage } from "usehooks-ts";
import { postRequestUI } from "common-utils/utils/api";

export default function AdminApp() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userType, setUserType] = useState(null);
  const [auth, setAuth] = useLocalStorage("auth", null);
  const history = useHistory();

  useEffect(() => {
    authenticate();
  }, [auth]);

  const authenticate = async () => {
    try {
      if (!auth) {
        setUserType(null);
        return;
      }
      const authRole = auth.role.userType;
      if (authRole) {
        redirectIfNotAdmin(authRole);
        setUserType(authRole);
        history.push("/orders");
      }
    } catch (error) {
      setUserType(null);
    }
  };

  const redirectIfNotAdmin = async (userType) => {
    if (userType === "carrier" || userType === "retailer") {
      await signOut();
      return;
    }
  };

  const signOut = async () => {
    await postRequestUI("/logout");
    setAuth(null);
    history.push("/login");
  };

  const handleLogout = async () => {
    setUserType(null);
    setAnchorEl(null);
    signOut();
  };

  const pages = userType === "support" ? SUPPORT_PAGES : PAGES;

  if (!auth) {
    return (
      <Switch>
        <Route path={"/forgot-password/:token?"} component={ForgotPassword} />
        <Route
          path={"/reset-password/:token?"}
          component={() => <ResetPassword appUrl={conf.admin} />}
        />
        <Route exact path={"/login"} component={SignIn} />
        <Route path="*" render={() => <Redirect to={"/login"} />} />
      </Switch>
    );
  }

  if (
    userType === "admin" ||
    userType === "superAdmin" ||
    userType === "support"
  ) {
    return (
      <>
        <CustomAppBar
          pages={pages}
          appName={"A"}
          mobileOpen={mobileOpen}
          setMobile={setMobileOpen}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleLogout={handleLogout}
        />
        <Switch>
          <main>
            <Route exact path="/">
              <Redirect to={"/orders"} />
            </Route>

            {pages.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                render={route.component}
              />
            ))}
          </main>
        </Switch>
      </>
    );
  }
  return <></>;
}
