import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import { formatAddress } from "common-utils/utils/formatter";
import {
  Filters,
  TableComponent,
  MainBox,
  DateFormatter,
} from "@shared/components/lib/index";
import { getRequestUI, postRequestUI } from "common-utils/utils/api";
import DialogResendPaazlOrders from "../components/DialogResendPaazlOrders";
import { TIME_SHOW_ERROR } from "common-utils/src/constants";
import { DateTime } from "luxon";

const PaazlOrders = () => {
  const [showProgress, setShowProgress] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedStartPickUpDateFilter, setSelectedStartPickUpDateFilter] =
    useState(new Date());
  const date = new Date();
  date.setDate(date.getDate() + 1);
  const [selectedEndPickUpDateFilter, setSelectedEndPickUpDateFilter] =
    useState(date);
  const [isOpenDialogResend, openResendDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    updateTable();
  }, []);

  useEffect(() => {
    updateTable();
  }, []);

  const updateTable = () => {
    setShowProgress(true);
    return getRequestUI("/paazlOrders", getFilterData())
      .then((result) => {
        setShowProgress(false);
        setOrders(result);
      })
      .catch((error) => {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
      });
  };

  const getFilterData = () => ({
    startDate: DateTime.fromJSDate(selectedStartPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
    endDate: DateTime.fromJSDate(selectedEndPickUpDateFilter).toFormat(
      "yyyy-MM-dd"
    ),
  });

  /**
   * Data to be passed to table barcode, consignee_adreess, shipper_address, status, date_created
   */
  const adminTableData = {
    headers: [
      "Barcode",
      "Consignee Address",
      "Shipper Address",
      "Delivery Status",
      "Created Date",
      "",
    ],
    body: [
      (order) => order.barcode,
      (order) =>
        formatAddress(
          order.consignee.postalCode,
          order.consignee.streetNumber,
          order.consignee.streetNumberSuffix,
          order.consignee.street
        ),
      (order) =>
        formatAddress(
          order.shipper.postalCode,
          order.shipper.streetNumber,
          order.shipper.streetNumberSuffix,
          order.shipper.street
        ),
      (order) =>
        _.get(order, "errorMessage") ? (
          <Tooltip title={<div>{_.get(order, "errorMessage")}</div>}>
            <Button>{order.status}</Button>
          </Tooltip>
        ) : (
          <Button>{order.status}</Button>
        ),
      (order) => (
        <DateFormatter date={order.createdAt} format={"dd-MM-yyyy HH:mm"} />
      ),
      (order) =>
        order.status === "MANCO" ? (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={setOpenResendDialog(order)}
          >
            Fix
          </Button>
        ) : (
          <></>
        ),
    ],
  };
  const setOpenResendDialog = (order) => () => {
    setSelectedOrder(order);
    openResendDialog(true);
  };

  const setCloseResendDialog = () => {
    setSelectedOrder({});
    openResendDialog(false);
  };
  const submitToResendOrder = async (order) => {
    try {
      setShowProgress(true);
      await postRequestUI("/resend-failed-paazl-orders", order);
      updateTable();
    } catch (error) {
      if (error.code === "invalid-argument") {
        setErrorMessage(error.message);
      }
    } finally {
      setCloseResendDialog();
      setShowProgress(false);
    }
  };

  const onCloseErrorMessage = () => {
    setErrorMessage("");
    setShowError(false);
  };

  return (
    <MainBox isFullWidth={true}>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <DialogResendPaazlOrders
        open={isOpenDialogResend}
        order={selectedOrder}
        showProgress={showProgress}
        handleClose={setCloseResendDialog}
        handleSubmit={submitToResendOrder}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!errorMessage}
        autoHideDuration={TIME_SHOW_ERROR}
        onClose={onCloseErrorMessage}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <div style={{ padding: 15 }}>
        <Filters
          setTitle={"Paazl orders"}
          selectedStartPickUpDateFilter={selectedStartPickUpDateFilter}
          setSelectedStartPickUpDateFilter={setSelectedStartPickUpDateFilter}
          selectedEndPickUpDateFilter={selectedEndPickUpDateFilter}
          setSelectedEndPickUpDateFilter={setSelectedEndPickUpDateFilter}
          hideExport={true}
          dateName={"Date"}
          onApplyFilter={updateTable}
        />
      </div>

      <TableComponent
        headers={adminTableData.headers}
        rowData={orders}
        cellComponents={adminTableData.body}
      />
    </MainBox>
  );
};

export default PaazlOrders;
