import { z } from "zod";

export const OrderDelivery = z.object({
  selectedProductId: z.any(),
  selectedLocation: z.any(),
  selectedCountry: z
    .object({
      name: z.string(),
      code: z.string(),
    })
    .optional(),
  address: z
    .object({
      postcode: z.string().min(3).max(6).optional(),
      houseNumber: z.string().min(1).max(5).optional(),
      houseAddition: z.string().optional(),
      street: z.string().optional(),
      city: z.string().optional(),
      dropOfCoordinates: z
        .object({
          lat: z.number(),
          lng: z.number(),
        })
        .optional(),
    })
    .optional(),
  selectedSizeId: z.object({}).optional(),
  contact: z.object({
    recipientEmail: z.string().email({ message: "Invalid email address" }),
    recipientName: z.string().min(1, "Please enter the recipient name"),
  }),
  extras: z
    .object({
      description: z.string().optional(),
      externalId: z
        .string()
        .trim()
        .refine((value) => value === "" || /^[a-zA-Z0-9-_.]+$/.test(value), {
          message:
            "Reference must be alphanumeric, hyphens, underscores, periods, or empty",
        })
        .optional(),
    })
    .optional(),
  options: z
    .object({
      signature: z.boolean().optional(),
      deliverToNeighbours: z.boolean().optional(),
      secondFactor: z.boolean().optional(),
      selectedIncurance: z.any().optional(),
    })
    .optional(),
});
