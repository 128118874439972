"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _green = _interopRequireDefault(require("@mui/material/colors/green"));
var _default = exports["default"] = function _default(theme) {
  return {
    paper: {
      position: "relative",
      padding: theme.spacing(3)
    },
    addButton: {
      position: "absolute",
      top: 20,
      right: 20
    },
    table: {
      minWidth: 650
    },
    root: {
      display: "flex"
    },
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    formControl: {
      margin: theme.spacing(5),
      minWidth: 120,
      width: 450
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    buttonSuccess: {
      backgroundColor: _green["default"][500],
      "&:hover": {
        backgroundColor: _green["default"][700]
      }
    },
    form: {
      maxWidth: "330px",
      // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    floatingLabelFocusStyle: {
      color: "#0635C9 !important"
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: "#0635C9 !important"
    },
    multilineColor: {
      color: "#000000"
    },
    buttonSubmit: {
      marginTop: 1,
      marginBottom: 1,
      borderRadius: 40,
      color: "white",
      backgroundColor: "#0635C9"
    },
    infoMsg: {
      display: "flex",
      justifyContent: "center",
      color: "black"
    },
    input: {
      color: "0635C9"
    }
  };
};