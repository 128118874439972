import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogAppBar from "../components/DialogAppBar";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import { ButtonProgress } from "@shared/components/lib/index";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { FormControl } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  formControl: {
    minWidth: 300,
    paddingTop: 2,
  },
  buttonSubmit: {
    marginTop: 5,
    marginBottom: 10,
    borderRadius: 40,
    color: "#fff",
    backgroundColor: "#0635C9",
    fontFamily: "Nunito, sans-serif",
    fontWeight: "bold",
    textTransform: "none",
  },
  labelRoot: {
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
});

const DialogResendPaazlOrders = ({
  open,
  order,
  showProgress,
  handleClose,
  handleSubmit,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [fields, setFields] = useState({});

  useEffect(() => {
    setFields({ ...order });
  }, [order]);

  const chooseField = (field, name, value) => {
    if (!field) {
      setFields({
        ...fields,
        [name]: value,
      });
      return;
    }
    setFields({
      ...fields,
      [field]: {
        ...fields[field],
        [name]: value,
      },
    });
  };
  const setError = (field, name) => {
    if (!field) {
      return !fields[name];
    }
    return !fields[field][name];
  };
  const getHelperText = (field, name) => {
    if (!field) {
      return !fields[name] ? `Field is required!` : "";
    }
    return !fields[field][name] ? `Field is required!` : "";
  };
  const checkError = () => {
    const { consignee, shipper, clientNumber } = fields;
    if (!consignee || !shipper) {
      return true;
    }
    return (
      !!consignee.name &&
      !!consignee.postalCode &&
      !!consignee.street &&
      !!consignee.streetNumber &&
      !!shipper.companyName &&
      !!shipper.postalCode &&
      !!shipper.street &&
      !!shipper.streetNumber &&
      !!clientNumber
    );
  };
  const submit = () => {
    if (!checkError()) {
      return;
    }
    handleSubmit(fields);
  };
  if (_.isEmpty(fields)) {
    return <></>;
  }
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogAppBar>
        <Toolbar>
          <IconButton
            data-testid="close_resend_dialog_btn"
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Button
            data-testid="save_resend_order_btn"
            autoFocus
            disabled={!checkError()}
            color="inherit"
            onClick={submit}
          >
            {"Save"}
            {showProgress && <ButtonProgress size={24} />}
          </Button>
        </Toolbar>
      </DialogAppBar>
      <DialogContent>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Client Number</Typography>
              <Paper>
                <FormControl sx={styles.formControl}>
                  <TextField
                    data-testid="client_number_field"
                    label="Retailer Number"
                    fullWidth
                    margin="normal"
                    value={fields.clientNumber}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    error={setError("", "clientNumber")}
                    helperText={getHelperText("", "clientNumber")}
                    onChange={(e) =>
                      chooseField("", "clientNumber", e.target.value)
                    }
                  />
                </FormControl>
              </Paper>
            </Grid>

            {/* Second Column Form */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Consignee Address</Typography>
              <Paper style={{ padding: 16 }}>
                <FormControl sx={styles.formControl}>
                  <TextField
                    data-testid="consignee_name_field"
                    label="Name"
                    required
                    fullWidth
                    margin="normal"
                    value={fields.consignee.name}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    error={setError("consignee", "name")}
                    helperText={getHelperText("consignee", "name")}
                    onChange={(e) =>
                      chooseField("consignee", "name", e.target.value)
                    }
                  />
                  <TextField
                    data-testid="consignee_name_field"
                    label="Company Name"
                    fullWidth
                    margin="normal"
                    value={fields.consignee.companyName}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    onChange={(e) =>
                      chooseField("consignee", "companyName", e.target.value)
                    }
                  />
                  <TextField
                    data-testid="consignee_postcode_field"
                    id="consignee_postcode"
                    label="Zipcode"
                    required
                    fullWidth
                    margin="normal"
                    value={fields.consignee.postalCode}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    error={setError("consignee", "postalCode")}
                    helperText={getHelperText("consignee", "postalCode")}
                    onChange={(e) =>
                      chooseField("consignee", "postalCode", e.target.value)
                    }
                  />
                  <TextField
                    data-testid="consignee_street_field"
                    label="Street"
                    required
                    fullWidth
                    margin="normal"
                    value={fields.consignee.street}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    error={setError("consignee", "street")}
                    helperText={getHelperText("consignee", "street")}
                    onChange={(e) =>
                      chooseField("consignee", "street", e.target.value)
                    }
                  />
                  <TextField
                    data-testid="consignee_street_number_field"
                    label="Street Number"
                    required
                    fullWidth
                    margin="normal"
                    value={fields.consignee.streetNumber}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    error={setError("consignee", "streetNumber")}
                    helperText={getHelperText("consignee", "streetNumber")}
                    onChange={(e) =>
                      chooseField("consignee", "streetNumber", e.target.value)
                    }
                  />
                  <TextField
                    data-testid="consignee_street_number_suffix_field"
                    label="Street Number Suffix"
                    fullWidth
                    margin="normal"
                    value={fields.consignee.streetNumberSuffix}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    onChange={(e) =>
                      chooseField(
                        "consignee",
                        "streetNumberSuffix",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6">Shipper Address</Typography>
              <Paper style={{ padding: 16 }}>
                <FormControl sx={styles.formControl}>
                  <TextField
                    data-testid="shipper_name_field"
                    label="Company Name"
                    required
                    fullWidth
                    margin="normal"
                    value={fields.shipper.companyName}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    error={setError("shipper", "companyName")}
                    helperText={getHelperText("shipper", "companyName")}
                    onChange={(e) =>
                      chooseField("shipper", "companyName", e.target.value)
                    }
                  />
                  <TextField
                    data-testid="shipper_postcode_field"
                    id="shipper_postcode"
                    label="Zipcode"
                    required
                    fullWidth
                    margin="normal"
                    value={fields.shipper.postalCode}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    error={setError("shipper", "postalCode")}
                    helperText={getHelperText("shipper", "postalCode")}
                    onChange={(e) =>
                      chooseField("shipper", "postalCode", e.target.value)
                    }
                  />
                  <TextField
                    data-testid="shipper_street_field"
                    label="Street"
                    required
                    fullWidth
                    margin="normal"
                    value={fields.shipper.street}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    error={setError("shipper", "street")}
                    helperText={getHelperText("shipper", "street")}
                    onChange={(e) =>
                      chooseField("shipper", "street", e.target.value)
                    }
                  />
                  <TextField
                    data-testid="shipper_street_number_field"
                    label="Street Number"
                    required
                    fullWidth
                    margin="normal"
                    value={fields.shipper.streetNumber}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    error={setError("shipper", "streetNumber")}
                    helperText={getHelperText("shipper", "streetNumber")}
                    onChange={(e) =>
                      chooseField("shipper", "streetNumber", e.target.value)
                    }
                  />
                  <TextField
                    data-testid="shipper_street_number_suffix_field"
                    label="Street Number Suffix"
                    fullWidth
                    margin="normal"
                    value={fields.shipper.streetNumberSuffix}
                    InputLabelProps={{
                      classes: {
                        root: styles.labelRoot,
                      },
                    }}
                    onChange={(e) =>
                      chooseField(
                        "shipper",
                        "streetNumberSuffix",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default DialogResendPaazlOrders;
