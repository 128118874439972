import React, { useState } from "react";
import _ from "lodash";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import CarrierHubsForm from "./CarrierHubsForm";
import { getRequestUI, deleteRequestUI } from "common-utils/utils/api";
import useTheme from "@mui/material/styles/useTheme";
import { useMutation, useQuery } from "react-query";
const useStyles = (theme) => ({
  root: {
    "& .wrapperClassName": {
      height: "300px",
      width: "600px",
      border: "1px solid black",
    },
  },
});

import {
  IconButton as CIconButton,
  TableComponent,
  PageTitle,
  SharedStyles,
} from "@shared/components/lib/index";
import { Snackbar, Alert } from "@mui/material";

const CarrierHubsTab = ({ carrierId }) => {
  const [deletingHub, setDeletingHub] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [selectedCarrierHub, setSelectedCarrierHub] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);

  const { data: carrierHubs, refetch: reloadHubData } = useQuery(
    ["carrier-hubs"],
    async () => {
      const result = await getRequestUI(`/carrier/hubs/${carrierId}`);
      return result;
    }
  );

  const deleteMutaion = useMutation(deleteCarrierHub, {
    onSuccess: () => {
      reloadHubData();
    },
    onError: () => {
      setErrorMessage("Failed to delete the hub. Please try again.");
    },
    onSettled: () => {
      setDeletingHub(null);
    },
  });
  async function deleteCarrierHub(id) {
    await deleteRequestUI(`carrier/hubs/${id}`);
  }

  const handleDelete = (id) => {
    setDeletingHub(id);
    deleteMutaion.mutate(id);
  };

  const hubTableData = {
    headers: [
      "Name",
      "Country",
      "City",
      "Street",
      "Postcode",
      "House Number",
      "House Addition",
      "",
    ],
    body: [
      (hub) => hub.name,
      (hub) => hub.address.country.name,
      (hub) => hub.address.city,
      (hub) => hub.address.street,
      (hub) => hub.address.postcode,
      (hub) => hub.address.houseNumber,
      (hub) => hub.address.houseAdditions,
      (hub) => (
        <>
          <Button
            data-testid="edit_hub_btn"
            endIcon={<EditIcon />}
            onClick={() => clickOnEditHub(hub)}
          />
          {deletingHub === hub.id ? (
            <CircularProgress size={24} sx={styles.deleteProgress} />
          ) : (
            <CIconButton
              data-testid="delete_hub_btn"
              icon="delete"
              onClick={() => handleDelete(hub.id)}
            />
          )}
        </>
      ),
    ],
  };

  const openNewhubForm = () => {
    setSelectedCarrierHub(null);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setSelectedCarrierHub(null);
  };

  const clickOnEditHub = (hub) => {
    setSelectedCarrierHub({
      ...hub,
      postcode: hub.address.postcode,
      houseAdditions: hub.address.houseAdditions,
      houseNumber: hub.address.houseNumber,
      country: hub.address.country,
      city: hub.address.city,
      street: hub.address.street,
    });
    setOpenForm(true);
  };

  return (
    <Grid container sx={styles.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!errorMessage}
        autoHideDuration={3000}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Hubs"} />
        </div>
        <Button
          data-testid="add_hub_btn"
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={openNewhubForm}
        >
          Add New Hub
        </Button>
        <TableComponent
          headers={hubTableData.headers}
          rowData={carrierHubs || []}
          cellComponents={hubTableData.body}
        />
      </TableContainer>

      {openForm && (
        <Dialog
          open={openForm}
          onClose={handleCloseForm}
          maxWidth="sm"
          fullWidth
        >
          <CarrierHubsForm
            hub={selectedCarrierHub}
            carrierId={carrierId}
            onSave={reloadHubData}
            handleClose={handleCloseForm}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default CarrierHubsTab;
