import config from "../conf";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export const initSentry = () => {
  if (config.env === "prod") {
    Sentry.init({
      dsn: "https://25044550733343ffb130055c47a20f67@o990674.ingest.sentry.io/5947349",
      integrations: [new Integrations.BrowserTracing()],
      environment: "prod",
      ignoreErrors: ["Non-Error promise rejection captured"],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }
};
