import React, { useState } from "react";
import _ from "lodash";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import RetailerFAQForm from "./RetailerFAQForm";
import { getRequestUI, deleteRequestUI } from "common-utils/utils/api";
import useTheme from "@mui/material/styles/useTheme";
import { useMutation, useQuery } from "react-query";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { htmlToText } from "html-to-text";

const useStyles = (theme) => ({
  root: {
    "& .wrapperClassName": {
      height: "300px",
      width: "600px",
      border: "1px solid black",
    },
  },
});
import {
  IconButton as CIconButton,
  TableComponent,
  PageTitle,
  SharedStyles,
} from "@shared/components/lib/index";
import { Snackbar, Alert } from "@mui/material";

const RetailerFAQTab = ({ retailerId }) => {
  const [deletingFaq, setDeletingFaq] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [faqData, setFaqData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);

  const { data, refetch: reloadFaqData } = useQuery(
    ["retailer-faq"],
    async () => {
      const result = await getRequestUI(`/retailer/faq/${retailerId}`);
      return result;
    }
  );

  const deleteMutaion = useMutation(deleteRetailerFaq, {
    onSuccess: () => {
      reloadFaqData();
    },
    onError: () => {
      setErrorMessage("Failed to delete faq. Please try again.");
    },
    onSettled: () => {
      setDeletingFaq(null);
    },
  });
  async function deleteRetailerFaq(id) {
    await deleteRequestUI(`retailer/faq/${id}`);
  }

  const handleDelete = (id) => {
    setDeletingFaq(id);
    deleteMutaion.mutate(id);
  };

  const faqTableData = {
    headers: ["Title", "Text", "", ""],
    body: [
      (faq) => faq.title,
      (faq) => (
        <Tooltip title={htmlToText(faq.text)} placement="top-start">
          <Typography noWrap>
            <span
              style={{
                display: "inline-block",
                width: "50vh",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {htmlToText(faq.text)}
            </span>
          </Typography>
        </Tooltip>
      ),
      (faq) => (
        <Button
          data-testid="edit_faq_btn"
          endIcon={<EditIcon />}
          onClick={() => clickOnEditFaq(faq)}
        />
      ),
      (faq) =>
        deletingFaq === faq.id ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton
            data-testid="delete_faq_btn"
            icon="delete"
            onClick={() => handleDelete(faq.id)}
          />
        ),
    ],
  };

  const openNewFAQForm = () => {
    setFaqData(null);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFaqData(null);
  };

  const clickOnEditFaq = (faq) => {
    setFaqData({
      id: faq.id,
      title: faq.title,
      text: faq.text,
    });
    setOpenForm(true);
  };

  return (
    <Grid container sx={styles.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!errorMessage}
        autoHideDuration={3000}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"FAQ"} />
        </div>
        <Button
          data-testid="add_faq_btn"
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={openNewFAQForm}
        >
          Add New FAQ
        </Button>
        <TableComponent
          headers={faqTableData.headers}
          rowData={data || []}
          cellComponents={faqTableData.body}
        />
      </TableContainer>

      {openForm && (
        <Dialog
          open={openForm}
          onClose={handleCloseForm}
          maxWidth="sm"
          fullWidth
        >
          <RetailerFAQForm
            faq={faqData}
            retailerId={retailerId}
            onSave={reloadFaqData}
            handleClose={handleCloseForm}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default RetailerFAQTab;
