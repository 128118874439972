import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useTheme from "@mui/material/styles/useTheme";
import { ButtonProgress } from "@shared/components/lib/index";
import { useMutation, useQueryClient } from "react-query";
import {
  getRequestUI,
  postRequestUI,
  deleteRequestUI,
  putRequestUI,
} from "common-utils/utils/api";
import HtmlEditor from "./HtmlEditor";
import { useQuery } from "react-query";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import isEmpty from "lodash/isEmpty";

const RetailerPromotionsTab = ({ retailerId }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [smsText, setSMSText] = useState("");
  const [template, setTemplate] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const queryClient = useQueryClient();
  const { data: promotion } = useQuery(
    ["promotion", retailerId],
    getPromotion,
    {
      enabled: !!retailerId,
      onSuccess: onSuccessQuery,
    }
  );
  const { data: locations } = useQuery(
    ["locations", retailerId],
    getLocations,
    {
      enabled: !!retailerId,
    }
  );
  const promotionMutation = useMutation(savePromotion, {
    onSuccess: onSuccessMutation,
  });
  const updatePromotionMutation = useMutation(updatePromotion, {
    onSuccess: onSuccessMutation,
  });
  const deletePromotionMutation = useMutation(removePromotion, {
    onSuccess: onSuccessMutation,
    enabled: !!retailerId,
  });

  function getLocations() {
    return getRequestUI(`/retailer/locations/${retailerId}`);
  }
  function getPromotion() {
    return getRequestUI(`/retailer-promotion/${retailerId}`);
  }
  function savePromotion(data) {
    return postRequestUI(`/retailer-promotion/${retailerId}`, data);
  }
  function updatePromotion(data) {
    return putRequestUI(`/retailer/promotion/${retailerId}`, data);
  }
  function removePromotion() {
    return deleteRequestUI(`/retailer-promotion/${retailerId}`);
  }

  function onSuccessMutation() {
    queryClient.invalidateQueries("promotion");
  }

  function onSuccessQuery(data) {
    if (data) {
      setSMSText(data.smsText);
      setTemplate(data.emailText);
    }
  }

  useEffect(() => {
    if (promotion && locations) {
      setSelectedLocations(
        locations.filter((location) =>
          promotion.locations.includes(location.id)
        )
      );
    }
  }, [promotion, locations]);

  const onHandleSave = () => {
    const locationIds = selectedLocations.map((location) => location.id);
    promotionMutation.mutate({
      smsText,
      emailText: template,
      locations: locationIds,
    });
  };
  const onHandleUpdate = () => {
    const locationIds = selectedLocations.map((location) => location.id);
    updatePromotionMutation.mutate({
      smsText,
      emailText: template,
      locations: locationIds,
    });
  };
  const onHandleDelete = () => {
    deletePromotionMutation.mutate();
  };

  return (
    <Grid container sx={styles.root}>
      {isEmpty(promotion) ? (
        <Button
          data-testid="save_promotion_btn"
          sx={styles.submitBtn}
          autoFocus
          color="inherit"
          onClick={onHandleSave}
        >
          Save
          {promotionMutation.isLoading && <ButtonProgress size={24} />}
        </Button>
      ) : (
        <Button
          data-testid="update_promotion_btn"
          sx={styles.submitBtn}
          autoFocus
          color="inherit"
          onClick={onHandleUpdate}
        >
          Update
          {promotionMutation.isLoading && <ButtonProgress size={24} />}
        </Button>
      )}
      <Button
        data-testid="remove_promotion_btn"
        sx={styles.removeBtn}
        autoFocus
        color="inherit"
        onClick={onHandleDelete}
      >
        Remove
        {deletePromotionMutation.isLoading && <ButtonProgress size={24} />}
      </Button>
      <Grid item xs={4}>
        <Autocomplete
          options={locations || []}
          id="locations"
          getOptionLabel={(option) => option.name ?? ""}
          classes={{
            endAdornment: styles.endAdornment,
          }}
          multiple
          value={selectedLocations}
          onChange={(event, reasons) => setSelectedLocations(reasons)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Send Promotion for these locations"
              variant="standard"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <HtmlEditor
          retailerId={retailerId}
          template={template}
          setTemplate={setTemplate}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={styles.inputLabel} variant="h5">
          SMS Text
        </Typography>
        <TextareaAutosize
          style={{ height: "200px", width: "600px" }}
          id="sms-text"
          value={smsText}
          minRows={4}
          maxLength={200}
          onChange={(e) => setSMSText(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = (theme) => ({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  root: {
    "& .wrapperClassName": {
      height: "300px",
      width: "600px",
      border: "1px solid black",
    },
  },
  submitBtn: {
    position: "absolute",
    top: "14px",
    zIndex: 10000,
    color: "#fff",
    left: "60px",
  },
  removeBtn: {
    position: "absolute",
    top: "14px",
    zIndex: 10000,
    color: "#fff",
    left: "120px",
  },
  inputLabel: {
    fontWeight: "bold",
    fontSize: "1.3em",
  },
});

export default RetailerPromotionsTab;
