import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import PicqerIntegrationTab from "./PicqerIntegrationTab";
import useTheme from "@mui/material/styles/useTheme";

const useStyles = (theme) => ({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
});

const picqerIntegration = {
  id: "picqer",
  name: "Picqer",
};

const integrations = [picqerIntegration];

const RetailerIntegrationTab = ({ retailerId }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [tabValue, setTabValue] = useState(picqerIntegration.id);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="Vertical tabs example"
        sx={styles.tabs}
      >
        {integrations.map((i, index) => (
          <Tab label={i.name} value={i.id} index={index} />
        ))}
      </Tabs>
      <PicqerIntegrationTab
        tabValue={tabValue}
        integration={picqerIntegration}
        retailerId={retailerId}
      />
    </Grid>
  );
};

export default RetailerIntegrationTab;
