import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getRequestUI,
  putRequestUI,
  postRequestUI,
} from "common-utils/utils/api";
import Button from "@mui/material/Button";
import { SharedStyles } from "@shared/components/lib/index";
import useTheme from "@mui/material/styles/useTheme";

const RetailerETATab = ({ retailerId, prerequisites }) => {
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [etaText, setEtaText] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const queryClient = useQueryClient();
  const allStatuses = prerequisites.statuses;

  const { isLoading: isLoadingEta } = useQuery(
    ["retailerEta", retailerId],
    () =>
      getRequestUI(`/retailer-tracking-settings/${retailerId}`).then(
        (response) => {
          const { eta } = response;
          const selectedStatuses = allStatuses.filter((status) =>
            eta.statuses.includes(status.id)
          );
          setSelectedStatuses(selectedStatuses);
          setEtaText(eta.etaText);
          setIsUpdate(true);
          return eta;
        }
      ),
    {
      enabled: !!retailerId,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  const createMutation = useMutation(
    (newData) =>
      postRequestUI(`/retailer-tracking-settings/${retailerId}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["statuses", retailerId]);
      },
    }
  );

  const updateMutation = useMutation(
    (newData) =>
      putRequestUI(`/retailer-tracking-settings/${retailerId}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["statuses", retailerId]);
      },
    }
  );

  const handleSave = () => {
    const statuses = selectedStatuses.map((status) => status.id);
    const data = { statuses, etaText };

    if (isUpdate) {
      updateMutation.mutate(data);
    } else {
      createMutation.mutate(data);
    }
  };

  return (
    <Grid container sx={styles.root}>
      <Button
        data-testid="change_eta"
        sx={{
          position: "absolute",
          top: "14px",
          left: "60px",
          color: "#fff",
          zIndex: 10000,
        }}
        variant="text"
        onClick={handleSave}
      >
        {isUpdate ? "Update ETA Status" : "Change ETA Status"}
      </Button>
      <Grid item xs={12} md={7}>
        <Autocomplete
          options={allStatuses}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => setSelectedStatuses(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose statuses for hidng ETA"
              variant="standard"
              InputProps={{ ...params.InputProps }}
            />
          )}
          value={selectedStatuses}
          multiple
          loading={isLoadingEta}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <TextField
          fullWidth
          label="Eta Message Text"
          variant="standard"
          value={etaText}
          onChange={(e) => setEtaText(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

const useStyles = (theme) => ({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  root: {
    "& .wrapperClassName": {
      height: "300px",
      width: "600px",
      border: "1px solid black",
    },
  },
  inputLabel: {
    fontWeight: "bold",
    fontSize: "1.3em",
  },
  modal: {
    position: "absolute",
    width: 800,
  },
  listItem: {
    padding: "4px 0px",
  },
  list: {
    padding: "40px 0",
  },
});

export default RetailerETATab;
