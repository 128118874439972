import React, { useState, useEffect, useCallback, useRef } from "react";
import _ from "lodash";
import "date-fns";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import useTheme from "@mui/material/styles/useTheme";
import { PostCodes, SharedStyles } from "@shared/components/lib/index";
import {
  postRequestUI,
  getRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";

function CarrierPostcodes({ carrierId }) {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [newPostcodeloading, setNewPostcodeloading] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [city, setCity] = useState("");
  const [cities, setCities] = useState([]);
  const [postCodes, setPostCodes] = useState([]);
  const [deletingPostCode, setDeletingPostCode] = useState(false);

  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);

  useEffect(() => {
    (async () => {
      await updateTable();
    })();
  }, []);

  useEffect(() => {
    setErrorMessage("");
    setShowError(false);
  }, [from, to]);

  const updateTable = async () => {
    setShowProgress(true);
    try {
      const response = await getRequestUI(`/postcode/${carrierId}`);
      setPostCodes(response);
      setShowProgress(false);
    } catch (error) {
      var code = error.code;
      var message = error.message;

      if (code === "invalid-argument") {
        setErrorMessage(message);
        setShowError(true);
      }
    }
  };

  const addCarriersPostcodes = async (postcode) => {
    try {
      await postRequestUI(`/postcodes/carrier/${carrierId}`, postcode);
      setOpen(false);
      setNewPostcodeloading(false);
      await updateTable();
    } catch (error) {
      var code = error.code;
      var message = error.message;

      if (code === "invalid-argument" || code === "already-exists") {
        setNewPostcodeloading(false);
        setErrorMessage(message);
        setShowError(true);
      }
    }
  };

  const handleDelete = useCallback(
    (id) => async () => {
      setDeletingPostCode(id);
      deleteRequestUI("/postcode", { id: id });
      try {
        await updateTable();
        setDeletingPostCode();
      } catch (error) {
        setDeletingPostCode();
      }
    },
    []
  );

  return (
    <TableContainer component={Paper} sx={sharedStyles.paper}>
      <PostCodes
        setShowError={setShowError}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        isAdmin={true}
        addCarriersPostcodes={addCarriersPostcodes}
        postcodes={postCodes}
        open={open}
        setOpen={setOpen}
        setShowProgress={setShowProgress}
        showProgress={showProgress}
        handleDelete={handleDelete}
      />
    </TableContainer>
  );
}
export default CarrierPostcodes;
