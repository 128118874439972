import React, { useState } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import {
  getRequestUI,
  deleteRequestUI,
  postRequestUI,
  putRequestUI,
} from "common-utils/utils/api";
import DialogAppBar from "../components/DialogAppBar";
import Checkbox from "@mui/material/Checkbox";
import Slide from "@mui/material/Slide";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import useTheme from "@mui/material/styles/useTheme";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  ButtonProgress,
  IconButton as CIconButton,
  PageTitle,
  TableComponent,
  SharedStyles,
} from "@shared/components/lib/index";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RetailerCarrierTab = ({ retailerId }) => {
  const trackingOnMode = {
    name: "On",
    value: "on",
  };
  const trackingRedirectMode = {
    name: "Redirect",
    value: "redirect",
  };

  const trackingOverwriteMode = {
    name: "Overwrite",
    value: "overwrite",
  };
  const trackingModes = [
    trackingOnMode,
    trackingRedirectMode,
    trackingOverwriteMode,
  ];

  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);
  const [deletingProgress, setDeletingProgress] = useState(null);
  const [isAddCarrier, setIsAddCarrier] = useState(false);
  const [carrierEdit, setCarrierEdit] = useState(false);
  const [formData, setFormData] = useState({
    verifyAddress: true,
    onlinePdfGeneration: true,
  });
  const [isCreatingNew, setCreatingNew] = useState(false);
  const queryClient = useQueryClient();
  const retailerCarrierQuery = useQuery(
    ["retailer-carriers", retailerId],
    getRetailerCarriers
  );
  const availableCarrierQuery = useQuery(
    ["available-carriers", isAddCarrier],
    getAvailableCarriers,
    { enabled: isAddCarrier }
  );
  const createRetailerCarrierMutation = useMutation(createRetailerCarrier, {
    onSuccess: onSuccessRetailerCarrierMutation,
    onError: () => handleClose(),
  });
  const updateRetailerCarrierMutation = useMutation(updateRetailerCarrier, {
    onSuccess: onSuccessRetailerCarrierMutation,
    onError: () => handleClose(),
  });
  const deleteRetailerCarrierMutation = useMutation(deleteRetailerCarrier, {
    onSuccess: onSuccessRetailerCarrierMutation,
  });

  function onSuccessRetailerCarrierMutation() {
    queryClient.invalidateQueries("retailer-carriers");
  }

  function getRetailerCarriers() {
    return getRequestUI(`/retailer-carriers/${retailerId}`);
  }
  function getAvailableCarriers() {
    return getRequestUI(`/available-carriers/${retailerId}`);
  }

  async function createRetailerCarrier(data) {
    setCreatingNew(true);
    await postRequestUI("/retailer/carrier", data);
    setCreatingNew(false);
    handleClose();
  }

  async function updateRetailerCarrier(data) {
    await putRequestUI(`/retailer/carrier`, data);
    handleClose();
  }

  async function deleteRetailerCarrier(id) {
    setDeletingProgress(id);
    await deleteRequestUI("/retailer/carrier", { id });
    setDeletingProgress(null);
  }

  const handleSave = async () => {
    const data = {
      retailerId,
      carrierId: formData.carrier.id,
      sms: formData.sms,
      twoFactorAuth: formData.twoFactorAuth,
      trackAndTrace: formData.trackAndTrace,
      statusUpdateNotification: formData.statusUpdateNotification,
      signatureRequired: formData.signatureRequired,
      allowDeliveryChange: formData.allowDeliveryChange,
      verifyAddress: formData.verifyAddress,
      reviewMail: formData.reviewMail,
      gpsRequired: formData.gpsRequired,
      trackingMode: formData.trackingMode
        ? formData.trackingMode.value
        : trackingOnMode.value,
      returnLabel: formData.returnLabel,
      showPropertiesOnLabel: formData.showPropertiesOnLabel,
      onlinePdfGeneration: formData.onlinePdfGeneration,
    };
    createRetailerCarrierMutation.mutate(data);
  };

  const handleEdit = async () => {
    const data = {
      id: formData.carrier.id,
      retailerId,
      carrierId: formData.carrier.carrierId,
      sms: formData.sms,
      twoFactorAuth: formData.twoFactorAuth,
      trackAndTrace: formData.trackAndTrace,
      statusUpdateNotification: formData.statusUpdateNotification,
      signatureRequired: formData.signatureRequired,
      allowDeliveryChange: formData.allowDeliveryChange,
      verifyAddress: formData.verifyAddress,
      reviewMail: formData.reviewMail,
      gpsRequired: formData.gpsRequired,
      trackingMode: formData.trackingMode.value,
      returnLabel: formData.returnLabel,
      showPropertiesOnLabel: formData.showPropertiesOnLabel,
      onlinePdfGeneration: formData.onlinePdfGeneration,
    };
    updateRetailerCarrierMutation.mutate(data);
  };

  const handleDeleteCarrier = async (id) => {
    deleteRetailerCarrierMutation.mutate(id);
  };

  const carrierTableData = {
    headers: [
      "",
      "Name",
      "Tracking Mode",
      "Status Update Notification",
      "SMS",
      "Two Factor Auth",
      "Signature",
      "Allow Delivery Change",
      "Review Mail",
      "Verify Address",
      "Require GPS Stamp",
      "Return Label",
      "Show Properties On Label",
      "Online PDF Generation",
      "",
    ],
    body: [
      (carrier) => (
        <Button
          endIcon={<EditIcon />}
          onClick={() => handleSetEditCarrier(carrier)}
        />
      ),
      (carrier) => carrier.name,
      (carrier) =>
        trackingModes.find((mode) => mode.value === carrier.trackingMode).name,
      (carrier) => (
        <Checkbox checked={carrier.statusUpdateNotification} readOnly />
      ),
      (carrier) => <Checkbox checked={carrier.sms} readOnly />,
      (carrier) => <Checkbox checked={carrier.twoFactorAuth} readOnly />,
      (carrier) => <Checkbox checked={carrier.signatureRequired} readOnly />,
      (carrier) => <Checkbox checked={carrier.allowDeliveryChange} readOnly />,
      (carrier) => <Checkbox checked={carrier.reviewMail} readOnly />,
      (carrier) => <Checkbox checked={carrier.verifyAddress} readOnly />,
      (carrier) => <Checkbox checked={carrier.gpsRequired} readOnly />,
      (carrier) => <Checkbox checked={carrier.returnLabel} readOnly />,
      (carrier) => (
        <Checkbox checked={carrier.showPropertiesOnLabel} readOnly />
      ),
      (carrier) => <Checkbox checked={carrier.onlinePdfGeneration} readOnly />,
      (carrier) =>
        deletingProgress === carrier.id ? (
          <CircularProgress size={24} sx={sharedStyles.deleteProgress} />
        ) : (
          <CIconButton
            icon="delete"
            onClick={async () => await handleDeleteCarrier(carrier.id)}
          />
        ),
    ],
  };

  const handleClickOpenCarriers = () => {
    setIsAddCarrier(true);
    setCarrierEdit(false);
    setFormData({ verifyAddress: true, onlinePdfGeneration: true });
  };

  const handleClose = () => {
    setIsAddCarrier(false);
    setCarrierEdit(false);
  };

  const handleSetEditCarrier = (carrier) => {
    setIsAddCarrier(true);
    setCarrierEdit(true);
    const carrierData = {
      carrier: carrier,
      trackAndTrace: carrier.trackAndTrace,
      statusUpdateNotification: carrier.statusUpdateNotification,
      twoFactorAuth: carrier.twoFactorAuth,
      sms: carrier.sms,
      signatureRequired: carrier.signatureRequired,
      allowDeliveryChange: carrier.allowDeliveryChange,
      verifyAddress: carrier.verifyAddress,
      reviewMail: carrier.reviewMail,
      gps: carrier.gpsRequired,
      trackingMode: trackingModes.find(
        (mode) => mode.value === carrier.trackingMode
      ),
      returnLabel: carrier.returnLabel,
      showPropertiesOnLabel: carrier.showPropertiesOnLabel,
      onlinePdfGeneration: carrier.onlinePdfGeneration,
      allowDateChange: carrier.allowDateChange,
    };
    setFormData(carrierData);
  };

  const handleCarrier = (e, value) => {
    setFormData({
      ...formData,
      carrier: value,
    });
  };

  const showProgress = retailerCarrierQuery.isLoading;
  const getErrorMessage = () => {
    if (retailerCarrierQuery.isError) {
      return retailerCarrierQuery.error.message;
    }
    if (createRetailerCarrierMutation.isError) {
      return createRetailerCarrierMutation.error.message;
    }
    if (updateRetailerCarrierMutation.isError) {
      const error =
        updateRetailerCarrierMutation.error instanceof Error
          ? updateRetailerCarrierMutation.error.message
          : "Error by update retailer";
      return error;
    }
    if (deleteRetailerCarrierMutation.isError) {
      const error =
        deleteRetailerCarrierMutation.error instanceof Error
          ? deleteRetailerCarrierMutation.error.message
          : "Error by update retailer";
      return error;
    }
    return "";
  };

  return (
    <Grid container>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Carriers"} />
        </div>
        <Button
          data-testid="add_carrier_btn"
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => handleClickOpenCarriers()}
        >
          Add Carrier
        </Button>
        <TableComponent
          headers={carrierTableData.headers}
          rowData={retailerCarrierQuery.data || []}
          cellComponents={carrierTableData.body}
        />
      </TableContainer>
      {isAddCarrier && (
        <Box p={10} flexDirection="row">
          <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <DialogAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Button
                  data-testid="save_carrier_btn"
                  autoFocus
                  color="inherit"
                  onClick={async () =>
                    carrierEdit ? await handleEdit() : await handleSave()
                  }
                >
                  {carrierEdit ? "Update" : " Save"}
                  {isCreatingNew && <ButtonProgress size={24} />}
                </Button>
              </Toolbar>
            </DialogAppBar>
            <DialogContent>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={!!getErrorMessage()}
                autoHideDuration={3000}
              >
                <Alert severity="error">{getErrorMessage()}</Alert>
              </Snackbar>
              <form style={sharedStyles.container}>
                <FormControl sx={sharedStyles.formControl}>
                  <Typography variant="h3" component="h4">
                    {carrierEdit ? "Edit Carrier" : "Add Carrier"}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {!carrierEdit ? (
                        <Autocomplete
                          data-testid="carriers_autocomplete"
                          id="products-autocomplete"
                          autoComplete
                          autoHighlight
                          options={availableCarrierQuery.data || []}
                          value={formData.carrier}
                          getOptionLabel={(option) => option.name ?? ""}
                          disableClearable
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="standard"
                              label="Select Carrier"
                              placeholder="Select carrier"
                            />
                          )}
                          onChange={handleCarrier}
                        />
                      ) : (
                        <Typography
                          style={{ paddingTop: 20 }}
                          variant="h5"
                          component="h5"
                        >
                          {formData.carrier.name}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        data-testid="tracking_modes"
                        id="tracking_modes"
                        autoComplete
                        autoHighlight
                        options={trackingModes}
                        defaultValue={trackingOnMode}
                        value={formData.trackingMode}
                        getOptionLabel={(option) => option.name ?? ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Select Tracking Mode"
                            placeholder="Select Tracking Mode"
                          />
                        )}
                        onChange={(e, tracking) =>
                          setFormData({
                            ...formData,
                            trackingMode: tracking,
                          })
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="status_update_notification"
                            checked={formData.statusUpdateNotification || false}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                statusUpdateNotification: e.target.checked,
                              })
                            }
                            name="partner"
                            color="primary"
                          />
                        }
                        label="Status Update Notification"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="sms"
                            checked={formData.sms || false}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                sms: e.target.checked,
                              })
                            }
                            name="sms"
                            color="primary"
                          />
                        }
                        label="SMS"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="two-factor-auth"
                            checked={formData.twoFactorAuth || false}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                twoFactorAuth: e.target.checked,
                              })
                            }
                            name="twoFactorAuth"
                            color="primary"
                          />
                        }
                        label="Two Factor Auth"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="signatureRequired"
                            checked={formData.signatureRequired || false}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                signatureRequired: e.target.checked,
                              })
                            }
                            name="signature"
                            color="primary"
                          />
                        }
                        label="Signature"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="allowDeliveryChange"
                            checked={!!formData.allowDeliveryChange}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                allowDeliveryChange: e.target.checked,
                              });
                            }}
                            name="allowDeliveryChange"
                            color="primary"
                          />
                        }
                        label="Allow Delivery Change"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="reviewMail"
                            checked={!!formData.reviewMail}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                reviewMail: e.target.checked,
                              });
                            }}
                            name="reviewMail"
                            color="primary"
                          />
                        }
                        label="Review Mail"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="verifyAddress"
                            checked={formData.verifyAddress}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                verifyAddress: e.target.checked,
                              });
                            }}
                            name="verifyAddress"
                            color="primary"
                          />
                        }
                        label="Verify Address"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="requireGPS"
                            checked={formData.gpsRequired}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                gpsRequired: e.target.checked,
                              });
                            }}
                            name="requireGPS"
                            color="primary"
                          />
                        }
                        label="Require GPS Stamp"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="returnLabel"
                            checked={formData.returnLabel || false}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                returnLabel: e.target.checked,
                              });
                            }}
                            name="returnLabel"
                            color="primary"
                          />
                        }
                        label="Return Label"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="showPropertiesOnLabel"
                            checked={formData.showPropertiesOnLabel || false}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                showPropertiesOnLabel: e.target.checked,
                              });
                            }}
                            name="showPropertiesOnLabel"
                            color="primary"
                          />
                        }
                        label="Show Properties On Label"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="onlinePdfGeneration"
                            checked={formData.onlinePdfGeneration || false}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                onlinePdfGeneration: e.target.checked,
                              });
                            }}
                            name="onlinePdfGeneration"
                            color="primary"
                          />
                        }
                        label="Online PDF Generation"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Grid>
  );
};

export default RetailerCarrierTab;
