import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import _, { isEmpty } from "lodash";
import { useMutation } from "react-query";
import { putRequestUI } from "common-utils/utils/api";

const UpdateClaims = ({
  statusDialogOpen,
  handleStatusDialogClose,
  statuses,
  refetchClaims,
  currentClaim,
  setErrorMessage,
}) => {
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [availableReasons, setAvailableReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [selectedReason, setSelectedReason] = useState({});

  useEffect(() => {
    setSelectedReason({});
    setSelectedStatus({});
    const currentStatusWithReason = statuses.find(
      (statusWithReason) =>
        statusWithReason.status.statusCode === currentClaim.status.statusCode
    );
    setAvailableStatuses(currentStatusWithReason.availableStatuses);
    setAvailableReasons(currentStatusWithReason.reasons);
  }, [statusDialogOpen]);

  useEffect(() => {
    if (selectedStatus.statusCode) {
      const currentStatusWithReason = statuses.find(
        (statusWithReason) =>
          statusWithReason.status.statusCode === selectedStatus.statusCode
      );
      setAvailableReasons(currentStatusWithReason.reasons);
    }
  }, [selectedStatus]);

  const handleSave = () => {
    setIsLoading(true);
    const data = {
      orderId: currentClaim.orderId,
      status: selectedStatus,
      reason: selectedReason,
    };
    updateStatusMutation.mutate(data);
  };

  const updateStatusMutation = useMutation(updateStatus, {
    onSuccess: () => {
      refetchClaims();
      handleStatusDialogClose();
      setIsLoading(false);
    },
    onError: (error) => {
      setIsLoading(false);
      setErrorMessage(error.message);
    },
  });

  async function updateStatus(data) {
    await putRequestUI("/update-claim-status", data);
  }

  return (
    <Dialog
      open={statusDialogOpen}
      onClose={handleStatusDialogClose}
      aria-labelledby="status-dialog-title"
    >
      <Box component="span" display={isLoading ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <DialogTitle id="status-dialog-title">Update Order Status</DialogTitle>
      <DialogContent>
        <FormGroup>
          <TextField
            id="status"
            variant="standard"
            select
            label="Status"
            margin="normal"
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
          >
            {availableStatuses.map((orderStatus) => (
              <MenuItem value={orderStatus} key={orderStatus.statusCode}>
                {orderStatus.displayName}
              </MenuItem>
            ))}
          </TextField>
        </FormGroup>
        {availableReasons.length > 0 && (
          <FormGroup>
            <TextField
              id="status"
              variant="standard"
              select
              label="Reason"
              margin="normal"
              onChange={(e) => setSelectedReason(e.target.value)}
            >
              {availableReasons.map((reason, index) => (
                <MenuItem value={reason} key={index}>
                  {reason.displayName}
                </MenuItem>
              ))}
            </TextField>
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleStatusDialogClose} color="primary">
          Cancel
        </Button>
        <Button
          disabled={isEmpty(selectedStatus)}
          onClick={handleSave}
          color="primary"
        >
          Update Status
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateClaims;
