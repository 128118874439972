import React, { useState } from "react";
import { getRequestUI, postRequestUI } from "common-utils/utils/api";
import { useQuery } from "react-query";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import {
  PageTitle,
  TableComponent,
  MainBox,
} from "@shared/components/lib/index";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

function OrderExports(props) {
  const [initialOrderExports, setInitialOrderExports] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const prerequisites = useQuery(
    ["admin-order-export-prerequisites"],
    async () => {
      const result = await getRequestUI("/export-orders/admin");
      return result;
    },
    {
      onSuccess: (data) => setInitialOrderExports(data),

      onError: () => {
        setErrorMessage("Cannot get order exports. Please try again.");
      },
    }
  );

  const { data = [] } = prerequisites;

  const clickOnDownloadFile = async (id) => {
    try {
      setDownloadLoading(true);
      const response = await getRequestUI("/export-orders/admin/download", {
        orderExportId: id,
      });
      if (!response) {
        setErrorMessage("Cannot get signed url");
      }
      window.open(response.signedUrl, "_blank");
      setDownloadLoading(false);
    } catch (e) {
      setErrorMessage("Cannot get signed url");
      setDownloadLoading(false);
    }
  };

  const orderExportTableData = {
    headers: ["Name", "Status", "Created", "Download"],
    body: [
      (orderExport) => orderExport.fileName,
      (orderExport) => orderExport.status.toUpperCase(),
      (orderExport) => new Date(`${orderExport.date} UTC`).toLocaleString(),
      (orderExport) => (
        <Button
          data-testid="edit_faq_btn"
          endIcon={<DownloadIcon />}
          onClick={() => clickOnDownloadFile(orderExport.id)}
          disabled={!orderExport.isCompleted || downloadLoading}
        />
      ),
    ],
  };

  return (
    <MainBox>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!errorMessage}
        autoHideDuration={3000}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <TableContainer component={Paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Exports"} />
        </div>
        <Grid container xs={12}></Grid>
        <TableComponent
          headers={orderExportTableData.headers}
          rowData={data || []}
          cellComponents={orderExportTableData.body}
        />
      </TableContainer>
    </MainBox>
  );
}

export default OrderExports;
