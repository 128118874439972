import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import TableFilter from "../components/TableFilter";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import useTheme from "@mui/material/styles/useTheme";
import {
  putRequestUI,
  postRequestUI,
  getRequestUI,
  deleteRequestUI,
  getRequest,
} from "common-utils/utils/api";
import {
  AddButton,
  IconButton as CIconButton,
  TableComponent,
  PageTitle,
  MainBox,
  TabPanel,
  SharedStyles,
} from "@shared/components/lib/index";
import DialogAppBar from "../components/DialogAppBar";
import EditIcon from "@mui/icons-material/Edit";
import Products from "./Product";
import CarrierSizes from "../components/CarrierSizes";
import CarrierPostCodes from "../components/CarrierPostCodes";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { formatTimeFromSeconds } from "common-utils/utils/time";
import CarrierForm from "../components/CarrierForm";
import CarrierGeoFences from "../components/CarrierGeoFences";
import GeofenceBlacklist from "../components/GeofenceBlacklist";
import CarrierCountries from "../components/CarrierCountries";
import CarrierHubsTab from "../components/CarrierHubsTab";
import ClaimSettingsTab from "../components/ClaimSettingsTab";
import PropertiesList from "../components/PropertiesList";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
  addProduct: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  deleteProgress: {
    marginLeft: 10,
  },
  activeProductStyle: {
    width: "32%",
    borderRadius: 5,
    padding: "10 13",
    border: "1px solid #0635C9",
    backgroundColor: "#0635C9",
    color: "#fff",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 7,
    },
  },
  productStyle: {
    width: "32%",
    borderRadius: 5,
    padding: "10 13",
    border: "1px solid #7F8FA4",
    color: "#000",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 7,
    },
  },
  activeTypeOfDeliveryStyle: {
    width: "32%",
    borderRadius: 5,
    padding: "10 15",
    border: "1px solid #0635C9",
    backgroundColor: "#0635C9",
    color: "#fff",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 7,
    },
  },
  typeOfDeliveryStyle: {
    width: "32%",
    borderRadius: 5,
    padding: "10 15",
    border: "1px solid #7F8FA4",
    color: "#000",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 7,
    },
  },
  geofences: {
    position: "relative",
    height: "724px",
  },
});

function Carriers(props) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [open, setOpen] = useState(false);

  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState({});
  const [showProgress, setShowProgress] = useState(false);
  const [isCreatingNew, setCreatingNew] = useState(false);
  const [deletingCarrier, setDeletingCarrier] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isAddProduct, setIsAddProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const [carriersProducts, setCarriersProducts] = useState([]);
  const [oldProduts, setOldProducts] = useState([]);
  const [productEdit, setProductEdit] = useState(false);
  const [product, setProduct] = useState({});
  const [types, setTypes] = useState([]);
  const [tagValue, setTagValue] = useState(0);
  const [selectedCarriers, setSelectedCarriers] = useState({ carriers: [] });
  const [initalCarriers, setInitialCarriers] = useState([]);

  const getInitionalCarriers = async () => {
    try {
      setShowProgress(true);
      const response = await getRequestUI("/carriers");
      setInitialCarriers(response);
      setShowProgress(false);
    } catch (e) {
      var code = e.code;
      var message = e.message;
      setShowProgress(false);
      console.log(e);
    }
  };
  const updateTable = async () => {
    try {
      setShowProgress(true);
      const carriersFilter = selectedCarriers.carriers.map((el) => el.id);
      const response = await getRequestUI("/carriers", {
        carriers: carriersFilter,
      });
      setCarriers(response);
      setShowProgress(false);
    } catch (e) {
      var code = e.code;
      var message = e.message;
      setShowProgress(false);
      console.log(e);
    }
  };
  const updateProductTable = async (id) => {
    try {
      setShowProgress(true);
      const response = await getRequestUI(`/product/${id}`);
      setProducts(response);
      setShowProgress(false);
    } catch (e) {
      var code = e.code;
      var message = e.message;
      setShowProgress(false);
      console.log(e);
    }
  };

  useEffect(() => {
    updateTable();
    getDeliveryTypes();
  }, [selectedCarriers.carriers]);

  useEffect(() => {
    getInitionalCarriers();
    getDeliveryTypes();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenProducts = () => {
    setIsAddProducts(true);
    setProductEdit(false);
    setProduct({});
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setSelectedProducts([]);
  };
  const setProperty = (properties, name, value) => {
    const property = properties.find((p) => p.name === name);
    if (property) {
      property.value = String(value);
      return;
    }
    properties.push({ name, value });
  };
  const formatProperties = (formData) => {
    const properties = selectedCarrier.properties || [];

    if (formData.packageType) {
      setProperty(properties, "packageType", formData.packageType);
    }
    if (formData.customerId) {
      setProperty(properties, "customerId", formData.customerId);
    }
    if (formData.depot) {
      setProperty(properties, "depot", formData.depot);
    }
    if (formData.username) {
      setProperty(properties, "username", formData.username);
    }
    if (formData.password) {
      setProperty(properties, "password", formData.password);
    }
    if (formData.collectionId) {
      setProperty(properties, "collectionId", formData.collectionId);
    }
    if (formData.scanAtThePickup !== undefined) {
      setProperty(properties, "scanAtThePickup", formData.scanAtThePickup);
    }
    if (formData.accountNumber) {
      setProperty(properties, "accountNumber", formData.accountNumber);
    }
    if (formData.companyName) {
      setProperty(properties, "companyName", formData.companyName);
    }
    if (formData.configurationId) {
      setProperty(properties, "configurationId", formData.configurationId);
    }
    if (formData.subscriptionKey) {
      setProperty(properties, "subscriptionKey", formData.subscriptionKey);
    }
    if (formData.webhookApiKey) {
      setProperty(properties, "webhookApiKey", formData.webhookApiKey);
    }
    if (formData.lineHaulApiKey) {
      setProperty(properties, "lineHaulApiKey", formData.lineHaulApiKey);
    }
    if (formData.lastMileApiKey) {
      setProperty(properties, "lastMileApiKey", formData.lastMileApiKey);
    }
    if (formData.lastMileCreatedOn) {
      setProperty(properties, "lastMileCreatedOn", formData.lastMileCreatedOn);
    }

    return properties;
  };
  const handleSave = async (formData) => {
    setErrorMessage("");
    setCreatingNew(true);
    let carrier = {
      name: formData.name,
      exportName: formData.exportName || "",
      carrierType: formData.carrierType,
      notificationEmail: formData.notificationEmail || "",
      reminderEmail: formData.reminderEmail,
      masterEmail: formData.masterEmail,
      locations:
        (formData.locations &&
          formData.locations.map(({ id, name }) => ({ id, name }))) ||
        [],
      deliversOnSat: formData.deliversOnSat,
      deliversOnSun: formData.deliversOnSun,
      apiKey: formData.apiKey,
      carrierApi: formData.carrierApi,
      client_secret: formData.client_secret,
      client_id: formData.client_id,
      routigoRouteId: formData.routeId,
      routigoSVCId: formData.svcId,
      vehicleType: formData.vehicleType,
      properties: formatProperties(formData),
    };

    try {
      const response = await postRequestUI("/carrier", carrier);
      setOpen(false);
      updateTable();
      setCreatingNew(false);
    } catch (e) {
      if (e.code === "invalid-argument") {
        setErrorMessage(e.message);
      }
      console.log(e);
      setCreatingNew(false);
    }
  };

  const handleEditCarrier = (formData) => {
    setErrorMessage("");
    setCreatingNew(true);
    const data = {
      id: formData.id,
      name: formData.name,
      exportName: formData.exportName || "",
      carrierType: formData.carrierType,
      notificationEmail: formData.notificationEmail || "",
      reminderEmail: formData.reminderEmail,
      masterEmail: formData.masterEmail,
      deliversOnSat: formData.deliversOnSat,
      deliversOnSun: formData.deliversOnSun,
      cutoff: formData.cutoff,
      carrierApi: formData.carrierApi,
      apiKey: formData.apiKey,
      client_id: formData.client_id,
      client_secret: formData.client_secret,
      routigoRouteId: formData.routeId,
      routigoSVCId: formData.svcId,
      vehicleType: formData.vehicleType,
      properties: formatProperties(formData),
    };
    putRequestUI("/carrier", data)
      .then((res) => {
        handleClose();
      })
      .then(() => {
        updateTable();
      })
      .catch((error) => {
        if (error.code === "invalid-argument") {
          setErrorMessage(error.message);
        }
      })
      .finally(() => {
        setCreatingNew(false);
      });
  };

  const handleDelete = useCallback(
    (id) => async () => {
      setDeletingCarrier(id);
      try {
        const response = await deleteRequestUI("/carrier", { id: id });
        updateTable();
        setDeletingCarrier();
      } catch (e) {
        console.log(e);
        setDeletingCarrier();
      }
    },
    []
  );

  const handleDeleteProduct = async (id) => {
    try {
      const response = await deleteRequestUI(`/product/${id}`);
      updateProductTable(selectedCarrier.id);
    } catch (e) {
      console.log(e);
    }
  };

  const editCarrier = (carrier) => {
    setSelectedCarrier(carrier);
    setEdit(true);
    setSelectedProducts(carrier.products);
    updateProductTable(carrier.id);
  };

  const editProduct = (product) => {
    setProduct(product);
    setProductEdit(true);
    setIsAddProducts(true);
  };

  const handleCloseProduct = () => {
    setIsAddProducts(false);
  };

  const getDeliveryTypes = async () => {
    try {
      const response = await getRequestUI("/types");
      setTypes(response);
    } catch (error) {}
  };

  const getTypeLabel = (type) => {
    return types.find((el) => el.id === type).title;
  };

  const productTableData = {
    headers: [
      "",
      "Name",
      "Description",
      "Type",
      "Pickup cutoff",
      "Delivery cutoff",
      "",
    ],
    body: [
      (product) => (
        <Button endIcon={<EditIcon />} onClick={() => editProduct(product)} />
      ),
      (product) => product.name,
      (product) => product.description,
      (product) => getTypeLabel(product.type),
      (product) =>
        `${formatTimeFromSeconds(
          product.pickup.startSeconds
        )} - ${formatTimeFromSeconds(product.pickup.endSeconds)}`,
      (product) =>
        `${formatTimeFromSeconds(
          product.delivery.startSeconds
        )} - ${formatTimeFromSeconds(product.delivery.endSeconds)}`,
      (product) =>
        deletingCarrier === product.id ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton
            icon="delete"
            onClick={() => handleDeleteProduct(product.id)}
          />
        ),
    ],
  };

  const handleChangeTagValue = (event, newValue) => {
    setTagValue(newValue);
  };

  const carrierTableData = {
    headers: [
      "",
      "Products",
      "Name",
      "Notification email",
      "Delivers on Sat",
      "Delivers on Sun",
      "",
    ],
    body: [
      (carrier) => (
        <Button
          data-testid="edit_carrier_btn"
          endIcon={<EditIcon />}
          onClick={() => editCarrier(carrier)}
        />
      ),
      (carrier) =>
        carrier.products.map((product) => {
          return <Grid>{product.name}</Grid>;
        }),
      (carrier) => carrier.name,
      (carrier) => carrier.notificationEmail,
      (carrier) => <Checkbox checked={carrier.deliversOnSat} readOnly />,
      (carrier) => <Checkbox checked={carrier.deliversOnSun} readOnly />,
      (carrier) =>
        deletingCarrier === carrier.id ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton
            data-testid="delete_carrier_btn"
            icon="delete"
            onClick={handleDelete(carrier.id)}
          />
        ),
    ],
  };

  return (
    <MainBox isFullWidth={true}>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <div style={{ padding: 15 }}>
        <PageTitle setTitle={"Carriers"} />
      </div>
      <Grid container xs={12}>
        <TableFilter
          tableFilter={[
            {
              data: initalCarriers,
              label: "Carriers",
              key: "carriers",
              id: "carriers",
            },
          ]}
          selectedFilterData={selectedCarriers}
          setSelectedFilterData={setSelectedCarriers}
        />
      </Grid>
      <TableComponent
        headers={carrierTableData.headers}
        rowData={carriers}
        cellComponents={carrierTableData.body}
      />
      {isAddProduct ? (
        <Products
          carrierId={selectedCarrier.id}
          handleClose={handleCloseProduct}
          updateProductTable={updateProductTable}
          isEdit={productEdit}
          product={product}
          types={types}
        />
      ) : (
        <>
          <AddButton onClick={handleClickOpen} />
          <Dialog
            fullScreen
            open={open || edit}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <DialogAppBar>
              <Toolbar>
                <IconButton
                  data-testid="close_product_btn"
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </DialogAppBar>
            <DialogContent>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={!!errorMessage}
                autoHideDuration={3000}
              >
                <Alert severity="error">{errorMessage}</Alert>
              </Snackbar>
              {edit && (
                <Paper>
                  <Tabs
                    value={tagValue}
                    onChange={handleChangeTagValue}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    <Tab label="Info" />
                    <Tab data-testid="product_tab" label="Products" />
                    <Tab data-testid="size_tab" label="Sizes" />
                    <Tab data-testid="geofences_tab" label="Geofences" />
                    <Tab data-testid="blacklist_tab" label="Blacklist" />
                    <Tab data-testid="postcodes_tab" label="Postcodes" />
                    <Tab data-testid="countries_tab" label="Countries" />
                    <Tab data-testid="hubs_tab" label="Hubs" />
                    <Tab
                      data-testid="claim_settings_tab"
                      label="Claim Settings"
                    />
                    <Tab data-testid="properies_tab" label="Properties" />
                  </Tabs>
                  <TabPanel value={tagValue} index={0}>
                    <CarrierForm
                      carrier={selectedCarrier}
                      edit={edit}
                      isCreatingNew={isCreatingNew}
                      tagValue={tagValue}
                      handleEditCarrier={handleEditCarrier}
                      handleSave={handleSave}
                    />
                  </TabPanel>
                  <TabPanel value={tagValue} index={1}>
                    <TableContainer component={Paper} sx={sharedStyles.paper}>
                      <Button
                        data-testid="add_product_btn"
                        sx={styles.addProduct}
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => handleClickOpenProducts()}
                      >
                        Add Product
                      </Button>
                      <div style={{ padding: 15 }}>
                        <PageTitle setTitle={"Products"} />
                      </div>

                      <TableComponent
                        headers={productTableData.headers}
                        rowData={products}
                        cellComponents={productTableData.body}
                      />
                    </TableContainer>
                  </TabPanel>
                  <TabPanel value={tagValue} index={2}>
                    <CarrierSizes carrierId={selectedCarrier.id} />
                  </TabPanel>
                  <TabPanel value={tagValue} index={3}>
                    <Grid sx={styles.geofences}>
                      <CarrierGeoFences carrierId={selectedCarrier.id} />
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tagValue} index={4}>
                    <GeofenceBlacklist carrierId={selectedCarrier.id} />
                  </TabPanel>
                  <TabPanel value={tagValue} index={5}>
                    <CarrierPostCodes carrierId={selectedCarrier.id} />
                  </TabPanel>
                  <TabPanel value={tagValue} index={6}>
                    <CarrierCountries carrierId={selectedCarrier.id} />
                  </TabPanel>
                  <TabPanel value={tagValue} index={7}>
                    <CarrierHubsTab carrierId={selectedCarrier.id} />
                  </TabPanel>
                  <TabPanel value={tagValue} index={8}>
                    <ClaimSettingsTab carrierId={selectedCarrier.id} />
                  </TabPanel>
                  <TabPanel value={tagValue} index={9}>
                    <PropertiesList
                      ownerId={selectedCarrier.id}
                      api={{
                        get: (ownerId) => `/carrier/properties/${ownerId}`,
                        save: () => `/carrier/property`,
                        delete: (id) => `/carrier/property/${id}`,
                      }}
                    />
                  </TabPanel>
                </Paper>
              )}
              {open && (
                <CarrierForm
                  carrier={selectedCarrier}
                  edit={edit}
                  isCreatingNew={isCreatingNew}
                  tagValue={tagValue}
                  handleEditCarrier={handleEditCarrier}
                  handleSave={handleSave}
                />
              )}
            </DialogContent>
          </Dialog>
        </>
      )}
    </MainBox>
  );
}

export default Carriers;
