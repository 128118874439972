import React, { useState, useEffect } from "react";
import { GeoFences } from "@shared/components/lib/index";
import {
  postRequestUI,
  putRequestUI,
  getRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";

const CarrierGeoFences = ({ carrierId }) => {
  const [cities, setCities] = useState([]);
  const [geoFences, setGeoFences] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    getCities();
    loadCarrierGeofences();
  }, []);

  const getCities = async () => {
    try {
      const res = await getRequestUI("/cities");
      setCities(res);
    } catch (e) {
      const code = e.code;
      const message = e.message;
    }
  };

  const loadCarrierGeofences = async () => {
    try {
      const response = await getRequestUI(`geofences/${carrierId}`);
      setGeoFences(response);
    } catch (e) {
      const code = e.code;
      const message = e.message;
    }
  };
  const createGeoFence = async (data) => {
    setIsSave(true);
    try {
      await postRequestUI(`/geofences`, { ...data, carrierId });
      await loadCarrierGeofences();
      setIsSave(false);
    } catch (e) {
      const code = e.code;
      const message = e.message;

      setIsSave(false);
    }
  };
  const updateGeoFence = async (data) => {
    setIsSave(true);
    try {
      await putRequestUI(`/geofences`, { ...data, carrierId });
      setIsSave(false);
    } catch (e) {
      const code = e.code;
      const message = e.message;

      setIsSave(false);
    }
  };
  const deleteGeoFence = async (id) => {
    setIsDelete(true);
    try {
      await deleteRequestUI(`/geofences/${id}`);
      loadCarrierGeofences();
      setIsDelete(false);
    } catch (e) {
      const code = e.code;
      const message = e.message;

      setIsDelete(false);
    }
  };

  return (
    <GeoFences
      cities={cities}
      geoFences={geoFences}
      createGeoFence={createGeoFence}
      updateGeoFence={updateGeoFence}
      deleteGeoFence={deleteGeoFence}
      isSave={isSave}
      isDelete={isDelete}
    />
  );
};

export default CarrierGeoFences;
