import React, { useState, useEffect, useMemo, useCallback } from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import green from "@mui/material/colors/green";
import TableFilter from "../components/TableFilter";
import Slider from "@mui/material/Slider";

import DialogAppBar from "../components/DialogAppBar";
import EditIcon from "@mui/icons-material/Edit";
import {
  AddButton,
  IconButton as CIconButton,
  ButtonProgress,
  TableComponent,
  PageTitle,
  MainBox,
  TabPanel,
  CheckboxList,
  SharedStyles,
} from "@shared/components/lib/index";
import CircularProgress from "@mui/material/CircularProgress";
import {
  putRequestUI,
  getRequestUI,
  postRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";
import useTheme from "@mui/material/styles/useTheme";
import LocationForm from "../components/LocationForm";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Autocomplete from "@mui/material/Autocomplete";
import { WEEK_DAYS } from "../constants";
import PropertiesList from "../components/PropertiesList";
import { useQuery, useMutation } from "react-query";

const useStyles = (theme) => ({
  table: {
    minWidth: 650,
  },
  alert: {
    padding: "10px !important",
  },
  paper: {
    position: "relative",
    padding: theme.spacing(3),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    display: "flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  addProduct: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  addressInfo: {
    padding: "10 0 20 0",
  },
  "MuiAlert-root": {
    padding: "10px !important",
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Locations(props) {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [newLocationloading, setNewLocationLoading] = useState(false);
  const [newProductloading, setNewProductLoading] = useState(false);
  const [newHubLoading, setNewHubLoading] = useState(false);
  const [newLocationSuccess, setNewLocationSuccess] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [deletingLocation, setDeletingLocation] = useState(null);
  const [deletingProduct, setDeletingProduct] = useState(null);
  const [edit, setEdit] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [editLocation, setEditLocation] = useState({});
  const [tagValue, setTagValue] = useState(0);
  const [isAddProduct, setIsAddProducts] = useState(false);
  const [productEdit, setProductEdit] = useState(false);
  const [product, setProduct] = useState({});
  const [locationRetailer, setLocationRetailer] = useState(null);
  const [checkedWeekdays, setCheckedWeekDay] = useState(WEEK_DAYS);
  const [isAddHub, setIsAddHub] = useState(false);
  const [selectedHub, setSelectedHub] = useState({});
  const [selectedLocations, setSelectedLocations] = useState({ locations: [] });
  const [daysToDeliver, setDaysToDeliver] = useState(0);
  const [isPreferred, setIsPreferred] = useState(false);

  const prerequisites = useQuery(
    ["admin-location-prerequisites"],
    async () => {
      const result = await getRequestUI("/admin/location-prerequisites");
      return result;
    },
    {
      onError: (err) => {
        setErrorMessage(err.message);
      },
    }
  );

  const locationResult = useQuery(
    ["locations", selectedLocations],
    async () => {
      const locationsIds = selectedLocations.locations.map((el) => el.id);
      const result = await getRequestUI(`/locations`, {
        locations: locationsIds,
      });
      return result;
    },
    {
      onError: (err) => {
        setErrorMessage(err.message);
      },
    }
  );

  const { data: locations = [], refetch: refetchLocations } = locationResult;
  const {
    countries = [],
    retailers = [],
    deliveryTypes = [],
    filteredLocations = [],
  } = prerequisites.data ? prerequisites.data : {};

  const { data: selectedProducts = [], refetch: refetchSelectedProducts } =
    useQuery(
      ["location products", editLocation],
      async () => {
        const result = await getRequestUI(
          `/location_products/${editLocation.id}`
        );
        return result;
      },
      { enabled: !_.isEmpty(editLocation) }
    );

  const availableProductsQuery = useQuery(
    ["available_products"],
    async () => {
      const result = getRequestUI(
        `/location/available_products/${editLocation.id}`
      );
      return result;
    },
    { enabled: !_.isEmpty(editLocation) }
  );

  const { data: availableHubs = [], refetch: refetchAvailableHubs } = useQuery(
    ["available-hubs", editLocation],
    async () => {
      const result = await getRequestUI(`/locations/available/hubs`, {
        retailerId: editLocation.retailer.id,
        warehouseId: editLocation.id,
      });
      return result;
    },
    {
      onError: (err) => {
        setErrorMessage(err.message);
      },
      enabled: !_.isEmpty(editLocation),
    }
  );

  const { data: locationHubs = [], refetch: refetchLocationHub } = useQuery(
    ["location-hubs", editLocation],
    async () => {
      const result = await getRequestUI(`/locations/hubs/${editLocation.id}`);
      return result;
    },
    {
      onError: (err) => {
        setErrorMessage(err.message);
      },
      enabled: !_.isEmpty(editLocation),
    }
  );

  const createMutation = useMutation(saveLocation, {
    onSuccess: () => {
      setOpen(false);
      refetchLocations();
      setNewLocationLoading(false);
    },
  });

  const addProductMutation = useMutation(addProduct, {
    onSuccess: () => {
      refetchLocations();
      refetchSelectedProducts();
      setIsAddProducts(false);
      setNewProductLoading(false);
    },
  });

  const deleteMutation = useMutation(deleteLocation, {
    onSuccess: () => {
      refetchLocations();
      handleClose();
      setDeletingLocation();
    },
  });

  const updateMutation = useMutation(updateLocation, {
    onSuccess: () => {
      handleClose(false);
      setNewLocationSuccess(true);
      setNewLocationLoading(false);
      refetchLocations();
    },
    onError: () => {
      setNewLocationSuccess(false);
      setNewLocationLoading(false);
    },
  });

  const deleteProductMutation = useMutation(deleteProduct, {
    onSuccess: () => {
      refetchLocations();
      refetchSelectedProducts();
    },
  });

  const addHubToWarehouseMutation = useMutation(addHubToWarehouse, {
    onSuccess: () => {
      refetchLocationHub();
      refetchAvailableHubs();
      setIsAddHub(false);
      setNewHubLoading(false);
    },
    onError: (error) => {
      setErrorMessage(error);
      setNewHubLoading(false);
    },
  });

  const deleteHubFromWarehouseMutation = useMutation(deleteHubFromWarehouse, {
    onSuccess: () => {
      refetchLocationHub();
      refetchAvailableHubs();
      setNewHubLoading();
    },
    onError: (error) => {
      setNewHubLoading();
      setErrorMessage(error);
    },
  });

  async function addHubToWarehouse() {
    setNewHubLoading(true);
    await postRequestUI("/warehouse/hub", {
      hubId: selectedHub.id,
      warehouseId: editLocation.id,
    });
  }

  async function deleteLocation(id) {
    setDeletingLocation(id);
    await deleteRequestUI(`/location`, { id: id });
  }

  async function addProduct(data) {
    await postRequestUI("/location/product", data);
    setCheckedWeekDay(WEEK_DAYS);
  }

  async function saveLocation(data) {
    setNewLocationLoading(true);
    await postRequestUI("/location", data);
  }

  async function updateLocation(data) {
    if (validate(data)) {
      return;
    }
    await putRequestUI("/location", data);
  }

  async function deleteProduct(id) {
    await deleteRequestUI("/location/product", {
      productId: id,
      locationId: editLocation.id,
    });
    setDeletingProduct(null);
  }

  async function deleteHubFromWarehouse(id) {
    setNewHubLoading(true);
    await deleteRequestUI(`/warehouse/hub/${id}`);
  }

  const validate = (data) => {
    if (!data) {
      throw new Error("data is not defined ");
    }

    if (!data.name) {
      throw new Error(`name is not defined ${JSON.stringify(data)}`);
    }
    if (data.name.trim() === "") {
      // show postode message here
      setErrorMessage("Name is empty");
      return true;
    }

    if (data.address.postcode === "") {
      // show postode message here
      setErrorMessage("Postocde is invalid");
      return true;
    }

    if (data.address.houseNumber === "") {
      setErrorMessage("HouseNumber is invalid");
      return true;
    }

    if (data.selectedRetailer === "") {
      setErrorMessage("Please select retailer");
      return true;
    }

    if (data.personName === "") {
      setErrorMessage("Please select person name");
      return true;
    }

    if (data.personNumber === "") {
      setErrorMessage("Please select person phone number");
      return true;
    }
    return false;
  };

  const handleChangeTagValue = (event, newValue) => {
    setTagValue(newValue);
  };

  const handleClickOpenProducts = () => {
    setIsAddProducts(true);
    setProductEdit(false);
    setProduct({});
    setDaysToDeliver(0);
    setIsPreferred(false);
  };

  const handleClickOpenHubs = () => {
    setIsAddHub(true);
    setProductEdit(false);
    setSelectedHub({});
  };

  const handleAddProductToLocation = () => {
    setNewProductLoading(true);
    const payload = {
      locationId: editLocation.id,
      productId: product.id,
      weekdays: checkedWeekdays.map((day) => day.id),
      daysToDeliver: daysToDeliver,
      isPreferred: isPreferred,
    };
    addProductMutation.mutate(payload);
  };

  const handleClickOpen = () => {
    setNewLocationLoading(false);
    setIsOpenDialog(true);
    setOpen(true);
    setEdit(false);
  };

  const handleEditLocation = (data) => {
    setNewLocationSuccess(false);
    setNewLocationLoading(true);
    updateMutation.mutate(data);
  };

  const handleSave = (data) => {
    setNewLocationSuccess(false);
    setNewLocationLoading(true);
    if (validate(data)) {
      return;
    }
    createMutation.mutate(data);
  };

  const handleSaveHubToWarehouse = () => {
    addHubToWarehouseMutation.mutate();
  };

  const handleDeleteHubFromWarehouse = (id) => {
    deleteHubFromWarehouseMutation.mutate(id);
  };

  const clickOnEditLocation = (location) => {
    setEditLocation(location);
    setLocationRetailer(location.retailer);
    setIsOpenDialog(true);
    setEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setTagValue(0);
  };

  const getTypeLabel = (type) => {
    return deliveryTypes.find((el) => el.id === type).title;
  };
  const getWeekDays = (weekdays, dayId) => (
    <Checkbox
      color="primary"
      checked={weekdays.some((day) => day === dayId)}
      inputProps={{ "aria-label": "primary checkbox" }}
    />
  );

  useEffect(() => {
    if (isAddProduct) {
      availableProductsQuery.refetch();
    }
  }, [isAddProduct]);

  const { data: availableProducts = [] } = availableProductsQuery;

  const handleCloseProductLocation = () => {
    setIsAddProducts(false);
    setCheckedWeekDay(WEEK_DAYS);
  };

  const handleDeleteProduct = (id) => {
    setDeletingProduct(id);
    deleteProductMutation.mutate(id);
  };

  const locationTableData = {
    headers: [
      "",
      "Name",
      "Retailer",
      "Products",
      "Postcode",
      "Street name",
      "House Number",
      "House Addition",
      "Person name",
      "Person number",
      "Type",
      "",
    ],
    body: [
      (location) => (
        <Button
          data-testid="edit_location_btn"
          endIcon={<EditIcon />}
          onClick={async () => await clickOnEditLocation(location)}
        />
      ),
      (location) => location.name,
      (location) => location.retailer.name,
      (location) =>
        location.products.map((product) => {
          return <Grid>{product.name}</Grid>;
        }),
      (location) => location.address.postcode,
      (location) => location.street,
      (location) => location.address.houseNumber,
      (location) => location.address.houseAdditions,
      (location) => location.personName,
      (location) => location.personNumber,
      (location) => location.type,
      (location) =>
        deletingLocation === location.id ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton
            data-testid="delete_location_btn"
            icon="delete"
            onClick={() => handleDelete(location.id)}
          />
        ),
    ],
  };

  const hubData = {
    headers: ["Name", ""],
    body: [
      (hubLocation) => hubLocation.name,
      (hubLocation) =>
        deletingProduct === hubLocation.id ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton
            icon="delete"
            onClick={() => handleDeleteHubFromWarehouse(hubLocation.id)}
          />
        ),
    ],
  };

  const productTableData = {
    headers: [
      "Name",
      "Type",
      "Days to Deliver",
      "Preffered product",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
      "",
    ],
    body: [
      (locationProduct) => formatProductLabel(locationProduct),
      (locationProduct) => getTypeLabel(locationProduct.type) || "",
      (locationProduct) => locationProduct.daysToDeliver,
      (locationProduct) => (
        <Checkbox
          color="primary"
          checked={locationProduct.isPreferred}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      ),
      (locationProduct) =>
        getWeekDays(locationProduct.weekdays, WEEK_DAYS[0].id),
      (locationProduct) =>
        getWeekDays(locationProduct.weekdays, WEEK_DAYS[1].id),
      (locationProduct) =>
        getWeekDays(locationProduct.weekdays, WEEK_DAYS[2].id),
      (locationProduct) =>
        getWeekDays(locationProduct.weekdays, WEEK_DAYS[3].id),
      (locationProduct) =>
        getWeekDays(locationProduct.weekdays, WEEK_DAYS[4].id),
      (locationProduct) =>
        getWeekDays(locationProduct.weekdays, WEEK_DAYS[5].id),
      (locationProduct) =>
        getWeekDays(locationProduct.weekdays, WEEK_DAYS[6].id),
      (locationProduct) =>
        deletingProduct === locationProduct.id ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton
            icon="delete"
            onClick={() => handleDeleteProduct(locationProduct.id)}
          />
        ),
    ],
  };

  const formatProductLabel = (option) => {
    if (_.isEmpty(option)) {
      return "";
    }
    return `${option.carrierName} ${option.name}`;
  };

  const handleProduct = useCallback((e, value) => {
    setProduct(value);
  }, []);

  const handleHub = useCallback((e, value) => {
    setSelectedHub(value);
  }, []);

  const handelSetCheckedWeekDay = (event, id) => {
    const found = checkedWeekdays.find((d) => d.id === id);
    if (found) {
      const list = checkedWeekdays.filter((d) => d.id !== found.id);
      setCheckedWeekDay(list);
    } else {
      const checkedList = [
        ...checkedWeekdays,
        WEEK_DAYS.find((day) => day.id === id),
      ];
      setCheckedWeekDay(checkedList);
    }
  };

  const getErrorMessage = () => {
    if (createMutation.isError) {
      return createMutation.error.message;
    }
    if (deleteMutation.isError) {
      return deleteMutation.error.message;
    }
    if (updateMutation.isError) {
      return updateMutation.error.message;
    }
    if (addProductMutation.isError) {
      return addProductMutation.error.message;
    }
    if (deleteProductMutation.isError) {
      return deleteProductMutation.error.message;
    }
    if (errorMessage) {
      return errorMessage;
    }
    return "";
  };

  const handleDelete = (id) => {
    setDeletingLocation(id);
    deleteMutation.mutate(id);
  };

  return (
    <MainBox isFullWidth={true}>
      <AddButton onClick={handleClickOpen} />
      <Dialog
        fullScreen
        open={open || edit}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {isAddHub && (
          <>
            <DialogAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setIsAddHub(false)}
                  aria-label="close"
                >
                  <CloseIcon data-testid="close_hub_btn" />
                </IconButton>
                <Button
                  data-testid="save_warehouse_hub_btn"
                  autoFocus
                  disabled={newHubLoading}
                  color="inherit"
                  onClick={handleSaveHubToWarehouse}
                >
                  Save
                  {newHubLoading && <ButtonProgress size={24} />}
                </Button>
              </Toolbar>
            </DialogAppBar>
            <form style={styles.container}>
              <FormControl sx={sharedStyles.formControl}>
                <Typography variant="h3" component="h4">
                  Add hub to warehouse
                </Typography>
                <Autocomplete
                  data-testid="hubs_autocomplete"
                  id="hubs-autocomplete"
                  autoComplete
                  autoHighlight
                  options={availableHubs || []}
                  value={selectedHub || []}
                  getOptionLabel={(option) => option.name || ""}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="standard"
                      label="Select Hubs"
                      placeholder="Select hubs"
                    />
                  )}
                  onChange={handleHub}
                />
              </FormControl>
            </form>
          </>
        )}
        {isAddProduct && (
          <>
            <DialogAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseProductLocation}
                  aria-label="close"
                >
                  <CloseIcon data-testid="close_product_btn" />
                </IconButton>
                <Button
                  data-testid="save_location_product_btn"
                  autoFocus
                  disabled={newProductloading}
                  color="inherit"
                  onClick={handleAddProductToLocation}
                >
                  Save
                  {newProductloading && <ButtonProgress size={24} />}
                </Button>
              </Toolbar>
            </DialogAppBar>
            <form style={styles.container}>
              <FormControl sx={sharedStyles.formControl}>
                <Typography variant="h3" component="h4">
                  Add product to location
                </Typography>
                <Autocomplete
                  data-testid="products_autocomplete"
                  id="products-autocomplete"
                  autoComplete
                  autoHighlight
                  options={availableProducts || []}
                  value={product || []}
                  getOptionLabel={(option) => formatProductLabel(option)}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="standard"
                      label="Select Products"
                      placeholder="Select products"
                    />
                  )}
                  onChange={handleProduct}
                />
              </FormControl>

              {!_.isEmpty(product) && (
                <FormControl sx={sharedStyles.formControl}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography id="discrete-slider" gutterBottom>
                        Days to delivery
                      </Typography>
                      <Slider
                        data-testid="delivery_days_slider"
                        defaultValue={daysToDeliver}
                        aria-labelledby="range-slider"
                        valueLabelDisplay="auto"
                        onChange={(e, value) => setDaysToDeliver(value)}
                        step={1}
                        marks
                        min={0}
                        max={7}
                        sx={{ maxWidth: "100%" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="checkbox_block_label_text">
                        Set as preferred product
                      </Typography>
                      <Checkbox
                        color="primary"
                        checked={isPreferred}
                        onChange={(e) => setIsPreferred(e.target.checked)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="checkbox_block_label_text">
                        Select week day(s)
                      </Typography>
                      <CheckboxList
                        list={WEEK_DAYS}
                        checkedList={checkedWeekdays}
                        onChange={handelSetCheckedWeekDay}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              )}
            </form>
          </>
        )}
        {!isAddHub && !isAddProduct && (
          <>
            <DialogAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon data-testid="close_btn" />
                </IconButton>
              </Toolbar>
            </DialogAppBar>
            <DialogContent>
              <Grid item sm={12}>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={!!getErrorMessage()}
                  autoHideDuration={3000}
                >
                  <Alert sx={styles.alert} severity="error">
                    {getErrorMessage()}
                  </Alert>
                </Snackbar>
                {edit && (
                  <Paper>
                    <Tabs
                      value={tagValue}
                      onChange={handleChangeTagValue}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      <Tab label="Info" />
                      <Tab data-testid="product_tab" label="Products" />
                      <Tab
                        label="Properties"
                        data-testid="location_properties"
                      />
                      {editLocation && editLocation.type === "warehouse" && (
                        <Tab label="Hub" data-testid="location_hubs" />
                      )}
                    </Tabs>
                    <TabPanel value={tagValue} index={0}>
                      <LocationForm
                        location={editLocation}
                        retailers={retailers}
                        edit={edit}
                        isCreatingNew={newLocationloading}
                        tagValue={tagValue}
                        handleEditLocation={handleEditLocation}
                        handleSave={handleSave}
                        countries={countries}
                        setLocationRetailer={setLocationRetailer}
                        setErrorMessage={setErrorMessage}
                      />
                    </TabPanel>
                    <TabPanel value={tagValue} index={1}>
                      <TableContainer component={Paper} sx={styles.paper}>
                        <Button
                          data-testid="add_product_btn"
                          sx={styles.addProduct}
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={() => handleClickOpenProducts()}
                        >
                          Add Product
                        </Button>
                        <div style={{ padding: 15 }}>
                          <PageTitle setTitle={"Products"} />
                        </div>

                        <TableComponent
                          headers={productTableData.headers}
                          rowData={selectedProducts}
                          cellComponents={productTableData.body}
                        />
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={tagValue} index={2}>
                      <PropertiesList
                        ownerId={editLocation.id}
                        api={{
                          get: (ownerId) => `/location/properties/${ownerId}`,
                          save: () => `/location/property`,
                          delete: (id) => `/location/property/${id}`,
                        }}
                      />
                    </TabPanel>
                    <TabPanel value={tagValue} index={3}>
                      <TableContainer component={Paper} sx={styles.paper}>
                        <Button
                          data-testid="add_hub_btn"
                          sx={styles.addProduct}
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={() => handleClickOpenHubs()}
                        >
                          Add Hub
                        </Button>
                        <div style={{ padding: 15 }}>
                          <PageTitle setTitle={"Hubs"} />
                        </div>

                        <TableComponent
                          headers={hubData.headers}
                          rowData={locationHubs}
                          cellComponents={hubData.body}
                        />
                      </TableContainer>
                    </TabPanel>
                  </Paper>
                )}
                {open && (
                  <LocationForm
                    location={editLocation}
                    retailers={retailers}
                    edit={edit}
                    isCreatingNew={newLocationloading}
                    tagValue={tagValue}
                    handleEditLocation={handleEditLocation}
                    handleSave={handleSave}
                    countries={countries}
                    setLocationRetailer={setLocationRetailer}
                    setErrorMessage={setErrorMessage}
                  />
                )}
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <TableContainer component={Paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Locations"} />
        </div>
        <Grid container xs={12}>
          <TableFilter
            tableFilter={[
              {
                data: filteredLocations,
                label: "Locations",
                key: "locations",
                id: "locations",
              },
            ]}
            selectedFilterData={selectedLocations}
            setSelectedFilterData={setSelectedLocations}
          />
        </Grid>
        <TableComponent
          headers={locationTableData.headers}
          rowData={locations}
          cellComponents={locationTableData.body}
        />
      </TableContainer>
    </MainBox>
  );
}

export default Locations;
