import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogAppBar from "../components/DialogAppBar";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import useTheme from "@mui/material/styles/useTheme";
import { ButtonProgress } from "@shared/components/lib/index";

import TextField from "@mui/material/TextField";
import {
  Grid,
  LinearProgress,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import {
  IconButton as CIconButton,
  IconBackpack,
  IconCargobike,
  IconLetter,
  IconVan,
  TableComponent,
  PageTitle,
  SharedStyles,
} from "@shared/components/lib/index";
import {
  putRequestUI,
  postRequestUI,
  getRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";

const useStyles = (theme) => ({
  deleteProgress: {
    marginLeft: 10,
  },
  mainBox: {
    padding: 0,
  },
  iconWrap: {
    marginBottom: "-4px",
    marginRight: "4px",
  },
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 540,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CarrierSizes = ({ carrierId }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [filteredSizes, setFilteredSizes] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [deletingProgress, setDeletingProgress] = useState(false);
  const [selectedSize, setSizeSelected] = useState({});
  const [isCreatingNew, setCreatingNew] = useState(false);

  useEffect(() => {
    getSizes(carrierId);
  }, [carrierId]);

  const getSizes = async (carrierId) => {
    try {
      setShowProgress(true);
      const response = await getRequestUI(`/sizes/carrier/${carrierId}`);
      setSizes(response);
      setShowProgress(false);
    } catch (e) {
      var code = e.code;
      var message = e.message;
      setErrorMessage(message);
      setShowProgress(false);

      console.log(e);
    }
  };
  const handleSave = async () => {
    setCreatingNew(true);
    setErrorMessage("");
    try {
      const size = {
        carrierId,
        sizeId: selectedSize.id,
      };
      await postRequestUI("/sizes/carrier", size);
      await getSizes(carrierId);
      handleCloseDialog();
    } catch (e) {
      if (e.code === "invalid-argument") {
        setErrorMessage(e.message);
      } else {
        handleCloseDialog();
        console.log(e);
      }
    } finally {
      setCreatingNew(false);
    }
  };

  const deleteSize = async (id) => {
    setDeletingProgress(true);
    try {
      await deleteRequestUI(`/sizes/carrier/${id}`);
      await getSizes(carrierId);
      setShowProgress(false);
    } catch (e) {
      console.log(e);
      setErrorMessage(e.message);
      setShowProgress(false);
    } finally {
      setDeletingProgress(false);
    }
  };

  const sizeToIcon = (icon, color) => {
    switch (icon) {
      case "SMALL":
        return <IconLetter style={styles.iconWrap} color={color} />;
      case "STANDARD":
        return <IconBackpack style={styles.iconWrap} color={color} />;
      case "XL":
        return <IconCargobike style={styles.iconWrap} color={color} />;
      case "XXL":
        return <IconVan style={styles.iconWrap} color={color} />;
      default:
        return <IconBackpack style={styles.iconWrap} color={color} />;
    }
  };
  const sizesTableData = {
    headers: ["Name", "Description", "Length", "Width", "Height", "Type", ""],
    body: [
      (size) => size.name,
      (size) => size.description,
      (size) => size.length,
      (size) => size.width,
      (size) => size.height,
      (size) => (
        <>
          <Box component="span" sx={styles.iconWrap}>
            {sizeToIcon(size.icon)}
          </Box>
          {size.icon}
        </>
      ),
      (size) =>
        deletingProgress ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton icon="delete" onClick={() => deleteSize(size.id)} />
        ),
    ],
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenSize = async () => {
    try {
      setShowProgress(true);
      const sizesAll = await getRequestUI(`/sizes`);
      const filtered = sizesAll.filter(
        (e) => !sizes.find((c) => c.sizeId === e.id)
      );
      setFilteredSizes(filtered);
      setShowProgress(false);
      setSizeSelected({});
      setOpenDialog(true);
    } catch (e) {
      var code = e.code;
      var message = e.message;
      setErrorMessage(message);
      setShowProgress(false);

      console.log(e);
    }
  };
  const setFormData = (e) => {
    setSizeSelected(e.target.value);
  };
  return (
    <Grid container>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <Button
          data-testid="add_size_btn"
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => handleClickOpenSize()}
        >
          Add Size
        </Button>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Sizes"} />
        </div>

        <TableComponent
          headers={sizesTableData.headers}
          rowData={sizes}
          cellComponents={sizesTableData.body}
        />
      </TableContainer>
      <Dialog
        fullScreen
        open={isOpenDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              data-testid="close_size_btn"
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button
              data-testid="save_size_btn"
              autoFocus
              color="inherit"
              onClick={handleSave}
            >
              Save
              {isCreatingNew && <ButtonProgress size={24} />}
            </Button>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!errorMessage}
            autoHideDuration={3000}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <form style={styles.container}>
            <FormControl sx={sharedStyles.formControl}>
              <Typography variant="h3" component="h4">
                Adding size
              </Typography>
              <Grid container spacing={2} sx={styles.formContainer}>
                <Grid item xs={12}>
                  {!!filteredSizes.length ? (
                    <TextField
                      data-testid="select_size"
                      id="selectSize"
                      variant="standard"
                      select
                      label="Select size"
                      margin="normal"
                      fullWidth
                      value={selectedSize}
                      onChange={setFormData}
                    >
                      {filteredSizes.map((size, i) => (
                        <MenuItem value={size} key={i}>
                          {size.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Typography>All sizes selected</Typography>
                  )}
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default CarrierSizes;
