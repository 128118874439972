import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogAppBar from "../components/DialogAppBar";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import { ButtonProgress } from "@shared/components/lib/index";
import useTheme from "@mui/material/styles/useTheme";
import TextField from "@mui/material/TextField";
import {
  Grid,
  LinearProgress,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import {
  AddButton,
  IconButton as CIconButton,
  IconBackpack,
  IconCargobike,
  IconLetter,
  IconVan,
  TableComponent,
  PageTitle,
  SharedStyles,
  MainBox,
} from "@shared/components/lib/index";
import {
  putRequestUI,
  postRequestUI,
  getRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";

const ICONS = ["SMALL", "STANDARD", "XL", "XXL"];

const useStyles = (theme) => ({
  deleteProgress: {
    marginLeft: 10,
  },
  mainBox: {
    padding: 0,
  },
  iconWrap: {
    "& svg": {
      marginBottom: "-4px",
      marginRight: "4px",
    },
  },
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 540,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Sizes = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [formData, setFormData] = useState({});
  const [showProgress, setShowProgress] = useState(false);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [deletingProgress, setDeletingProgress] = useState(false);
  const [isCreatingNew, setCreatingNew] = useState(false);

  useEffect(() => {
    getSizes();
  }, []);

  const getSizes = async () => {
    try {
      setShowProgress(true);
      const response = await getRequestUI(`/sizes`);
      setSizes(response);
      setShowProgress(false);
    } catch (e) {
      var code = e.code;
      var message = e.message;
      setErrorMessage(message);
      setShowProgress(false);
      console.log(e);
    }
  };

  const editSize = (size) => {
    setIsEdit(true);
    setFormData(size);
    setOpenDialog(true);
  };

  const deleteSize = async (id) => {
    setDeletingProgress(true);
    try {
      await deleteRequestUI(`/sizes/${id}`);
      await getSizes();
      setShowProgress(false);
    } catch (e) {
      console.log(e);
      setErrorMessage(e.message);
      setShowProgress(false);
    } finally {
      setDeletingProgress(false);
    }
  };
  const sizeToIcon = (icon, color) => {
    switch (icon) {
      case "SMALL":
        return <IconLetter style={styles.iconWrap} color={color} />;
      case "STANDARD":
        return <IconBackpack style={styles.iconWrap} color={color} />;
      case "XL":
        return <IconCargobike style={styles.iconWrap} color={color} />;
      case "XXL":
        return <IconVan style={styles.iconWrap} color={color} />;
      default:
        return <IconBackpack style={styles.iconWrap} color={color} />;
    }
  };

  const sizesTableData = {
    headers: [
      "",
      "Name",
      "Description",
      "Length",
      "Width",
      "Height",
      "Type",
      "",
    ],
    body: [
      (size) => (
        <Button endIcon={<EditIcon />} onClick={() => editSize(size)} />
      ),
      (size) => size.name,
      (size) => size.description,
      (size) => size.length,
      (size) => size.width,
      (size) => size.height,
      (size) => (
        <>
          <Box component="span" sx={styles.iconWrap}>
            {sizeToIcon(size.icon)}
          </Box>
          {size.icon}
        </>
      ),
      (size) =>
        deletingProgress ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton icon="delete" onClick={() => deleteSize(size.id)} />
        ),
    ],
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEdit = async () => {
    if (validate()) {
      return;
    }
    setCreatingNew(true);
    setErrorMessage("");
    const updatedSize = {
      name: formData.name,
      description: formData.description || "",
      length: formData.length,
      width: formData.width,
      height: formData.height,
      icon: formData.icon,
    };
    try {
      await putRequestUI(`/sizes/${formData.id}`, updatedSize);
      getSizes();
      handleCloseDialog();
    } catch (e) {
      if (e.code === "invalid-argument") {
        setErrorMessage(e.message);
      } else {
        handleCloseDialog();
        console.log(e);
      }
    } finally {
      setCreatingNew(false);
      setIsEdit(false);
      setFormData({});
    }
  };

  const validate = () => {
    if (!formData.name) {
      setErrorMessage("Name is required");
      return true;
    }
    return false;
  };
  const handleFormData = useCallback(
    (key) => (e) => {
      setFormData({ ...formData, [key]: e.target.value });
    },
    [formData]
  );
  const handleClickOpenSize = () => {
    setIsEdit(false);
    setFormData({});
    setOpenDialog(true);
  };

  const handleSave = async () => {
    if (validate()) {
      return;
    }
    setCreatingNew(true);
    setErrorMessage("");
    const size = {
      name: formData.name,
      description: formData.description || "",
      length: formData.length,
      width: formData.width,
      height: formData.height,
      icon: formData.icon,
    };
    try {
      await postRequestUI("/sizes", size);
      getSizes();
      handleCloseDialog();
    } catch (e) {
      if (e.code === "invalid-argument") {
        setErrorMessage(e.message);
      } else {
        handleCloseDialog();
        console.log(e);
      }
    } finally {
      setCreatingNew(false);
      setIsEdit(false);
      setFormData({});
    }
  };
  return (
    <MainBox>
      <AddButton onClick={handleClickOpenSize} />
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Sizes"} />
        </div>

        <TableComponent
          headers={sizesTableData.headers}
          rowData={sizes}
          cellComponents={sizesTableData.body}
        />
      </TableContainer>
      <Dialog
        fullScreen
        open={isOpenDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button
              data-testid="save_size_btn"
              autoFocus
              color="inherit"
              onClick={isEdit ? handleEdit : handleSave}
            >
              {isEdit ? "Update" : " Save"}
              {isCreatingNew && <ButtonProgress size={24} />}
            </Button>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!errorMessage}
            autoHideDuration={3000}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <form style={styles.container}>
            <FormControl sx={sharedStyles.formControl}>
              <Typography variant="h3" component="h4">
                {isEdit ? "Edit size" : " Create new size"}
              </Typography>
              <Grid container spacing={2} sx={styles.formContainer}>
                <Grid item xs={12}>
                  <TextField
                    data-testid="size_name_field"
                    id="size-name"
                    variant="standard"
                    label="Name"
                    fullWidth
                    value={formData.name || ""}
                    onChange={handleFormData("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid="size_description_field"
                    id="description"
                    variant="standard"
                    label="Description"
                    fullWidth
                    value={formData.description || ""}
                    onChange={handleFormData("description")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid="size-icon"
                    id="icon-id"
                    variant="standard"
                    select
                    label="Size icon"
                    margin="normal"
                    fullWidth
                    value={formData.icon || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        icon: e.target.value,
                      })
                    }
                  >
                    {ICONS.map((icon, i) => (
                      <MenuItem value={icon} key={i}>
                        <Box component="span" sx={styles.iconWrap}>
                          {sizeToIcon(icon)}
                        </Box>
                        {icon}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    data-testid="size_length_field"
                    id="length"
                    variant="standard"
                    label="Length"
                    type="number"
                    fullWidth
                    value={formData.length || ""}
                    onChange={handleFormData("length")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    data-testid="size_wight_field"
                    id="width"
                    variant="standard"
                    label="Width"
                    type="number"
                    fullWidth
                    value={formData.width || ""}
                    onChange={handleFormData("width")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    data-testid="size_height_field"
                    id="height"
                    variant="standard"
                    label="Height"
                    type="number"
                    fullWidth
                    value={formData.height || ""}
                    onChange={handleFormData("height")}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </MainBox>
  );
};

export default Sizes;
