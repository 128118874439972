import {
  Grid,
  Box,
  LinearProgress,
  TableContainer,
  Paper,
  Button,
  TextField,
  MenuItem,
  Dialog,
  Slide,
  Toolbar,
  IconButton,
  FormControl,
  CircularProgress,
  Chip,
  Autocomplete,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import {
  SharedStyles,
  PageTitle,
  TableComponent,
  IconButton as CIconButton,
} from "@shared/components/lib/index";
import useTheme from "@mui/material/styles/useTheme";
import { useQuery, useMutation } from "react-query";
import DialogAppBar from "../components/DialogAppBar";
import CloseIcon from "@mui/icons-material/Close";
import {
  getRequestUI,
  postRequestUI,
  deleteRequestUI,
  putRequestUI,
} from "common-utils/utils/api";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { notificationTypes } from "common-utils/utils/notification";
import EditIcon from "@mui/icons-material/Edit";
import HtmlEditor from "./HtmlEditor";
import LockIcon from "@mui/icons-material/Lock";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const possibleListVariables = [
  {
    name: `{backgroundColor}`,
    description: `Background color of thew header`,
  },
  {
    name: `{color}`,
    description: `Text color`,
  },
  {
    name: `{deliveryDate}`,
    description: `Parcel delivery date`,
  },
  {
    name: `{code}`,
    description: `Second factor code`,
  },
  {
    name: `{eta}`,
    description: `Estimated time of delivery`,
  },
  {
    name: `{trackingLink}`,
    description: `Tracking link of a parcel`,
  },
  {
    name: `{retailerName}`,
    description: `Retailer name`,
  },
  {
    name: `{name}`,
    description: `Name of a recipient`,
  },
  {
    name: `{retailerLocationName}`,
    description: `Pickup location name`,
  },
  {
    name: `{remark}`,
    description: `Delivery status remark`,
  },
  { name: `{productTimeframe}`, description: `Timeframe of product` },
  {
    name: `{brandingEmailText}`,
    description: `Branding tracking email text`,
  },
];

const RetailerNotificationTab = ({ statuses, reasons, types, retailerId }) => {
  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);
  const styles = useStyles(theme);
  const [newDialogOpen, setNewDialogOpen] = useState(false);
  const [status, setStatus] = useState({});
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [type, setType] = useState({});
  const [emailSubject, setEmailSubject] = useState("");
  const [deletingNotification, setDeletingNotification] = useState(null);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [template, setTemplate] = useState("");

  const handleClickOpenNotifications = () => {
    clear();
    setNewDialogOpen(true);
  };

  const editNotification = (notification) => {
    const selectedStatus = statuses.find(
      (status) => status.id === notification.status.id
    );
    setStatus(selectedStatus);
    setSelectedReasons(notification.reasons);
    setType(notification.type);
    setTemplate(notification.template);
    setEmailSubject(notification.subject);
    setSelectedNotificationId(notification.id);
    setNewDialogOpen(true);
  };

  const clear = () => {
    setStatus({});
    setSelectedReasons([]);
    setType("");
    setEmailSubject("");
    setTemplate("");
    setSelectedNotificationId(null);
  };

  const notificationTableData = {
    headers: ["", "Status", "Reason", "Type", "Second Factor", ""],
    body: [
      (notification) => (
        <Button
          endIcon={<EditIcon />}
          onClick={() => editNotification(notification)}
        />
      ),
      (notification) => notification.status.name,
      (notification) => (
        <>
          {notification.reasons.map((reason) => (
            <Chip label={reason.name} />
          ))}
        </>
      ),
      (notification) => notification.type,
      (notification) =>
        notification.hasTFA ? (
          <LockIcon style={{ float: "left", marginRight: "10px" }} />
        ) : null,
      (notification) =>
        deletingNotification === notification.id ? (
          <CircularProgress size={24} />
        ) : (
          <CIconButton
            icon="delete"
            onClick={() => handleDelete(notification.id)}
          />
        ),
    ],
  };

  const handleClose = () => {
    setNewDialogOpen(false);
  };

  const { data, refetch } = useQuery(["retailer-notifications"], async () => {
    const result = await getRequestUI(`retailer/notification/${retailerId}`);
    return result;
  });

  const { data: branding } = useQuery(["retailer-branding"], async () => {
    const result = await getRequestUI(`/retailer/${retailerId}/branding`);
    return result;
  });

  const handleSave = () => {
    const data = {
      status,
      reasons: selectedReasons,
      template,
      type,
      retailerId,
      emailSubject,
    };
    if (selectedNotificationId) {
      data.notificationId = selectedNotificationId;
      updateRetailerCarrierMutation.mutate(data);
    } else {
      createRetailerNotificationMutation.mutate(data);
    }
  };

  const handleEdit = () => {
    const data = {
      status,
      reasons: selectedReasons,
      type,
      template,
      retailerId,
      subject: emailSubject,
      notificationId: selectedNotificationId,
    };
    updateRetailerCarrierMutation.mutate(data);
  };

  const handleDelete = (id) => {
    setDeletingNotification(id);
    deleteRetailerNotificationMutation.mutate(id);
  };

  const createRetailerNotificationMutation = useMutation(
    createRetailerNotification,
    {
      onSuccess: () => {
        refetch();
      },
      onSettled: () => {
        handleClose();
      },
    }
  );

  const updateRetailerCarrierMutation = useMutation(
    updateRetailerNotification,
    {
      onSuccess: () => {
        refetch();
      },
      onSettled: () => {
        handleClose();
      },
    }
  );

  const deleteRetailerNotificationMutation = useMutation(
    deleteRetailerNotification,
    {
      onSuccess: () => {
        refetch();
      },
      onSettled: () => {
        setDeletingNotification(null);
      },
    }
  );

  async function createRetailerNotification(data) {
    await postRequestUI(`retailer/notification`, data);
  }

  async function updateRetailerNotification(data) {
    await putRequestUI(`retailer/notification/${data.notificationId}`, data);
  }

  async function deleteRetailerNotification(id) {
    await deleteRequestUI(`retailer/notification/${id}`);
  }

  return (
    <>
      <Grid container sx={styles.root}>
        <Box component="span" display={false ? "block" : "none"}>
          <LinearProgress />
        </Box>
        <TableContainer component={Paper} sx={sharedStyles.paper}>
          <div style={{ padding: 15 }}>
            <PageTitle setTitle={"Notifications"} />
          </div>

          <Button
            data-testid="add_notification_btn"
            sx={sharedStyles.addButton}
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => handleClickOpenNotifications()}
          >
            Add Notification
          </Button>
          <TableComponent
            headers={notificationTableData.headers}
            rowData={data || []}
            cellComponents={notificationTableData.body}
          />
        </TableContainer>
        {newDialogOpen && (
          <>
            <Box p={10} flexDirection="row">
              <Dialog
                fullScreen
                open={true}
                onClose={handleClose}
                TransitionComponent={Transition}
              >
                <DialogAppBar>
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    {selectedNotificationId ? (
                      <Button
                        data-testid="update_notification_btn"
                        autoFocus
                        color="inherit"
                        onClick={() => handleEdit()}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        data-testid="update_notification_btn"
                        autoFocus
                        color="inherit"
                        onClick={() => handleSave()}
                      >
                        Save
                      </Button>
                    )}
                  </Toolbar>
                </DialogAppBar>
                <Grid item xs={12} display="flex">
                  <Grid item xs={8}>
                    <form style={sharedStyles.container}>
                      <FormControl sx={sharedStyles.formControl}>
                        <Typography variant="h3" component="h4">
                          {selectedNotificationId ? (
                            <>Edit notification</>
                          ) : (
                            <>Add notificarion</>
                          )}
                        </Typography>
                        <>
                          <TextField
                            data-testid="select_status"
                            id="selectStatus"
                            variant="standard"
                            select
                            label="Select status"
                            margin="normal"
                            fullWidth
                            value={status}
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            {statuses.map((status, i) => (
                              <MenuItem value={status} key={i}>
                                {status.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <Autocomplete
                            options={reasons || []}
                            id="reasons"
                            getOptionLabel={(option) => option.name ?? ""}
                            classes={{
                              endAdornment: styles.endAdornment,
                            }}
                            multiple
                            value={selectedReasons}
                            onChange={(event, reasons) =>
                              setSelectedReasons(reasons)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Reasons"
                                variant="standard"
                              />
                            )}
                          />
                          <TextField
                            data-testid="select_type"
                            id="selectStatus"
                            variant="standard"
                            select
                            label="Select type"
                            margin="normal"
                            fullWidth
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            {types.map((type, i) => (
                              <MenuItem value={type} key={i}>
                                {type}
                              </MenuItem>
                            ))}
                          </TextField>
                        </>
                        {type === notificationTypes.EMAIL && (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                sx={sharedStyles.inputLabel}
                                variant="h5"
                              >
                                Email Subject
                              </Typography>
                              <TextareaAutosize
                                style={{ height: "50px", width: "450px" }}
                                id="sms-text"
                                value={emailSubject}
                                minRows={1}
                                maxLength={200}
                                onChange={(e) =>
                                  setEmailSubject(e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12} display="flex">
                              {type === notificationTypes.EMAIL && (
                                <HtmlEditor
                                  retailerId={retailerId}
                                  template={template}
                                  setTemplate={setTemplate}
                                />
                              )}
                            </Grid>
                            {/* <Grid item xs={12}>
                              <div
                                style={{
                                  maxHeight: "300px",
                                  overflow: "auto",
                                }}
                              >
                                <CodeEditor
                                  value={template}
                                  language="html"
                                  placeholder="Please enter html code"
                                  onChange={(evn) =>
                                    setTemplate(evn.target.value)
                                  }
                                  padding={15}
                                  style={{
                                    overflow: "auto", // Enable scrolling if content exceeds height
                                    fontSize: 12,
                                    backgroundColor: "#f5f5f5",
                                    fontFamily:
                                      "ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace",
                                  }}
                                />
                              </div>
                            </Grid> */}
                          </>
                        )}
                        {type === notificationTypes.SMS && (
                          <Grid item xs={12}>
                            <Typography sx={styles.inputLabel} variant="h5">
                              SMS Text
                            </Typography>
                            <TextareaAutosize
                              style={{ height: "200px", width: "600px" }}
                              id="sms-text"
                              value={template}
                              minRows={4}
                              maxLength={200}
                              onChange={(e) => setTemplate(e.target.value)}
                            />
                          </Grid>
                        )}
                      </FormControl>
                    </form>
                  </Grid>
                  <Grid item xs={4} sx={styles.list}>
                    <Typography variant="h4">Possible variables</Typography>
                    <List dense={false}>
                      {possibleListVariables.map((element, index) => (
                        <ListItem sx={styles.listItem} key={index}>
                          <ListItemText
                            primary={element.name}
                            secondary={element.description}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Dialog>
            </Box>
          </>
        )}
      </Grid>
    </>
  );
};

const useStyles = (theme) => ({
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  root: {
    "& .wrapperClassName": {
      height: "300px",
      width: "600px",
      border: "1px solid black",
    },
  },
  inputLabel: {
    fontWeight: "bold",
    fontSize: "1.3em",
  },
  modal: {
    position: "absolute",
    width: 800,
  },
  listItem: {
    padding: "4px 0px",
  },
  list: {
    padding: "40px 0",
  },
});

export default RetailerNotificationTab;
