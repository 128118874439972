"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styles = require("@mui/material/styles");
var theme = (0, _styles.createTheme)({
  palette: {
    primary: {
      main: "#0635C9",
      contrastText: "#fff"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          color: "inherit",
          primary: {
            color: "inherit"
          }
        }
      }
    },
    MuiTypography: {
      variants: [{
        props: {
          variant: "h3",
          component: "h4"
        },
        style: {
          paddingBottom: 8
        }
      }]
    }
  }
});
var _default = exports["default"] = theme;