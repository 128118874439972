import React, { useState } from "react";
import _ from "lodash";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import ReturnAddressForm from "./ReturnAddressForm";
import { getRequestUI, deleteRequestUI } from "common-utils/utils/api";
import useTheme from "@mui/material/styles/useTheme";
import { useMutation, useQuery } from "react-query";
const useStyles = (theme) => ({
  root: {
    "& .wrapperClassName": {
      height: "300px",
      width: "600px",
      border: "1px solid black",
    },
  },
});

import {
  IconButton as CIconButton,
  TableComponent,
  PageTitle,
  SharedStyles,
} from "@shared/components/lib/index";
import { Snackbar, Alert } from "@mui/material";

const RetailerReturnAddressTab = ({ retailerId }) => {
  const [deletingReturnAddress, setDeletingReturnAddress] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [returnAddressData, setReturnAddressData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);

  const { data, refetch: reloadReturnAddressData } = useQuery(
    ["retailer-return-addresses"],
    async () => {
      const result = await getRequestUI(
        `/retailer/return-address/${retailerId}`
      );
      return result;
    }
  );

  const deleteMutaion = useMutation(deleteRetailerReturnAddress, {
    onSuccess: () => {
      reloadReturnAddressData();
    },
    onError: () => {
      setErrorMessage("Failed to delete the address. Please try again.");
    },
    onSettled: () => {
      setDeletingReturnAddress(null);
    },
  });
  async function deleteRetailerReturnAddress(id) {
    await deleteRequestUI(`retailer/return-address/${id}`);
  }

  const handleDelete = (id) => {
    setDeletingReturnAddress(id);
    deleteMutaion.mutate(id);
  };

  const returnAddressTableData = {
    headers: [
      "Name",
      "Country",
      "City",
      "Street",
      "Postcode",
      "House Number",
      "House Addition",
      "Email",
      "",
    ],
    body: [
      (returnAddress) => returnAddress.name,
      (returnAddress) => returnAddress.address.country.name,
      (returnAddress) => returnAddress.address.city,
      (returnAddress) => returnAddress.address.street,
      (returnAddress) => returnAddress.address.postcode,
      (returnAddress) => returnAddress.address.houseNumber,
      (returnAddress) => returnAddress.address.houseAdditions,
      (returnAddress) => returnAddress.email,
      (returnAddress) => (
        <>
          <Button
            data-testid="edit_return_address_btn"
            endIcon={<EditIcon />}
            onClick={() => clickOnEditReturnAddress(returnAddress)}
          />
          {deletingReturnAddress === returnAddress.id ? (
            <CircularProgress size={24} sx={styles.deleteProgress} />
          ) : (
            <CIconButton
              data-testid="delete_return_address_btn"
              icon="delete"
              onClick={() => handleDelete(returnAddress.id)}
            />
          )}
        </>
      ),
    ],
  };

  const openNewReturnAddressForm = () => {
    setReturnAddressData(null);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setReturnAddressData(null);
  };

  const clickOnEditReturnAddress = (returnAddress) => {
    setReturnAddressData({
      ...returnAddress,
      postcode: returnAddress.address.postcode,
      houseAdditions: returnAddress.address.houseAdditions,
      houseNumber: returnAddress.address.houseNumber,
      country: returnAddress.address.country,
      city: returnAddress.address.city,
      street: returnAddress.address.street,
    });
    setOpenForm(true);
  };

  return (
    <Grid container sx={styles.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!errorMessage}
        autoHideDuration={3000}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Return Addresses"} />
        </div>
        <Button
          data-testid="add_return_address_btn"
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={openNewReturnAddressForm}
        >
          Add New Address
        </Button>
        <TableComponent
          headers={returnAddressTableData.headers}
          rowData={data || []}
          cellComponents={returnAddressTableData.body}
        />
      </TableContainer>

      {openForm && (
        <Dialog
          open={openForm}
          onClose={handleCloseForm}
          maxWidth="sm"
          fullWidth
        >
          <ReturnAddressForm
            returnAddress={returnAddressData}
            retailerId={retailerId}
            onSave={reloadReturnAddressData}
            handleClose={handleCloseForm}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default RetailerReturnAddressTab;
