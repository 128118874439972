import React, { useState } from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogAppBar from "../components/DialogAppBar";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import useTheme from "@mui/material/styles/useTheme";
import { ButtonProgress } from "@shared/components/lib/index";
import { getData } from "country-list";

import TextField from "@mui/material/TextField";
import {
  Grid,
  LinearProgress,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import {
  IconButton as CIconButton,
  TableComponent,
  PageTitle,
  SharedStyles,
} from "@shared/components/lib/index";
import {
  postRequestUI,
  getRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";
import { useQuery, useMutation } from "react-query";

const useStyles = (theme) => ({
  deleteProgress: {
    marginLeft: 10,
  },
  mainBox: {
    padding: 0,
  },
  iconWrap: {
    marginBottom: "-4px",
    marginRight: "4px",
  },
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 540,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CarrierCountries = ({ carrierId }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [deletingProgress, setDeletingProgress] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [isCreatingNew, setCreatingNew] = useState(false);

  const carrierCountries = useQuery(["carrierCountries"], async () => {
    const result = await getRequestUI(`/carrier-countries/${carrierId}`);
    return result;
  });

  const createCarrierCountryMutation = useMutation(createCarrierCountry, {
    onSuccess: () => {
      carrierCountries.refetch();
    },
    onError: () => handleCloseDialog(),
  });

  const deleteCarrierCountryMutation = useMutation(deleteCarrierCountry, {
    onSuccess: () => {
      carrierCountries.refetch();
    },
  });

  async function createCarrierCountry(data) {
    setCreatingNew(true);
    await postRequestUI(`carrier-country/${carrierId}`, data);
    setCreatingNew(false);
    handleCloseDialog();
  }

  async function deleteCarrierCountry(data) {
    setDeletingProgress(true);
    await deleteRequestUI(`carrier-country/${carrierId}/${data}`);
    setDeletingProgress(false);
    handleCloseDialog();
  }

  const handleSave = () => {
    createCarrierCountryMutation.mutate(selectedCountry);
  };

  const setFormData = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleDelete = (countryCode) => {
    deleteCarrierCountryMutation.mutate(countryCode);
  };

  const carriersCountriesTableData = {
    headers: ["Name", ""],
    body: [
      (country) => country.name,
      (country) =>
        deletingProgress ? (
          <CircularProgress size={24} sx={styles.deleteProgress} />
        ) : (
          <CIconButton
            icon="delete"
            onClick={() => handleDelete(country.code)}
          />
        ),
    ],
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setShowProgress(true);
    const allCountries = getData();
    const filtered = allCountries.filter(
      (country) =>
        !carrierCountries.data.find(
          (carrierCountry) => carrierCountry.code === country.code
        )
    );
    setFilteredCountries(filtered);
    setSelectedCountry({});
    setOpenDialog(true);
  };

  const getErrorMessage = () => {
    if (carrierCountries.isError) {
      return carrierCountries.error.message;
    }
    if (createCarrierCountryMutation.isError) {
      return createCarrierCountryMutation.error.message;
    }
    if (deleteCarrierCountryMutation.isError) {
      deleteCarrierCountryMutation.error.message;
    }
    return "";
  };

  return (
    <Grid container>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <Button
          data-testid="add_country_btn"
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => handleOpenDialog()}
        >
          Add Country
        </Button>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Countries"} />
        </div>

        <TableComponent
          headers={carriersCountriesTableData.headers}
          rowData={carrierCountries.data || []}
          cellComponents={carriersCountriesTableData.body}
        />
      </TableContainer>
      <Dialog
        fullScreen
        open={isOpenDialog}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              data-testid="close_country_btn"
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button
              data-testid="save_country_btn"
              autoFocus
              color="inherit"
              onClick={handleSave}
            >
              Save
              {isCreatingNew && <ButtonProgress size={24} />}
            </Button>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!getErrorMessage()}
            autoHideDuration={3000}
          >
            <Alert severity="error">{getErrorMessage()}</Alert>
          </Snackbar>
          <form style={styles.container}>
            <FormControl sx={sharedStyles.formControl}>
              <Typography variant="h3" component="h4">
                Adding country
              </Typography>
              <Grid container spacing={2} sx={styles.formContainer}>
                <Grid item xs={12}>
                  {!!filteredCountries.length ? (
                    <TextField
                      data-testid="select_country"
                      id="selectCountry"
                      variant="standard"
                      select
                      label="Select country"
                      margin="normal"
                      fullWidth
                      value={selectedCountry}
                      onChange={setFormData}
                    >
                      {filteredCountries.map((size, i) => (
                        <MenuItem value={size} key={i}>
                          {size.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  ) : (
                    <Typography>All countries selected</Typography>
                  )}
                </Grid>
              </Grid>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default CarrierCountries;
