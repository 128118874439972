import { DateTime } from "luxon";

const zeroPad = (num, places) => String(num).padStart(places, "0");

export const formatSliderToDisplay = (value) => {
  let hours = Math.floor(value);
  let minutes = (value % 1) * 60;
  return `${zeroPad(hours, 2)}:${zeroPad(minutes, 2)}`;
};

export const formatValueToSeconds = (timeValue) => {
  const [hours, minutes] = timeValue
    .toString()
    .split(":")
    .map((value) => parseInt(value));
  return hours * 3600 + minutes * 60;
};

export const formatTimeToSlider = (seconds) => {
  const time = formatTimeFromSeconds(seconds);
  let [hours, minutes] = time.split(":").map((value) => parseInt(value));
  return hours + minutes / 60;
};

export const formatTimeFromSeconds = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  return DateTime.fromObject({ hour: hours, minute: minutes }).toFormat("T");
};
