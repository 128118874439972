import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { adminAvailableRoles } from "../constants";

const useStyles = (theme) => ({
  root: {
    padding: "15px",
  },
  endAdornment: {
    top: 0,
    bottom: "calc(50% - 14px)",
  },
});

const TableFilter = ({
  tableFilter,
  selectedFilterData,
  setSelectedFilterData,
}) => {
  const styles = useStyles();
  return (
    <>
      {tableFilter.map((filter) => {
        const { data, label, key, id } = filter;
        return (
          <Grid sx={styles.root} item xs={12} lg={4} md={4}>
            <Autocomplete
              options={data}
              id={`${filter.id}_filter`}
              getOptionLabel={(option) => option.name ?? ""}
              classes={{
                endAdornment: styles.endAdornment,
              }}
              multiple
              value={selectedFilterData[key] || []}
              onChange={(e, value) => {
                setSelectedFilterData({ [key]: value });
              }}
              renderInput={(params) => (
                <TextField {...params} label={label} variant="standard" />
              )}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default TableFilter;
