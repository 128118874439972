import { DateTime } from "luxon";

export const tomorrow = () => {
  return DateTime.local().plus({ days: 1 }).toJSDate();
};

export const getCurrentMondayToNextMonday = () => {
  const currentDate = DateTime.local();
  const currentDayOfWeek = currentDate.weekday;
  const daysToCurrentMonday = (currentDayOfWeek - 1) % 7;
  const currentWeekMonday = currentDate.minus({ days: daysToCurrentMonday });
  const nextWeekMonday = currentWeekMonday.plus({ weeks: 1 });

  return {
    currentWeekMonday: currentWeekMonday.toJSDate(),
    nextWeekMonday: nextWeekMonday.toJSDate(),
  };
};
