import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  getRequestUI,
  deleteRequestUI,
  postRequestUI,
} from "common-utils/utils/api";
import DialogAppBar from "./DialogAppBar";
import Slide from "@mui/material/Slide";
import FormControl from "@mui/material/FormControl";
import useTheme from "@mui/material/styles/useTheme";

import {
  ButtonProgress,
  DeleteButton,
  PageTitle,
  TableComponent,
  SharedStyles,
} from "@shared/components/lib/index";
import { useQuery, useMutation } from "react-query";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PropertiesList = ({ ownerId, api }) => {
  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);
  const [IsNewPropertyDialogOpen, setNewDialogOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [isCreatingNew, setCreatingNew] = useState(false);

  const prerequisites = useQuery(
    [`getting-properties-for-${ownerId}`],
    async () => {
      const result = await getRequestUI(api.get(ownerId));
      return result;
    }
  );

  async function deleteProperty(id) {
    await deleteRequestUI(api.delete(id));
    prerequisites.refetch();
  }

  const createMutation = useMutation(saveProperty, {
    onSuccess: () => {
      prerequisites.refetch();
      setCreatingNew(false);
      handleClose();
    },
  });

  const deleteMutation = useMutation(deleteProperty, {
    onSuccess: () => {
      prerequisites.refetch();
      handleClose();
    },
  });

  const handleDeleteProperty = async (id) => {
    deleteMutation.mutate(id);
  };

  const handleSave = () => {
    const data = {
      ...formData,
      ownerId,
    };
    createMutation.mutate(data);
  };

  async function saveProperty(data) {
    await postRequestUI(api.save(), data);
  }

  const { data = [] } = prerequisites;

  const propertyTableData = {
    headers: ["Name", "Value", "Show on Label", ""],
    body: [
      (property) => property.name,
      (property) => property.value,
      (property) => <Checkbox checked={property.showOnLabel} readOnly />,
      (property) => (
        <DeleteButton id={property.id} deleteItem={handleDeleteProperty} />
      ),
    ],
  };

  const handleClickOpenProperties = () => {
    setNewDialogOpen(true);
    setFormData({});
  };

  const handleClose = () => {
    setNewDialogOpen(false);
  };

  const getErrorMessage = () => {
    if (prerequisites.isError) {
      return prerequisites.error.message;
    }
    if (createMutation.isError) {
      return createMutation.error.message;
    }
    if (deleteMutation.isError) {
      return deleteMutation.error.message;
    }
    return "";
  };

  return (
    <Grid container>
      <TableContainer component={Paper} sx={sharedStyles.paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Properties"} />
        </div>
        <Button
          data-testid="add_property_btn"
          sx={sharedStyles.addButton}
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => handleClickOpenProperties()}
        >
          Add Property
        </Button>
        <TableComponent
          headers={propertyTableData.headers}
          rowData={data}
          cellComponents={propertyTableData.body}
        />
      </TableContainer>
      {IsNewPropertyDialogOpen && (
        <Box p={10} flexDirection="row">
          <Dialog
            fullScreen
            open={true}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <DialogAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Button
                  data-testid="save_property_btn"
                  autoFocus
                  color="inherit"
                  onClick={handleSave}
                >
                  Save
                  {isCreatingNew && <ButtonProgress size={24} />}
                </Button>
              </Toolbar>
            </DialogAppBar>
            <DialogContent>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={!!getErrorMessage()}
                autoHideDuration={3000}
              >
                <Alert severity="error">{getErrorMessage()}</Alert>
              </Snackbar>
              <form style={sharedStyles.container}>
                <FormControl sx={sharedStyles.formControl}>
                  <Typography variant="h3" component="h4">
                    Add Property
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        data-testid="name"
                        id="name"
                        variant="standard"
                        label="Name"
                        fullWidth
                        value={formData.name || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            name: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        data-testid="value"
                        id="value"
                        variant="standard"
                        label="Value"
                        fullWidth
                        value={formData.value || ""}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            value: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            data-testid="show_on_the_label"
                            checked={formData.showOnLabel || false}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                showOnLabel: e.target.checked,
                              })
                            }
                            name="show_sender_address_on_label"
                            color="primary"
                          />
                        }
                        label="Show On Label"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </form>
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Grid>
  );
};

PropertiesList.propTypes = {
  ownerId: PropTypes.number,
  api: {
    get: PropTypes.func,
    save: PropTypes.func,
    delete: PropTypes.func,
  },
};

export default PropertiesList;
