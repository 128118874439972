import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MuiPhoneNumber from "material-ui-phone-number-2";
import Autocomplete from "@mui/material/Autocomplete";
import useTheme from "@mui/material/styles/useTheme";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import DialogAppBar from "../components/DialogAppBar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import { useQuery, useMutation } from "react-query";

import {
  getRequestUI,
  postRequestUI,
  putRequestUI,
} from "common-utils/utils/api";
import { ButtonProgress, SharedStyles } from "@shared/components/lib/index";
import _ from "lodash";
import { Snackbar, Alert } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ReturnAddressFormTab = ({
  returnAddress,
  retailerId,
  onSave,
  handleClose,
}) => {
  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);
  const [returnAddressData, setReturnAddressData] = useState(returnAddress);
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);

  const prerequisites = useQuery(
    ["admin-returnAddress-prerequisites"],
    async () => {
      const result = await getRequestUI("/admin/location-prerequisites");
      return result;
    },
    {
      onError: (err) => {
        setErrorMessage(err.message);
      },
    }
  );
  const { countries = [] } = prerequisites.data ? prerequisites.data : {};

  const validate = () => {
    if (!returnAddressData) {
      setErrorMessage("Please fill return address inputs");
      setShowError(true);
      return false;
    }
    if (!returnAddressData.name) {
      setErrorMessage("Return address name is required");
      setShowError(true);
      return false;
    }
    if (!returnAddressData.postcode) {
      setErrorMessage("Postcode is required");
      setShowError(true);
      return false;
    }
    if (!returnAddressData.houseNumber) {
      setErrorMessage("House number is required");
      setShowError(true);
      return false;
    }
    if (_.isEmpty(returnAddressData.country)) {
      setErrorMessage("Country is required");
      setShowError(true);
      return false;
    }
    if (!returnAddressData.email) {
      setErrorMessage("Email is required");
      setShowError(true);
      return false;
    }
    if (!returnAddressData.contactName) {
      setErrorMessage("Contact name is required");
      setShowError(true);
      return false;
    }
    if (!returnAddressData.contactNumber) {
      setErrorMessage("Contact number is required");
      setShowError(true);
      return false;
    }

    return true;
  };

  const handleAddReturnAddress = () => {
    if (!validate()) {
      return;
    }
    const data = {
      name: returnAddressData.name,
      retailerId,
      address: {
        postcode: returnAddressData.postcode,
        houseNumber: returnAddressData.houseNumber,
        houseAdditions: returnAddressData.houseAdditions,
        country: returnAddressData.country,
      },
      email: returnAddressData.email,
      contactName: returnAddressData.contactName,
      contactNumber: returnAddressData.contactNumber,
      city: returnAddressData.city,
      street: returnAddressData.street,
    };
    createRetailerReturnAddressMutation.mutate(data);
  };

  const handleUpdateReturnAddress = () => {
    if (!validate()) {
      return;
    }
    const data = {
      id: returnAddressData.id,
      name: returnAddressData.name,
      retailerId,
      address: {
        postcode: returnAddressData.postcode,
        houseNumber: returnAddressData.houseNumber,
        houseAdditions: returnAddressData.houseAdditions,
        country: returnAddressData.country,
      },
      email: returnAddressData.email,
      contactName: returnAddressData.contactName,
      contactNumber: returnAddressData.contactNumber,
      city: returnAddressData.city,
      street: returnAddressData.street,
    };
    updateRetailerReturnAddressMutation.mutate(data);
  };

  const createRetailerReturnAddressMutation = useMutation(
    createRetailerReturnAddress,
    {
      onSuccess: () => {
        onSave();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
      onSettled: () => {
        handleClose();
      },
    }
  );

  const updateRetailerReturnAddressMutation = useMutation(
    updateRetailerReturnAddress,
    {
      onSuccess: () => {
        onSave();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
      onSettled: () => {
        handleClose();
      },
    }
  );

  async function createRetailerReturnAddress(data) {
    await postRequestUI(`retailer/return-address`, data);
  }

  async function updateRetailerReturnAddress(data) {
    await putRequestUI(`retailer/return-address/${data.id}`, data);
  }

  const handleCountry = (e, value) => {
    if (!value) {
      setReturnAddressData({ ...returnAddressData, country: {} });
    }
    setReturnAddressData({
      ...returnAddressData,
      country: value,
      city: "",
      street: "",
      postcode: "",
      houseNumber: "",
      houseAdditions: "",
    });
  };

  const submit = () => {
    if (!!returnAddress) {
      handleUpdateReturnAddress();
    } else {
      handleAddReturnAddress();
    }
  };

  const setButtonValue = () => {
    return !!returnAddress ? "Edit Address" : "Save Address";
  };

  return (
    <>
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button
              data-testid="add_return_address_btn"
              autoFocus
              color="inherit"
              onClick={submit}
            >
              {setButtonValue()}
            </Button>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={showError}
            autoHideDuration={3000}
            onClose={() => setShowError(false)}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <form style={sharedStyles.container}>
            <FormControl sx={sharedStyles.formControl}>
              <Typography variant="h3" component="h4">
                {!!returnAddress
                  ? "Edit Return Address"
                  : " Create New Return Address"}
              </Typography>

              <TextField
                data-testid="name_field"
                id="name"
                variant="standard"
                label="Return Address"
                value={
                  returnAddressData && returnAddressData.name
                    ? returnAddressData.name
                    : ""
                }
                onChange={(e) =>
                  setReturnAddressData({
                    ...returnAddressData,
                    name: e.target.value,
                  })
                }
              />
              <Autocomplete
                data-testid="countries_autocomplete"
                id="countries-autocomplete"
                autoComplete
                autoHighlight
                options={countries || []}
                value={
                  returnAddressData && returnAddressData.country
                    ? returnAddressData.country
                    : {}
                }
                getOptionLabel={(option) => option.name ?? ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Select Country"
                    placeholder="Select country"
                  />
                )}
                onChange={handleCountry}
              />

              <>
                <TextField
                  data-testid="postcode_field"
                  id="postcode-field"
                  variant="standard"
                  label="Postcode"
                  value={
                    returnAddressData && returnAddressData.postcode
                      ? returnAddressData.postcode
                      : ""
                  }
                  onChange={(e) =>
                    setReturnAddressData({
                      ...returnAddressData,
                      postcode: e.target.value,
                    })
                  }
                />
                <TextField
                  data-testid="house_number_field"
                  id="house-number-field"
                  variant="standard"
                  label="House Number"
                  value={
                    returnAddressData && returnAddressData.houseNumber
                      ? returnAddressData.houseNumber
                      : ""
                  }
                  onChange={(e) =>
                    setReturnAddressData({
                      ...returnAddressData,
                      houseNumber: e.target.value,
                    })
                  }
                />
                <TextField
                  data-testid="house_addition_field"
                  id="house-addition"
                  variant="standard"
                  label='Addition (e.g. "H" or "1")'
                  value={
                    returnAddressData && returnAddressData.houseAdditions
                      ? returnAddressData.houseAdditions
                      : ""
                  }
                  onChange={(e) =>
                    setReturnAddressData({
                      ...returnAddressData,
                      houseAdditions: e.target.value,
                    })
                  }
                />
                <TextField
                  data-testid="street_field"
                  id="street_field"
                  variant="standard"
                  label="Street"
                  value={
                    returnAddressData && returnAddressData.street
                      ? returnAddressData.street
                      : ""
                  }
                  onChange={(e) =>
                    setReturnAddressData({
                      ...returnAddressData,
                      street: e.target.value,
                    })
                  }
                />
              </>

              <TextField
                data-testid="contact_name_field"
                id="contact-name"
                variant="standard"
                label="Contact name"
                value={
                  returnAddressData && returnAddressData.contactName
                    ? returnAddressData.contactName
                    : ""
                }
                onChange={(e) =>
                  setReturnAddressData({
                    ...returnAddressData,
                    contactName: e.target.value,
                  })
                }
              />
              <TextField
                variant="standard"
                id="email"
                label="Email"
                type="search"
                fullWidth
                value={
                  returnAddressData && returnAddressData.email
                    ? returnAddressData.email
                    : ""
                }
                onChange={(e) =>
                  setReturnAddressData({
                    ...returnAddressData,
                    email: e.target.value,
                  })
                }
              />

              <MuiPhoneNumber
                data-testid="contact_number_field"
                id="contact-number"
                variant="standard"
                label="Contact number"
                defaultCountry="nl"
                regions={"europe"}
                value={
                  returnAddressData && returnAddressData.contactNumber
                    ? returnAddressData.contactNumber
                    : ""
                }
                onChange={(value) =>
                  setReturnAddressData({
                    ...returnAddressData,
                    contactNumber: value,
                  })
                }
              />
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default ReturnAddressFormTab;
