import React, { useState, useRef } from "react";
import axios from "axios";
import SVG from "react-inlinesvg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { IconButton as CIconButton } from "@shared/components/lib/index";

const useStyles = (theme) => ({
  wrapper: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  dropZone: {
    width: "100%",
    height: "150px",
    border: "3px dashed #0635C9",
    padding: "20px",
    textAlign: "center",
  },
  image: {
    maxHeight: "200px",
    maxWidth: "350px",
    margin: "20 0 20 0",
  },
});

const SVGUpload = ({ svgXml, setSvgXml, isError, setIsError }) => {
  const styles = useStyles();
  const fileInput = useRef(null);
  const [acceptedImageTypes, setAcceptedImageTypes] = useState([
    "image/svg",
    "image/svg+xml",
  ]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOndragOver = (event) => {
    event.preventDefault();
  };
  const handleOndrop = (event) => {
    //prevent the browser from opening the image
    event.preventDefault();
    event.stopPropagation();
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  };

  const getXmlFromSvg = async (file) => {
    const url = URL.createObjectURL(file);
    const response = await axios.get(url);
    const svgXml = response.data;
    setSvgXml(svgXml);
  };

  const handleFile = async (file) => {
    const isValidFile = acceptedImageTypes.includes(file.type);
    if (isValidFile) {
      setIsError(false);
      await getXmlFromSvg(file);
      return;
    }
    setSvgXml("");
    setIsError(true);
    setErrorMessage(
      `File with type ${file.type} not valid. Please use only SVG files`
    );
  };

  const handleDeleteIcon = () => {
    setSvgXml("");
    setIsError(false);
  };

  return (
    <Grid sx={styles.wrapper}>
      <Grid
        sx={styles.dropZone}
        onDragOver={handleOndragOver}
        onDrop={handleOndrop}
        onClick={() => fileInput.current.click()}
      >
        <p>Click to select or Drag and drop image here....</p>
        <input
          type="file"
          accept="image/*"
          ref={fileInput}
          hidden
          onChange={async (e) => await handleFile(e.target.files[0])}
        />
      </Grid>
      {svgXml && (
        <div>
          <SVG style={styles.image} src={svgXml} />
          <CIconButton
            data-testid="delete_icon_btn"
            icon="delete"
            onClick={handleDeleteIcon}
          />
        </div>
      )}
      {isError && (
        <Typography variant="h6" style={{ color: "red" }}>
          {errorMessage}
        </Typography>
      )}
    </Grid>
  );
};

export default SVGUpload;
