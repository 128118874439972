import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import useTheme from "@mui/material/styles/useTheme";
import Popper from "@mui/material/Popper";
import { Grid, Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import {
  getRequestUI,
  postRequestUI,
  putRequestUI,
} from "common-utils/utils/api";
import DialogAppBar from "../components/DialogAppBar";
import { ButtonProgress, SharedStyles } from "@shared/components/lib/index";
import Slider from "@mui/material/Slider";
import Chip from "@mui/material/Chip";
import { DateTime } from "luxon";
import {
  formatSliderToDisplay,
  formatTimeToSlider,
  formatValueToSeconds,
} from "common-utils/utils/time";
import { colorGrey } from "common-utils/utils/commonColors";

const useStyles = (theme) => ({
  table: {
    minWidth: 650,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    display: "flex",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 340,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
  },
  deleteProgress: {
    marginLeft: 10,
  },
  activeProductStyle: {
    width: "32%",
    borderRadius: 5,
    padding: "10 13",
    border: "1px solid #0635C9",
    backgroundColor: "#0635C9",
    color: "#fff",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 7,
    },
  },
  productStyle: {
    width: "32%",
    borderRadius: 5,
    padding: "10 13",
    border: "1px solid" + colorGrey,
    color: "#000",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 7,
    },
  },
  activeTypeOfDeliveryStyle: {
    width: "32%",
    borderRadius: 5,
    padding: "10 15",
    border: "1px solid #0635C9",
    backgroundColor: "#0635C9",
    color: "#fff",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 7,
    },
  },
  typeOfDeliveryStyle: {
    width: "32%",
    borderRadius: 5,
    padding: "10 15",
    border: "1px solid" + colorGrey,
    color: "#000",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: 7,
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Products({
  carrierId,
  handleClose,
  updateProductTable,
  product,
  isEdit,
  types,
}) {
  const [formData, setFormData] = useState({});
  const [showProgress, setShowProgress] = useState(false);
  const [isCreatingNew, setCreatingNew] = useState(false);
  const [deletingCarrier, setDeletingCarrier] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [edit, setEdit] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [pickupStartSeconds, setPickupStartSeconds] = useState(54000);
  const [pickupEndSeconds, setPickupEndSeconds] = useState(54000);
  const [deliveryStartSeconds, setDeliveryStartSeconds] = useState(54000);
  const [deliveryEndSeconds, setDeliveryEndSeconds] = useState(54000);
  const [deliveryAfterDaysValue, setDeliveryAfterDays] = useState(0);
  const [daysToPickUpValue, setDaysToPickUp] = useState(1);
  const [pickupValue, setPickupValue] = useState([]);
  const [deliveryValue, setDeliveryValue] = useState([]);
  const theme = useTheme();
  const styles = useStyles(theme);
  const shared = SharedStyles(theme);

  useEffect(() => {
    if (Object.keys(product).length > 0) {
      setFormData({
        name: product.name,
        description: product.description,
        type: product.type,
      });
      setPickupStartSeconds(product.pickup.startSeconds);
      setPickupEndSeconds(product.pickup.endSeconds);
      setDeliveryStartSeconds(product.delivery.startSeconds);
      setDeliveryEndSeconds(product.delivery.endSeconds);
      setDeliveryAfterDays(product.deliveryAfterDays);
      setDaysToPickUp(product.daysToPickup);
      pickupValue[0] = formatTimeToSlider(product.pickup.startSeconds);
      pickupValue[1] = formatTimeToSlider(product.pickup.endSeconds);
      deliveryValue[0] = formatTimeToSlider(product.delivery.startSeconds);
      deliveryValue[1] = formatTimeToSlider(product.delivery.endSeconds);
    }
  }, [product]);

  const handleSave = async () => {
    if (validate()) {
      return;
    }
    setErrorMessage("");
    setCreatingNew(true);
    let product = {
      name: formData.name,
      description: formData.description || "",
      carrierId: carrierId,
      pickupStartSeconds: pickupStartSeconds,
      pickupEndSeconds: pickupEndSeconds,
      deliveryStartSeconds: deliveryStartSeconds,
      deliveryEndSeconds: deliveryEndSeconds,
      deliveryAfterDays: deliveryAfterDaysValue,
      daysToPickup: daysToPickUpValue,
      type: formData.type,
    };
    try {
      const response = await postRequestUI("/product", product);
      updateProductTable(carrierId);
      setCreatingNew(false);
      handleClose();
    } catch (e) {
      if (e.code === "invalid-argument") {
        setErrorMessage(e.message);
      } else {
        handleClose();
        console.log(e);
        setCreatingNew(false);
      }
    }
  };

  const validate = () => {
    if (!formData.name) {
      setErrorMessage("Name is required");
      return true;
    }

    if (!formData.type) {
      setErrorMessage("Type is required");
      return true;
    }
    return false;
  };

  const handleFormData = useCallback(
    (key) => (e) => {
      setFormData({ ...formData, [key]: e.target.value });
    },
    [formData]
  );

  const handleEdit = async () => {
    if (validate()) {
      return;
    }
    setErrorMessage("");
    setCreatingNew(true);
    let updatedProduct = {
      name: formData.name,
      description: formData.description || "",
      carrierId: carrierId,
      pickupStartSeconds: pickupStartSeconds,
      pickupEndSeconds: pickupEndSeconds,
      deliveryStartSeconds: deliveryStartSeconds,
      deliveryEndSeconds: deliveryEndSeconds,
      deliveryAfterDays: deliveryAfterDaysValue,
      daysToPickup: daysToPickUpValue,
      type: formData.type,
    };
    try {
      const response = await putRequestUI(
        `/product/${product.id}`,
        updatedProduct
      );
      updateProductTable(carrierId);
      setCreatingNew(false);
      handleClose();
    } catch (e) {
      if (e.code === "invalid-argument") {
        setErrorMessage(e.message);
      } else {
        handleClose();
        console.log(e);
        setCreatingNew(false);
      }
    }
  };

  const handleChangePickupTime = (newValue) => {
    setPickupStartSeconds(
      formatValueToSeconds(formatSliderToDisplay(newValue[0]))
    );
    setPickupEndSeconds(
      formatValueToSeconds(formatSliderToDisplay(newValue[1]))
    );
  };

  const handleChangeDeliveryTime = (newValue) => {
    setDeliveryStartSeconds(
      formatValueToSeconds(formatSliderToDisplay(newValue[0]))
    );
    setDeliveryEndSeconds(
      formatValueToSeconds(formatSliderToDisplay(newValue[1]))
    );
  };

  const handleChangeDeliveryAfterDays = (newValue) => {
    setDeliveryAfterDays(newValue);
  };

  const handleChangeDaysToPickup = (newValue) => {
    setDaysToPickUp(newValue);
  };
  return (
    <Box p={10} flexDirection="row">
      <Dialog
        fullScreen
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button
              data-testid="save_product_btn"
              autoFocus
              color="inherit"
              onClick={isEdit ? handleEdit : handleSave}
            >
              {isEdit ? "Update" : " Save"}
              {isCreatingNew && <ButtonProgress size={24} />}
            </Button>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={!!errorMessage}
            autoHideDuration={3000}
          >
            <Alert severity="error">{errorMessage}</Alert>
          </Snackbar>
          <form style={styles.container}>
            <FormControl sx={shared.formControl}>
              <Typography variant="h3" component="h4">
                {isEdit ? "Edit Product" : " Create new Product"}
              </Typography>
              <Grid container sx={styles.formContainer}>
                <Grid item xs={12}>
                  <TextField
                    data-testid="product_name_field"
                    id="product-name"
                    variant="standard"
                    label="Name"
                    fullWidth
                    value={formData.name || ""}
                    onChange={handleFormData("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid="product_description_field"
                    id="description"
                    variant="standard"
                    label="Description"
                    fullWidth
                    value={formData.description || ""}
                    onChange={handleFormData("description")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid="schedueling_type"
                    id="scheduelingType"
                    variant="standard"
                    select
                    label="Product Type"
                    margin="normal"
                    fullWidth
                    value={formData.type || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        type: e.target.value,
                      })
                    }
                  >
                    {types.map((type, i) => (
                      <MenuItem value={type.id} key={i}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Typography id="discrete-slider" gutterBottom>
                  Pickup cutoff
                </Typography>
                <Slider
                  data-testid="pickup_cutoff_slider"
                  defaultValue={pickupValue.length ? pickupValue : [15, 15]}
                  aria-labelledby="range-slider"
                  valueLabelDisplay="auto"
                  onChange={(e, value) => handleChangePickupTime(value)}
                  valueLabelFormat={(value) => formatSliderToDisplay(value)}
                  step={0.5}
                  marks
                  min={0}
                  max={24}
                />
                {(formData.type === "nextDay" ||
                  formData.type === "sameDay") && (
                  <>
                    <Typography id="discrete-slider" gutterBottom>
                      Days to delivery
                    </Typography>
                    <Slider
                      data-testid="delivery_after_days"
                      defaultValue={deliveryAfterDaysValue}
                      aria-labelledby="range-slider"
                      valueLabelDisplay="auto"
                      onChange={(e, value) =>
                        handleChangeDeliveryAfterDays(value)
                      }
                      valueLabelFormat={(value) => `${value} days`}
                      step={1}
                      marks
                      min={0}
                      max={5}
                    />
                  </>
                )}

                {(formData.type === "nextDay" ||
                  formData.type === "sameDay") && (
                  <>
                    <Typography id="discrete-slider" gutterBottom>
                      Days to pickup
                    </Typography>
                    <Slider
                      data-testid="days_to_pickup"
                      defaultValue={daysToPickUpValue}
                      aria-labelledby="range-slider"
                      valueLabelDisplay="auto"
                      onChange={(e, value) => handleChangeDaysToPickup(value)}
                      valueLabelFormat={(value) => `${value} days`}
                      step={1}
                      marks
                      min={0}
                      max={10}
                    />
                  </>
                )}
                <Typography id="discrete-slider" gutterBottom>
                  Delivery Timeframe
                </Typography>
                <Slider
                  data-testid="delivery_timeframe_slider"
                  defaultValue={deliveryValue.length ? deliveryValue : [15, 15]}
                  aria-labelledby="range-slider"
                  valueLabelDisplay="auto"
                  name="delivery_time_frame"
                  onChange={(e, value) => handleChangeDeliveryTime(value)}
                  valueLabelFormat={(value) => formatSliderToDisplay(value)}
                  step={0.5}
                  marks
                  min={0}
                  max={24}
                />
              </Grid>
            </FormControl>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Products;
