import React, { useState } from "react";
import { useQuery } from "react-query";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { isEmpty } from "lodash";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  Filters,
  TableComponent,
  MainBox,
  DateFormatter,
  ClaimInfo,
  ClaimStatuses,
  CurrencyDisplay,
} from "@shared/components/lib/index";
import { getRequestUI } from "common-utils/utils/api";
import { getCurrentMondayToNextMonday } from "common-utils/utils/date";
import UpdateClaims from "../components/UpdateClaims";
import useTheme from "@mui/material/styles/useTheme";
import { DateTime } from "luxon";
import { TIME_SHOW_ERROR } from "common-utils/src/constants";

const useStyles = (theme) => ({
  infoButton: {
    "& svg": {
      color: "#000000",
    },
  },
  filterBlock: {
    padding: 2,
  },
});

const Claims = () => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { currentWeekMonday, nextWeekMonday } = getCurrentMondayToNextMonday();
  const [selectedStartClaimDateFilter, setSelectedStartClaimDateFilter] =
    useState(currentWeekMonday);
  const [selectedEndClaimDateFilter, setSelectedEndClaimDateFilter] =
    useState(nextWeekMonday);
  const [selectedTrackingCodeFilter, setSelectedTrackingCodeFilter] = useState(
    []
  );
  const [selectedStatusFilter, setSelectedStatusFilter] = useState([]);
  const [currentClaim, setCurrentClaim] = useState({});
  const [claims, setClaims] = useState([]);
  const [openClaimsDialog, setOpenClaimsDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isApplyFilterDisabled, setIsApplyFilterDisabled] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);

  const openInfoClaim = (claim) => {
    setCurrentClaim(claim);
    setOpenClaimsDialog(true);
  };

  const openStatusDialog = (claim) => {
    setCurrentClaim(claim);
    setStatusDialogOpen(true);
  };

  const handleCloseInfo = () => {
    setOpenClaimsDialog(false);
  };

  const onCloseErrorMessage = () => {
    setErrorMessage("");
  };

  const validateFilterDates = (startDate, endDate) => {
    return DateTime.fromJSDate(startDate) <= DateTime.fromJSDate(endDate);
  };

  const getFilterData = () => {
    if (
      !validateFilterDates(
        selectedStartClaimDateFilter,
        selectedEndClaimDateFilter
      )
    ) {
      setIsApplyFilterDisabled(true);
      return undefined;
    }

    setIsApplyFilterDisabled(false);
    return {
      startDate: DateTime.fromJSDate(selectedStartClaimDateFilter).toFormat(
        "yyyy-MM-dd"
      ),
      endDate: DateTime.fromJSDate(selectedEndClaimDateFilter).toFormat(
        "yyyy-MM-dd"
      ),
      trackingCodes: selectedTrackingCodeFilter,
      statusCodes: selectedStatusFilter.map((status) => status.id),
    };
  };

  const fetchClaims = async () => {
    const filterData = getFilterData();
    if (!filterData) return [];
    try {
      return await getRequestUI("/claims", filterData);
    } catch (error) {
      setErrorMessage(error.message);
      throw error;
    }
  };

  const { refetch, isLoading } = useQuery(["claims"], fetchClaims, {
    enabled: true,
    onSuccess: setClaims,
    onError: (error) => setErrorMessage(error.message),
    refetchOnWindowFocus: false,
  });

  const handleApplyFilter = () => {
    if (
      validateFilterDates(
        selectedStartClaimDateFilter,
        selectedEndClaimDateFilter
      )
    ) {
      refetch();
    }
  };

  const searchClaimsByTrackingCodes = async () => {
    const trackingCodesString = selectedTrackingCodeFilter.join(",");
    try {
      const res = await getRequestUI(
        `/admin/claims/search-tracking-codes?trackingCodes=${trackingCodesString}`
      );
      return res;
    } catch (error) {
      setErrorMessage(error.message);
      return [];
    }
  };

  const searchTrackingCodeQuery = useQuery(
    ["search-claims-trackingCode"],
    searchClaimsByTrackingCodes,
    {
      enabled: false,
      onSuccess: (claims) => {
        setClaims(claims);
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
      refetchOnWindowFocus: false,
    }
  );

  const { data: claimPrerequisites } = useQuery(
    ["claims-prerequisites"],
    () => getRequestUI("/claims-prerequisites"),
    {
      onError: (error) => {
        setErrorMessage(error.message);
      },
    }
  );

  const availableStatusOptions =
    claimPrerequisites?.map((item) => ({
      id: item.status.statusCode,
      name: item.status.displayName,
    })) || [];

  const handleGetClaimsByTrackingCode = () => {
    searchTrackingCodeQuery.refetch();
  };

  const claimTableData = {
    headers: [
      "External Id",
      "Date Claim",
      "Date Order",
      "Tracking Code",
      "Parcel Price",
      "Claim Price",
      "Claim Status",
      "Claim Reason",
      "Details",
    ],
    body: [
      (claim) => claim.externalId,
      (claim) => <DateFormatter date={claim.claimDate} format={"dd-MM-yyyy"} />,
      (claim) => <DateFormatter date={claim.orderDate} format={"dd-MM-yyyy"} />,
      (claim) => claim.trackingCode,
      (claim) => <CurrencyDisplay value={claim.price} currency="EUR" />,
      (claim) => <CurrencyDisplay value={claim.claimPrice} currency="EUR" />,
      (claim) => (
        <ClaimStatuses
          claim={claim}
          openStatusDialog={openStatusDialog}
          showUpdateButton={true}
          formatting={false}
        />
      ),
      (claim) => claim.reason,
      (claim) => (
        <Button
          sx={styles.infoButton}
          startIcon={<VisibilityIcon />}
          onClick={() => openInfoClaim(claim)}
        />
      ),
    ],
  };

  return (
    <>
      <MainBox isFullWidth={true}>
        <Box component="span" display={isLoading ? "block" : "none"}>
          <LinearProgress />
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={!!errorMessage}
          onClose={onCloseErrorMessage}
          autoHideDuration={TIME_SHOW_ERROR}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Grid container sx={styles.filterBlock}>
          <Filters
            setTitle={"Claims"}
            selectedStartPickUpDateFilter={selectedStartClaimDateFilter}
            setSelectedStartPickUpDateFilter={setSelectedStartClaimDateFilter}
            selectedEndPickUpDateFilter={selectedEndClaimDateFilter}
            setSelectedEndPickUpDateFilter={setSelectedEndClaimDateFilter}
            statuses={availableStatusOptions}
            selectedStatusFilter={selectedStatusFilter}
            setSelectedStatusFilter={setSelectedStatusFilter}
            selectedTrackingCodeFilter={selectedTrackingCodeFilter}
            setSelectedTrackingCodeFilter={setSelectedTrackingCodeFilter}
            getOrderByTrackingCodes={handleGetClaimsByTrackingCode}
            hideExport={true}
            dateName={"Date"}
            onApplyFilter={handleApplyFilter}
            hideApplyFilter={isApplyFilterDisabled}
            statusLabel={"Claim Status"}
          />
        </Grid>
        <TableComponent
          headers={claimTableData.headers}
          rowData={claims}
          cellComponents={claimTableData.body}
        />
        {!isEmpty(currentClaim) && (
          <>
            <ClaimInfo
              claim={currentClaim}
              isOpenOrderInfo={openClaimsDialog}
              handleCloseInfo={handleCloseInfo}
            />
            <UpdateClaims
              statusDialogOpen={statusDialogOpen}
              handleStatusDialogClose={() => setStatusDialogOpen(false)}
              currentClaim={currentClaim}
              statuses={claimPrerequisites || []}
              refetchClaims={refetch}
              setErrorMessage={setErrorMessage}
            />
          </>
        )}
      </MainBox>
    </>
  );
};

export default Claims;
