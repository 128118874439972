import React, { useState } from "react";
import { Button, Grid, Dialog, DialogContent } from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { useQuery } from "react-query";
import { getRequestUI } from "common-utils/utils/api";
import format from "string-template";
import {
  defaultTemplateRedColor,
  defaultTemplateWhiteColor,
} from "common-utils/utils/commonColors";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IconButton as CIconButton } from "@shared/components/lib/index";

const HtmlEditor = ({ template, setTemplate, retailerId }) => {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const handleOpenPreviewModal = () => {
    setOpenPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setOpenPreviewModal(false);
  };

  const { data: branding } = useQuery(["retailer-branding"], async () => {
    const result = await getRequestUI(`/retailer/${retailerId}/branding`);
    return result;
  });

  return (
    <Grid>
      <Button onClick={handleOpenPreviewModal} color="primary">
        Preview
      </Button>
      <CopyToClipboard text={template}>
        <CIconButton icon="content_copy" />
      </CopyToClipboard>
      <Grid item xs={12}>
        <div
          style={{
            maxHeight: "300px",
            maxWidth: "600px",
            overflow: "auto",
          }}
        >
          <CodeEditor
            value={template}
            language="html"
            placeholder="Please enter html code"
            onChange={(evn) => setTemplate(evn.target.value)}
            padding={15}
            style={{
              overflow: "auto", // Enable scrolling if content exceeds height
              fontSize: 12,
              backgroundColor: "#f5f5f5",
              fontFamily:
                "ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono, Menlo, monospace",
            }}
          />
        </div>
      </Grid>
      <Dialog
        maxWidth={1000}
        open={openPreviewModal}
        onClose={handleClosePreviewModal}
      >
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: format(template, {
                backgroundColor: branding
                  ? branding.topBarColor
                  : defaultTemplateRedColor,
                color: branding
                  ? branding.secondaryColor
                  : defaultTemplateWhiteColor,
              }),
            }}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default HtmlEditor;
