import axios from "axios";
import _ from "lodash";
import { DateTime } from "luxon";

const REQUEST_TIMEOUT = 15 * 1000;

const formatURL = (url) =>
  process.env.REACT_APP_API_URL
    ? `${process.env.REACT_APP_API_URL}${url}`
    : url;

const Axios = axios.create({
  baseURL: formatURL("/api/v1"),
});

const AxiosApiUi = axios.create({
  baseURL: formatURL("/apiui/v1"),
  timeout: REQUEST_TIMEOUT,
});

const setConfig = async (config) => {
  const authLocalStorage = JSON.parse(window.localStorage.getItem("auth"));
  const token = authLocalStorage ? authLocalStorage.token : undefined;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["x-locale"] = DateTime.local().offset;
  }
  return config;
};

AxiosApiUi.interceptors.request.use(async (config) => {
  return await setConfig(config);
});

AxiosApiUi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.localStorage.setItem("auth", null);
      window.dispatchEvent(new Event("local-storage"));
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

Axios.interceptors.request.use(async (config) => {
  return await setConfig(config);
});

const defaultHandler = (error) => {
  if (error.code === "permission-denied") {
    postRequestUI("/logout");
  } else {
    throw error;
  }
};

export const formatParams = (params) =>
  _.map(params, (value, name) => {
    if (Array.isArray(value)) {
      return (
        value.map((valueItem) => `${name}[]=${valueItem}`).join("&") ||
        `${name}[]=`
      );
    } else {
      return `${name}=${value}`;
    }
  })
    .filter((param) => param.length > 0)
    .join("&");

export const getRequestUI = async (url, params) => {
  try {
    let requestUrl = params ? url + "?".concat(formatParams(params)) : url;
    const response = await AxiosApiUi.get(requestUrl);
    return response.data;
  } catch (e) {
    defaultHandler(e.response.data);
  }
};

export const getPDFRequestUI = async (url, params) => {
  try {
    const requestUrl = params ? url + "?".concat(formatParams(params)) : url;
    const response = await AxiosApiUi.get(requestUrl, {
      responseType: "arraybuffer",
    });
    return response;
  } catch (error) {
    defaultHandler(error.response.data);
  }
};

export const getRequest = async (url) => {
  try {
    const response = await Axios.get(url);
    return response.data;
  } catch (e) {
    defaultHandler(e.response.data);
  }
};

export const postRequestUI = async (url, payload) => {
  try {
    const response = await AxiosApiUi.post(url, { data: payload });
    return response.data;
  } catch (e) {
    defaultHandler(e.response.data);
  }
};

export const loginRequestUI = async (url, payload) => {
  try {
    const response = await AxiosApiUi.post(url, { data: payload });
    return response.data;
  } catch (e) {
    throw e.response;
  }
};

export const putRequestUI = async (url, payload) => {
  try {
    const response = await AxiosApiUi.put(url, { data: payload });
    return response.data;
  } catch (e) {
    defaultHandler(e.response.data);
  }
};

export const deleteRequestUI = async (url, params) => {
  try {
    let requestUrl = params ? url + "?".concat(formatParams(params)) : url;
    const response = await AxiosApiUi.delete(requestUrl);
    return response.data;
  } catch (e) {
    defaultHandler(e.response.data);
  }
};

export const deleteRequest = async (url, params) => {
  try {
    let requestUrl = params ? url + "?".concat(formatParams(params)) : url;
    const response = await Axios.delete(requestUrl);
    return response.data;
  } catch (e) {
    defaultHandler(e.response.data);
  }
};

export const postRequest = async (url, payload) => {
  try {
    const response = await Axios.post(url, { data: payload });
    return response.data;
  } catch (e) {
    defaultHandler(e.response.data);
  }
};

export const putRequest = async (url, payload) => {
  try {
    const response = await Axios.put(url, { data: payload });
    return response.data;
  } catch (e) {
    defaultHandler(e.response.data);
  }
};

export const callableFunctions = {
  getRequest,
  postRequest,
  deleteRequest,
};
