import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import { getRequestUI, putRequestUI } from "common-utils/utils/api";

import Checkbox from "@mui/material/Checkbox";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import useTheme from "@mui/material/styles/useTheme";

import { SharedStyles } from "@shared/components/lib/index";

const RetailerFeatureTab = ({ retailer, handleClose }) => {
  const theme = useTheme();
  const sharedStyles = SharedStyles(theme);
  const [features, setFeatures] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      await updateFeatures();
    })();
  }, [retailer]);

  const updateFeatures = async () => {
    try {
      const result = await getRequestUI(`/features/${retailer.id}`);
      setFeatures(result);
    } catch (error) {
      if (error.code === "invalid-argument") {
        setErrorMessage(error.message);
      }
    }
  };

  const handleChange = async (feature) => {
    setErrorMessage("");
    try {
      const data = {
        enabled: !feature.enabled,
        retailerId: retailer.id,
        featureId: feature.id,
      };
      await putRequestUI(`/retailer-feature`, data);
      await updateFeatures();
    } catch (error) {
      // Getting the Error details.
      if (error.code === "invalid-argument") {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <Grid container>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!errorMessage}
        autoHideDuration={3000}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <form style={sharedStyles.container}>
        <FormControl sx={sharedStyles.formControl}>
          <Typography variant="h3" component="h4">
            Retailer Feature
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {features.length > 0 ? (
                <>
                  {features.map((feature) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-testid={feature.id}
                          checked={feature.enabled || false}
                          onChange={async () => await handleChange(feature)}
                          name={feature.id}
                          color="primary"
                        />
                      }
                      label={feature.name}
                    />
                  ))}
                </>
              ) : null}
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </Grid>
  );
};

export default RetailerFeatureTab;
