import React, { useState, useCallback, useEffect } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { ButtonProgress, SharedStyles } from "@shared/components/lib/index";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import useTheme from "@mui/material/styles/useTheme";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  postRequestUI,
  getRequestUI,
  putRequestUI,
} from "common-utils/utils/api";
import { SketchPicker } from "react-color";
import SVGUpload from "./SVGUpload";
import Box from "@mui/material/Box";
import {
  defaultMainColor,
  defaultSecondaryColor,
} from "common-utils/utils/commonColors";

const useStyles = (theme) => ({
  formContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 340,
  },
  submitBtn: {
    position: "absolute",
    top: "14px",
    zIndex: 10000,
    color: "#fff",
    left: "60px",
  },
  colorContainer: {
    display: "flex",
    flexDirecton: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(3),
  },
  color: {
    width: "100px",
    height: "14px",
    borderRadius: "2px",
    height: "30px",
  },
  swatch: {
    height: "30px",
    padding: "5px",
    borderRadius: "1px",
    boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
    display: "inline-block",
    cursor: "pointer",
    width: "100px",
  },
  popover: {
    paddingLeft: theme.spacing(5),
  },
  cover: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  colorTitle: {
    paddingRight: "20px",
  },
  colorPickerContainer: {
    display: "flex",
  },
  textArea: {
    width: "100%",
  },
});

const RetailerBrandingFormTab = ({ retailer, isCreatingNew, handleClose }) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [topBarColor, setTopBarColor] = useState(defaultMainColor);
  const [secondaryColor, setSecondaryColor] = useState(defaultSecondaryColor);
  const [timelineColor, setTimelineColor] = useState(
    theme.palette.common.black
  );
  const [colorType, setColorType] = useState("");
  const [svgXml, setSvgXml] = useState("");
  const [retailerBrandingId, setRetailerBrandingId] = useState(null);
  const [isError, setIsError] = useState(false);
  const [trackingText, setTrackingText] = useState("");
  const [trackingEmailText, setTrackingEmailText] = useState("");
  const [showSender, setShowSender] = useState(true);

  useEffect(() => {
    (async () => {
      const result = await getRequestUI(`/retailer/${retailer.id}/branding`);
      if (result) {
        return updateRetailerBranding(result);
      }
      setRetailerBrandingId(null);
      return;
    })();
  }, []);

  const handleClickOnColorPicker = (colorType) => {
    setColorType(colorType);
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleCloseColorPicker = () => {
    setDisplayColorPicker(false);
  };

  const updateRetailerBranding = (branding) => {
    setTopBarColor(branding.topBarColor);
    setSecondaryColor(branding.secondaryColor);
    setTimelineColor(
      branding.timelineColor ? branding.timelineColor : timelineColor
    );
    setSvgXml(branding.svg);
    setRetailerBrandingId(branding.retailerId);
    setTrackingText(branding.trackingText);
    setTrackingEmailText(branding.trackingEmailText);
    setShowSender(branding.showSender);
  };

  const handleChangeColor = (color) => {
    switch (colorType) {
      case "topBar":
        setTopBarColor(color.hex);
        break;
      case "secondary":
        setSecondaryColor(color.hex);
        break;
      case "timeline":
        setTimelineColor(color.hex);
        break;
    }
  };
  const submit = async () => {
    try {
      const data = {
        topBarColor,
        secondaryColor,
        timelineColor,
        svg: svgXml,
        trackingText,
        trackingEmailText,
        showSender,
      };
      if (retailerBrandingId) {
        const result = await putRequestUI(
          `/retailer/${retailer.id}/branding`,
          data
        );
        handleClose();
        return updateRetailerBranding(result);
      }
      const result = await postRequestUI(
        `/retailer/${retailer.id}/branding`,
        data
      );
      handleClose();
      return updateRetailerBranding(result);
    } catch (error) {
      console.log(error.code + ":" + error.message);
    }
  };

  return (
    <>
      <Button
        data-testid="save_btn"
        sx={styles.submitBtn}
        autoFocus
        color="inherit"
        disabled={isError}
        onClick={async () => await submit()}
      >
        Change branding
        {isCreatingNew && <ButtonProgress size={24} />}
      </Button>
      <form style={sharedStyles.container}>
        <FormControl sx={sharedStyles.formControl}>
          <Typography variant="h3" component="h4">
            Retailer Branding
          </Typography>
          <Grid style={{ padding: "20" }}>
            <SVGUpload
              svgXml={svgXml}
              setSvgXml={setSvgXml}
              setIsError={setIsError}
              isError={isError}
            />
            <Grid alignItems={"center"} style={{ display: "flex" }}>
              <Grid>
                <Grid xs={12} sx={styles.colorContainer}>
                  <Typography sx={styles.colorTitle} variant="h5">
                    Top bar color
                  </Typography>
                  <Box
                    sx={styles.swatch}
                    bgcolor={topBarColor}
                    onClick={() => handleClickOnColorPicker("topBar")}
                  ></Box>
                </Grid>
                <Grid sx={styles.colorContainer} xs={12}>
                  <Typography sx={styles.colorTitle} variant="h5">
                    Secondary color
                  </Typography>
                  <Box
                    sx={styles.swatch}
                    bgcolor={secondaryColor}
                    onClick={() => handleClickOnColorPicker("secondary")}
                  ></Box>
                </Grid>
                <Grid xs={12} sx={styles.colorContainer}>
                  <Typography sx={styles.colorTitle} variant="h5">
                    Timeline Color
                  </Typography>
                  <Box
                    sx={styles.swatch}
                    bgcolor={timelineColor}
                    onClick={() => handleClickOnColorPicker("timeline")}
                  ></Box>
                </Grid>
                <Grid>
                  <Typography sx={styles.colorTitle} variant="h5">
                    Additional text
                  </Typography>
                  <TextareaAutosize
                    aria-label="additional text"
                    minRows={3}
                    sx={styles.textArea}
                    placeholder="Additional text"
                    value={trackingText}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setTrackingText(e.target.value);
                    }}
                  />
                </Grid>
                <Grid>
                  <Typography className={styles.colorTitle} variant="h5">
                    Tracking email text
                  </Typography>
                  <TextareaAutosize
                    aria-label="Tracking email text"
                    minRows={3}
                    className={styles.textArea}
                    placeholder="Tracking email text"
                    value={trackingEmailText}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setTrackingEmailText(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="track_and_trace_sender_chk"
                        checked={showSender}
                        onChange={(e) => setShowSender(e.target.checked)}
                        name="track_and_trace_sender_chk"
                        color="primary"
                      />
                    }
                    label="Show sender"
                  />
                </Grid>
              </Grid>
              {displayColorPicker ? (
                <Box sx={styles.popover}>
                  <Box sx={styles.cover} onClick={handleCloseColorPicker} />
                  {colorType === "topBar" && (
                    <SketchPicker
                      color={topBarColor}
                      onChange={handleChangeColor}
                    />
                  )}
                  {colorType === "secondary" && (
                    <SketchPicker
                      color={secondaryColor}
                      onChange={handleChangeColor}
                    />
                  )}
                  {colorType === "timeline" && (
                    <SketchPicker
                      color={timelineColor}
                      onChange={handleChangeColor}
                    />
                  )}
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </FormControl>
      </form>
    </>
  );
};

export default RetailerBrandingFormTab;
