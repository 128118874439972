import Orders from "./pages/Orders";
import Retailers from "./pages/Retailers";
import Locations from "./pages/Locations";
import Users from "./pages/Users";
import Carriers from "./pages/Carriers";
import PaazlOrders from "./pages/PaazlOrders";
import Sizes from "./pages/Sizes";
import Claims from "./pages/Claims";
import OrderExports from "./pages/Exports";

export const SUPPORT_PAGES = [
  { path: "/orders", name: "Orders", component: () => <Orders /> },
];

export const PAGES = [
  ...SUPPORT_PAGES,
  { path: "/retailers", name: "Retailers", component: () => <Retailers /> },
  { path: "/carriers", name: "Carriers", component: () => <Carriers /> },
  { path: "/locations", name: "Locations", component: () => <Locations /> },
  { path: "/users", name: "Users", component: () => <Users /> },
  { path: "/paazl", name: "Paazl Orders", component: () => <PaazlOrders /> },
  { path: "/sizes", name: "Sizes", component: () => <Sizes /> },
  { path: "/claims", name: "Claims", component: () => <Claims /> },
  { path: "/exports", name: "Exports", component: () => <OrderExports /> },
];
