import React, { useState, useCallback, useEffect } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { defaultCountry } from "common-utils/utils/country";
import MuiPhoneNumber from "material-ui-phone-number-2";
import Autocomplete from "@mui/material/Autocomplete";
import useTheme from "@mui/material/styles/useTheme";
import { useQuery } from "react-query";
import { getRequestUI } from "common-utils/utils/api";
import {
  ButtonProgress,
  GoogleAutoCompleteAddress,
  SharedStyles,
} from "@shared/components/lib/index";

const useStyles = (theme) => ({
  submitBtn: {
    "&.MuiButton-root": {
      position: "absolute",
      top: "14px",
      zIndex: 10000,
      color: "#fff",
      left: "60px",
    },
  },
});

const LocationForm = ({
  location,
  retailers,
  edit,
  isCreatingNew,
  tagValue,
  handleEditLocation,
  handleSave,
  countries,
  setLocationRetailer,
  setErrorMessage,
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const sharedStyles = SharedStyles(theme);
  const [name, setLocationName] = useState("");
  const [locationId, setLocationId] = useState(null);
  const [selectedRetailer, setSelectedRetailer] = useState("");
  const [postcode, setPostcode] = useState("");
  const [returnAddress, setReturnAddress] = useState({});
  const [houseNumber, setHouseNumber] = useState("");
  const [houseAdditions, setHouseAdditions] = useState("");
  const [personName, setPersonName] = useState("");
  const [personNumber, setPersonNumber] = useState("");
  const [country, setCountry] = useState(defaultCountry);
  const [coordinates, setCoordinates] = useState({});
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [selectedReturnAddress, setSelectedReturnAddress] = useState(null);
  const [reference, setReference] = useState("");
  const [showSenderAddressOnLabel, setShowSenderAddressOnLabel] =
    useState(true);
  const isNLCountryCode = country && country.code === defaultCountry.code;
  const store = { id: "store", name: "Store" };
  const warehouse = { id: "warehouse", name: "Warehouse" };
  const hub = { id: "hub", name: "Hub" };
  const locationTypes = [store, warehouse, hub];
  const [selectedType, setSelectedType] = useState(store);
  const { data: returnAddressData = [], refetch: refetchReturnAddress } =
    useQuery(
      ["retailer-return-addresses"],
      async () => {
        const result = await getRequestUI(
          `/retailer/return-address/${selectedRetailer.id}`
        );
        return result;
      },
      { enabled: !_.isEmpty(selectedRetailer) }
    );

  useEffect(() => {
    if (edit) {
      const selectedRetailer = retailers.find(
        (retailer) => retailer.id == location.retailer.id
      );
      const selectedType = locationTypes.find(
        (type) => type.id === location.type
      );
      if (returnAddressData) {
        const selectedReturnAddressFromData = returnAddressData.find(
          (address) => address.id === location.retailerReturnAddressId
        );
        setSelectedReturnAddress(selectedReturnAddressFromData);
      }

      setLocationId(location.id);
      setLocationName(location.name);
      setSelectedRetailer(selectedRetailer);
      setPostcode(location.address.postcode);
      setHouseNumber(location.address.houseNumber);
      setHouseAdditions(location.address.houseAdditions);
      setPersonName(location.personName);
      setPersonNumber(location.personNumber);
      setCountry(location.address.country);
      setCity(location.city);
      setStreet(location.street);
      setCoordinates(location.coordinates);
      setReference(location.reference);
      setShowSenderAddressOnLabel(location.showSenderAddressOnLabel);
      setSelectedType(selectedType);
    }
  }, [edit, returnAddressData]);

  const setButtonValue = () => {
    if (edit && (tagValue === 0 || tagValue === 3)) {
      return "Update";
    } else if (tagValue === 1) {
      return "";
    } else {
      return "Save";
    }
  };

  const submit = () => {
    if (edit) {
      const data = {
        id: locationId,
        name: name,
        personName: personName,
        personNumber: personNumber,
        address: {
          postcode,
          houseNumber,
          houseAdditions,
          country,
        },
        retailer: {
          id: selectedRetailer.id,
          name: selectedRetailer.name,
        },
        reference,
        returnAddress,
        type: selectedType.id,
        showSenderAddressOnLabel,
        retailerReturnAddressId: selectedReturnAddress?.id,
      };
      if (!isNLCountryCode) {
        data.coordinates = coordinates;
        data.street = street;
        data.city = city;
      }
      return handleEditLocation(data);
    }
    const data = {
      name: name,
      retailer: {
        id: selectedRetailer.id,
        name: selectedRetailer.name,
      },
      address: {
        postcode,
        houseNumber,
        houseAdditions,
        country,
      },
      coordinates,
      personName,
      personNumber,
      city,
      street,
      reference,
      returnAddress,
      type: selectedType.id,
      showSenderAddressOnLabel,
      retailerReturnAddressId: selectedReturnAddress?.id,
    };
    return handleSave(data);
  };

  const onAddressResolved = (autoCompleteData) => {
    if (!autoCompleteData) {
      return;
    }
    setErrorMessage("");
    const { coordinates, postcode, houseNumber, city, street } =
      autoCompleteData;
    if (!postcode || !city) {
      setErrorMessage("Unable to locate an address");
      return;
    }
    if (!houseNumber) {
      setErrorMessage("Please add street number");
      return;
    }
    if (!street) {
      setErrorMessage("Please fill the street");
      return;
    }
    setCoordinates(coordinates);
    setPostcode(postcode);
    setHouseNumber(houseNumber);
    setCity(city);
    setStreet(street);
  };

  const handleCountry = useCallback((e, value) => {
    if (!value) {
      setCountry({});
    } else {
      setCountry(value);
    }
    setCity("");
    setStreet("");
    setPostcode("");
    setHouseNumber("");
    setHouseAdditions("");
    setCoordinates(null);
  }, []);

  const handleRetailer = useCallback((e, value) => {
    setSelectedRetailer(value);
    setLocationRetailer(value);
  }, []);

  const handleType = (e, value) => {
    setSelectedType(value);
  };

  return (
    <>
      <Button
        data-testid="save_location_edit_btn"
        sx={styles.submitBtn}
        autoFocus
        color="inherit"
        onClick={submit}
      >
        {setButtonValue()}
        {isCreatingNew && <ButtonProgress size={24} />}
      </Button>
      <form style={sharedStyles.container}>
        <FormControl sx={sharedStyles.formControl}>
          <Typography variant="h3" component="h4">
            {edit ? "Edit Location" : " Create new Location"}
          </Typography>
          <TextField
            data-testid="location_name_field"
            id="name"
            variant="standard"
            label="Location Name"
            value={name || ""}
            onChange={(e) => setLocationName(e.target.value)}
          />
          <Autocomplete
            data-testid="retailers_autocomplete"
            id="retailer-autocomplete"
            autoComplete
            autoHighlight
            options={retailers}
            value={selectedRetailer}
            getOptionLabel={(option) => option.name ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select Retailer"
                placeholder="Select Retailer"
              />
            )}
            onChange={handleRetailer}
          />
          <Autocomplete
            data-testid="countries_autocomplete"
            id="countries-autocomplete"
            autoComplete
            autoHighlight
            options={countries || []}
            value={country}
            getOptionLabel={(option) => option.name ?? ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Select Country"
                placeholder="Select country"
              />
            )}
            onChange={handleCountry}
          />
          {isNLCountryCode ? (
            <>
              <TextField
                data-testid="postcode_field"
                id="postcode-field"
                variant="standard"
                label="Postcode"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
              <TextField
                data-testid="house_number_field"
                id="house-number-field"
                variant="standard"
                label="House Number"
                value={houseNumber}
                onChange={(e) => setHouseNumber(e.target.value)}
              />
              <TextField
                data-testid="house_addition_field"
                id="house-addition"
                variant="standard"
                label='Addition (e.g. "H" or "1")'
                value={houseAdditions}
                onChange={(e) => setHouseAdditions(e.target.value)}
              />
            </>
          ) : (
            <GoogleAutoCompleteAddress
              country={country}
              onSelect={onAddressResolved}
              address={{ city, street, houseNumber }}
            />
          )}
          <TextField
            data-testid="person_name_field"
            id="person-name"
            variant="standard"
            label="Person name"
            value={personName}
            onChange={(e) => setPersonName(e.target.value)}
          />
          <MuiPhoneNumber
            data-testid="person_number_field"
            id="person-number"
            variant="standard"
            label="Person number"
            defaultCountry="nl"
            regions={"europe"}
            value={personNumber}
            onChange={(value) => setPersonNumber(value)}
          />
          <TextField
            data-testid="location_reference_field"
            id="location_reference_field"
            variant="standard"
            label="Reference"
            value={reference}
            onChange={(e) => setReference(e.target.value)}
          />
          <>
            <Autocomplete
              data-testid="location_type_autocmplete"
              id="location_type_autocmplete"
              autoComplete
              autoHighlight
              options={locationTypes}
              defaultValue={store}
              value={selectedType}
              getOptionLabel={(option) => option.name ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select Type"
                  placeholder="Select Type"
                />
              )}
              onChange={handleType}
            />
            <Autocomplete
              data-testid="retailer_return_address_autocomplete"
              id="retailer_return_address_autocomplete"
              autoComplete
              autoHighlight
              options={returnAddressData}
              value={selectedReturnAddress}
              getOptionLabel={(option) => option.name ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select Retailer Return Address"
                  placeholder="Select Return Address"
                />
              )}
              onChange={(e, value) => {
                setSelectedReturnAddress(value);
              }}
            />
          </>
          <FormControlLabel
            control={
              <Checkbox
                data-testid="show_sender_address_on_label"
                checked={showSenderAddressOnLabel}
                onChange={(e) => setShowSenderAddressOnLabel(e.target.checked)}
                name="showSenderAddressOnLabel"
                color="primary"
              />
            }
            label="Show Sender Address On label"
          />
        </FormControl>
      </form>
    </>
  );
};

export default LocationForm;
