import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./css/normalize.css";
import Main from "./Main";
import * as serviceWorker from "./serviceWorker";
import { initSentry } from "common-utils/utils/sentry";

initSentry();

ReactDOM.render(<Main />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
